import { Component, ComponentRef, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { BbObject, Gear, GearPropertyDatatype, Container, DataColumn, SegmentGearType, Group, GearCode, GearProperty, DataColumnType } from 'src/app/shared/models/building-blocks';
import { BaseMultiLineStringInputProperties, ReadOnlyMultiLineStringInputProperties } from 'src/app/shared/models/core-multiLineString-input-properties';
import { ChunkNumberInputProperties, NullableIntInputProperties, WholeNumberInputProperties } from 'src/app/shared/models/core-numeric-input-properties';
import { BaseStringInputProperties, ReadOnlyStringInputProperties } from 'src/app/shared/models/core-string-input-properties';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { BuildingBlockHelperService } from '../building-block-helper.service';
import { ToastrService } from 'ngx-toastr';
import { FormulaBuilderService } from 'src/app/shared/services/formula-builder.service';
import { FormulaBuilderTreeItem } from 'src/app/shared/models/formula-builder-tree-item';
import { HelperService } from 'src/app/shared/services/helper.service';
import { FieldService} from 'src/app/shared/services/field.service';
import { GearPropertyService } from '../gear-property.service';
import { ContainerRuleContext } from 'src/app/shared/models/contexts/container-rule-context';
import { SegmentFilterBuilderComponent } from 'src/app/shared/components/segment-filter-builder/segment-filter-builder.component';
import { filter, take, takeUntil, finalize } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { containerTypeIds, coreResponseCodes, EnumContainerType, EnumFormulaBuilderDataSet } from 'src/app/shared/constants/enums';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { CoreEditorOptions } from 'src/app/shared/models/core-editor-options';
import DataSource from 'devextreme/data/data_source';
import { DxLoadPanelComponent } from 'devextreme-angular';
import { SaveableBbProperty } from 'src/app/shared/models/saveable-bb-property';
import { Subject } from 'rxjs';
import { CorePopupProperties } from 'src/app/shared/models/core-popup-properties';
import { CorePopupStep } from 'src/app/shared/models/core-popup-step';
import { confirm } from 'devextreme/ui/dialog';
import { CoreResponse } from 'src/app/shared/models/core-response';
import { CoreFormFieldProperties } from 'src/app/shared/models/core-form-field-properties';
import { CoreInputEditorType } from 'src/app/shared/constants/dev-extreme-enums';
import { CoreBotService } from 'src/app/shared/services/core-bot.service';
import { DbGear } from 'src/app/shared/models/db-gear';
import { SavedColumn } from 'src/app/shared/models/saved-column';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Router } from '@angular/router';
import { CoreFileManagerComponent } from 'src/app/shared/components/bb-file-manager/bb-file-manager.component';

@Component({
    selector: 'app-bb-property-panel',
    templateUrl: './bb-property-panel.component.html',
    styleUrls: ['./bb-property-panel.component.scss']
})
export class BbPropertyPanelComponent implements OnInit, OnDestroy {
	@ViewChildren('segmentFilterBuilder') criteriaFilterBuilders!: QueryList<SegmentFilterBuilderComponent>;
    @ViewChildren(SaveableBbProperty) saveableProperties!: QueryList<SaveableBbProperty>;
    @ViewChild('appFileManager') appFileManager: CoreFileManagerComponent;

    focusedObject: BbObject = null;
    focusedObjectChanges: any = null;
    dataType = GearPropertyDatatype;
    recurrenceId: number;

    showAdvancedView: boolean = false;
    focusedChild: BbObject = null;
    scopeContainer: Container = null;
    periodId: number;
    seriesId: number;
    inputFields: DataSource;
    lockPanel: boolean = false;
    convertIntegersToFloat: boolean = true;

    nullableIntProperties = new NullableIntInputProperties();
    readOnlyIntProperties = new NullableIntInputProperties();
    wholeNumberProperties = new WholeNumberInputProperties(1000, 0);
    dayNumberProperties = new WholeNumberInputProperties(28);
    monthNumberProperties = new WholeNumberInputProperties(12);
    chunkNumberProperties = new ChunkNumberInputProperties();
    stringProperties = new BaseStringInputProperties();
    readOnlyStringProperties = new ReadOnlyStringInputProperties();
    multiLineStringProperties = new BaseMultiLineStringInputProperties();
    readOnlyMultiLineStringPropertie = new ReadOnlyMultiLineStringInputProperties();
    formulaDisplayProperties = new BaseMultiLineStringInputProperties(200, false, true);

    gearPropertyMappings: Record<string, any>;
    groupedGearPropertyMappings: any;
    pendingMappingUpdates: any[] = null;
    containerProperties: any[];

    savedColumnsEditDetails: Record<string, SavedColumn> = {};
    savedColumnsAllDetails: Record<string, SavedColumn> = {};
    savedColumnsConfirmationPopupProps: CorePopupProperties = new CorePopupProperties();
    savedColumnsConfirmationPopupSteps: CorePopupStep[] = [];
    savedColumnsConfirmationWarning: string = `
        <br> <span class="removed-column-highlight">Removed columns will have all output from that column deleted from calculation.</span> 
        <br> Reassigned columns will have all output from the original column reassigned to the new column.
    `;
    savedColumnsTimeoutWarning: string = `
        <br>
        <br>Generating the list of downstream rules is taking longer than expected.
        <br>Please check the logger to see when the downstream rule generation has completed. 
        <br>Would you like to save your changes anyway?
    `;
    versionConflictConfirmationWarning: string = `
        <br>
        <br> The rules above have active versions that are downstream from this rule version.
        <br>
        <br> Any columns that are removed from this version will be unavailable in the downstream versions.
        <br>
        <br> Any columns that are added to this version will be unavailable in the downstream versions unless a new version of each downstream rule 
        is created with a start date later than the start date of this rule version.
    `;
    areSavedColumnsConfirmed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    periodDropdownProps: Record<string, CoreDropdownProperties> = {};
    loadPanel: ComponentRef<DxLoadPanelComponent>;
    isImplementer: boolean = false;
    promptBuilderTitle: string = 'Prompt Builder';

    readonly widePropertyTypes: GearPropertyDatatype[] = [
        GearPropertyDatatype.AssignFields,
        GearPropertyDatatype.ApplyTo,
        GearPropertyDatatype.FieldGrid,
        GearPropertyDatatype.FilterBuilder,
        GearPropertyDatatype.SegmentFilterBuilder,
        GearPropertyDatatype.UpdateXactionFields,
        GearPropertyDatatype.UnionFields,
        GearPropertyDatatype.FileManager
    ];

    private unsubscribe$ = new Subject<void>();

    constructor(private buildingBlockHelper: BuildingBlockHelperService,
        private buildingBlocksService: BuildingBlocksService,
        private gearPropertyService: GearPropertyService,
        private coreBotService: CoreBotService,
        private toast: ToastrService,
        private formulaBuilderService: FormulaBuilderService,
        private helperService: HelperService,
        private viewContainerRef: ViewContainerRef,
        private fieldService: FieldService,
        private router: Router,
        private permissionService: PermissionService) {
        this.periodDropdownProps['period'] = new CoreDropdownProperties().createPeriodDropdownDefaults('dateRange', false, false, false, 'Period');
    }

    ngOnInit(): void {
        this.buildingBlockHelper.getSubObject().pipe(takeUntil(this.unsubscribe$), filter(obj => !!obj)).subscribe(focusedChild => {
            this.focusedChild = focusedChild;
            this.setChildSourcePropertyGroups(this.focusedChild);
            if(!this.focusedChild.objectTypeCode.includes('Bb')) {
                setTimeout(this.scrollToSourcePropertyGroup);
            }
        });

        this.buildingBlockHelper.getFocusedObject().pipe(takeUntil(this.unsubscribe$), filter(obj => !!obj)).subscribe(focusedObject => {
            this.focusedChild = null;
            this.focusedObject = focusedObject;
            this.focusedObjectChanges = this.helperService.deepCopyTwoPointO(this.focusedObject);
            if(focusedObject.id.includes('Co')){
                this.containerProperties = JSON.parse(focusedObject['objectJson']);
            }
            this.buildingBlockHelper.setFocusedObjectChanges(this.focusedObjectChanges);
            this.convertIntegersToFloat = this.focusedObjectChanges.propertyValues?.find(x => x.property.systemName === 'convertIntegersToFloat')?.value ?? true;
            if (this.focusedObject instanceof Gear) {
                this.focusedObjectChanges.gearType = this.buildingBlocksService.gearTypeRecord[this.focusedObject.gearTypeCode].createSameGearType();
                this.focusedObjectChanges.objectTypeCode = this.focusedObject.objectTypeCode;
                this.focusedObjectChanges.gearTypeCode = this.focusedObjectChanges.gearType.getGearTypeCode();
                if (this.gearPropertyService.objectStore) {
                    this.gearPropertyService.updateMappings(this.focusedObject.propertyValues.map(p => p.property.systemName));
                } else {
                    this.pendingMappingUpdates = this.focusedObject.propertyValues.map(p => p.property.systemName);
                }
            } else {
                this.focusedObjectChanges.objectTypeCode = this.focusedObject.objectTypeCode;
            }
        });

        this.gearPropertyService.getMappings().subscribe(res => {
            this.gearPropertyMappings = res;
            if (this.gearPropertyService.objectStore && this.pendingMappingUpdates) {
                const tempPendingMappingUpdates = this.helperService.deepCopyTwoPointO(this.pendingMappingUpdates);
                this.pendingMappingUpdates = null;
                this.gearPropertyService.updateMappings(tempPendingMappingUpdates);
            }
        });

        this.buildingBlockHelper.getScopeContainer().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if(result?.id.includes('Co')){
                this.containerProperties = JSON.parse(result.objectJson);
                this.recurrenceId = this.buildingBlockHelper.getObjectById(result.parentId)?.['recurrenceId'];
            }
            this.scopeContainer = result;
        });

        this.readOnlyIntProperties.readOnly = true;
        this.buildingBlockHelper.getEditLock().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            this.lockPanel = result;
            this.stringProperties.readOnly = this.lockPanel;
            this.multiLineStringProperties.readOnly = this.lockPanel;
            this.nullableIntProperties.readOnly = this.lockPanel;
            this.wholeNumberProperties.readOnly = this.lockPanel;
            this.dayNumberProperties.readOnly = this.lockPanel;
            this.monthNumberProperties.readOnly = this.lockPanel;
            this.chunkNumberProperties.readOnly = this.lockPanel;
        });

        this.buildingBlockHelper.getShowAdvancedView().pipe(takeUntil(this.unsubscribe$)).subscribe(result => this.showAdvancedView = result);

        this.buildingBlockHelper.getPeriodId().pipe(takeUntil(this.unsubscribe$)).subscribe(periodId => {
            this.periodId = periodId;
        });

        this.buildingBlockHelper.getSeriesId().pipe(takeUntil(this.unsubscribe$)).subscribe(seriesId => {
            this.seriesId = seriesId;
        });

        this.buildingBlockHelper.getShowLoadPropertyPanel().pipe(takeUntil(this.unsubscribe$)).subscribe(showPanel => {
            if (showPanel && this.helperService.isNullOrUndefined(this.loadPanel)) {
                this.loadPanel = this.helperService.createLoadPanel({ my: 'center', at: 'center', of: '.bb-property-panel' });
                this.loadPanel.instance.container = '.bb-property-panel';
                this.helperService.injectComponent(this.viewContainerRef, this.loadPanel);
            } else if (showPanel === false && !this.helperService.isNullOrUndefined(this.loadPanel)) {
                this.loadPanel.destroy();
                this.loadPanel = undefined;
            }
        });

        this.permissionService.getIsImplementer().subscribe(result => {
            this.isImplementer = result;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onContainerPropertyChange(e): void {
        this.focusedObjectChanges.objectJson = JSON.stringify(this.containerProperties);
    }

    onUserDependenciesChange(e): void {
        if (e.value && !this.containerProperties['userDependencies']) {
            this.containerProperties['userDependencies'] = [];
        } else if(!e.value) {
            delete this.containerProperties['userDependencies'];
        }
        this.onContainerPropertyChange(e);
    }

    onExclusiveUserDependenciesChange(e): void {
        if (e.value) {
            this.containerProperties['exclusiveUserDependencies'] = true;
        } else {
            delete this.containerProperties['exclusiveUserDependencies'];
        }
        this.onContainerPropertyChange(e);
    }

    onPropertyCheckChange(e): void {
        if (this.focusedObjectChanges.gearTypeCode === GearCode.FormulaGearCode) {
            this.convertIntegersToFloat = this.focusedObjectChanges.propertyValues?.find(x => x.property.systemName === 'convertIntegersToFloat')?.value ?? true;
            const containerId = this.focusedObjectChanges.parentId.slice(2);
            this.buildingBlocksService.getFriendlyFormulaString(
                this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'sqlFormulaString').value,
                containerId).subscribe(friendlyFormula => {
                    this.buildingBlocksService.getSqlFormulaString(friendlyFormula, containerId, this.convertIntegersToFloat).subscribe(sqlFormula => {
                        this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'sqlFormulaString').value = sqlFormula;
            });
        });
        }
    }

    setChildSourcePropertyGroups(focusedChild: BbObject): void {
        const sourceNames: string[] = [];
        const type = focusedChild.objectTypeCode;
        const name = focusedChild.name;

        if (type === 'Bb') {
            sourceNames.push(...['General', 'Source', 'Eligibility', 'Data Filter', 'Calculate', 'Output']);
        } else if (['Redirect Output', 'Account Redir', 'Historical Accounts'].includes(name) || name.startsWith('Staged ')) {
            sourceNames.push('Output');
        } else if (type === 'Ds' || name === 'Period Filter') {
            sourceNames.push('Source');
        } else if (name === 'Elig Date' || name === 'Split by Role' ) {
            sourceNames.push('Eligibility');
        } else if (type === 'Fi' && name === 'Criteria Filter') {
            sourceNames.push('Data Filter');
        } else if (type === 'Fi' && name === 'Account Filter') {
            sourceNames.push('Eligibility');
        } else if (type === 'Ca' || type === 'Ag' || type === 'Bb') {
            sourceNames.push('Calculate');
        }
        focusedChild['sourcePropertyGroups'] = sourceNames;
    }

    scrollToSourcePropertyGroup() {
        Array.from(document.getElementsByClassName('property-group'))
            .find(e => !e.firstElementChild.classList.contains('dim-overlay'))
            ?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }

    async onSaveNodeButtonClick(e): Promise<void> {
        await Promise.all(this.saveableProperties.map(grid => grid.saveInternalData()));
        if(this.saveableProperties.some(property => !property.isValid)){
            this.toast.error('Cannot save because the object has invalid input.');
            return;
        }

        if (!this.focusedObjectChanges.name.trim()) {
            this.toast.error('An object\'s name must not be blank.');
            return null;
        }
        if (this.focusedObjectChanges.name !== this.focusedObject.name && this.buildingBlockHelper.hasGearRuleNameCollision(this.focusedObjectChanges.name, this.focusedObject.id)) {
            this.toast.error('Cannot name an object the same as an existing rule.');
            return null;
        }
        if (this.buildingBlockHelper.hasIntraRuleGearNameCollision(this.focusedObjectChanges.name, this.focusedObjectChanges.id)) {
            this.toast.error('Cannot name an object the same as another gear in this rule.');
            return null;
        }
        if (this.buildingBlockHelper.hasReservedNameCollision(this.focusedObjectChanges.name)) {
            this.toast.error('Cannot name an object the same as a reserved name.');
            return null;
        }
        if(await this.buildingBlockHelper.hasFieldNameCollision(this.focusedObjectChanges.name)) {
            this.toast.error('Cannot name an object the same as a field.');
            return null;
        }
        // Todo: It appears that only the segment gear needs this looping of the properties. Confirm

        if (this.focusedObject instanceof Gear) {
            this.buildingBlockHelper.setShowLoadPanel(true);

            if (this.focusedObject.gearTypeCode === GearCode.SegmentGearCode) {
                this.applyChangesToObject(this.focusedObject);
                this.buildingBlocksService.updateGear(this.focusedObject).subscribe(res => {
                    if(res.responseCode === coreResponseCodes.Success){
                        const segmentGear: Container = res.results.find(object => object instanceof Container) as Container;
                        if(this.scopeContainer.id === segmentGear.id){
                            this.buildingBlockHelper.setScopeContainer(segmentGear);
                        }
                        this.applyChangesToObject(this.focusedObject);
                        this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(this.focusedObject.id);
                        this.buildingBlockHelper.addObjectsToStore(res.results);
                        this.buildingBlockHelper.setFocusedObject(segmentGear);
                        this.buildingBlockHelper.forceRefreshDiagram();
                        this.buildingBlockHelper.setShowLoadPanel(false);
                        this.toast.success(`'${this.focusedObject.name}' saved successfully`);
                    } else if(res.responseCode === coreResponseCodes.Error){
                        this.helperService.displayErrorToast(this.toast, res, 'An error occurred while attempting to create rule');
                        this.buildingBlockHelper.setShowLoadPanel(false);
                    }
                },
                    err => {
                        this.buildingBlockHelper.setShowLoadPanel(false);
                        this.toast.error('Failed to update gear. Refresh and try again.');
                });
            } else {
                const gearId = this.focusedObject.id;
                const focusedObjectCopy = this.buildingBlocksService.objToBbObject(this.focusedObject) as Gear;
                this.applyChangesToObject(focusedObjectCopy);
                const requiresConfirmation = await this.buildingBlocksService.validateUpdateDbGear(this.buildingBlockHelper.GearToDbGear(focusedObjectCopy)).toPromise();
                if(requiresConfirmation.responseCode === coreResponseCodes.RequiresConfirmation){
                    const isUpdateCancelled = !await confirm(requiresConfirmation.message, 'Saved Columns Affected');
                    if(isUpdateCancelled){
                        this.buildingBlockHelper.setShowLoadPanel(false);
                        return;
                    }
                }
                this.buildingBlocksService.updateDbGear(this.buildingBlockHelper.GearToDbGear(focusedObjectCopy)).pipe(finalize(() => {
                    this.buildingBlockHelper.setShowLoadPanel(false);
                })).subscribe(updateResponse => {
                    this.applyChangesToObject(this.focusedObject);
                    this.buildingBlockHelper.clearUnsavedGridProperties();
                    this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(gearId, true);
                    this.buildingBlockHelper.addObjectsToStore(updateResponse.objects);
                    this.buildingBlockHelper.setFocusedObject(updateResponse.objects.find(object => object instanceof Gear && object.parentId === this.scopeContainer.id));
                    this.buildingBlocksService.getContainerById(this.buildingBlockHelper.getParentByChildObject(this.focusedObject).id).subscribe(scopeContainer => {
                        if (this.scopeContainer.id === scopeContainer.id) {
                            this.buildingBlockHelper.setScopeContainer(scopeContainer);
                        }
                        this.buildingBlockHelper.replaceObjectInStore(scopeContainer);
                        if (scopeContainer.typeId === containerTypeIds.Group) {
                            this.buildingBlockHelper.setGearboxTemplates();
                            if (scopeContainer.parentId) {
                                this.buildingBlocksService.getContainerById(scopeContainer.parentId).subscribe(rule => {
                                    this.buildingBlockHelper.replaceObjectInStore(rule);
                                    this.buildingBlockHelper.forceRefreshDiagram();
                                });
                            }
                        }
                        this.toast.success(`'${this.focusedObject.name}' saved successfully`);
                    });
                    this.buildingBlockHelper.forceRefreshDiagram();
                    this.buildingBlockHelper.addColumnsToStore(requiresConfirmation.result.dataColumns);
                },
                err => {
                    this.toast.error(err.message);
                });
            }
        } else if (this.focusedObject['typeId'] === EnumContainerType.Group) {
            const updatedGearbox: Group = this.focusedObjectChanges as Group;
            this.focusedObject.name = this.focusedObjectChanges.name;
            this.focusedObject.description = this.focusedObjectChanges.description;
            this.buildingBlocksService.updateContainer(updatedGearbox).subscribe(res => {
                if (res.responseCode === coreResponseCodes.Success) {
                    this.buildingBlockHelper.updateStoreObject(updatedGearbox, true);
                    this.toast.success(`'${updatedGearbox.name}' saved successfully`);
                } else {
                    this.toast.error(res.message);
                }
            });
        } else if (this.focusedObject['typeId'] === EnumContainerType.ProductionRule || this.focusedObject['typeId'] === EnumContainerType.AIRule) {
            if (this.buildingBlockHelper.ruleHasGearNameCollision(this.focusedObjectChanges.name, this.focusedObjectChanges.id)) {
                this.toast.error('Cannot name a rule the same as any gear.');
                return null;
            }
            this.buildingBlockHelper.setShowLoadPanel(true);
            if(this.savedColumnsEditDetails && Object.keys(this.savedColumnsEditDetails).length > 0) {
                let displayString = '';
                Object.keys(this.savedColumnsEditDetails).forEach(key => {
                    const destFriendlyName: string = this.buildingBlockHelper.getDataColumnBySystemName(key)?.friendlyName ?? key;
                    const value = this.savedColumnsEditDetails[key];
                    const savedColumnName: string = this.savedColumnsEditDetails[key]?.name ?? destFriendlyName;
                    if(value.id === -2) {
                        displayString += `<span class="removed-column-highlight">Removed column: ${savedColumnName}</span><br>`;
                    } else if(value.id === -1) {
                        displayString += `Inserted column: ${destFriendlyName} as ${savedColumnName}<br>`;
                    } else {
                        displayString += `Edited column: ${savedColumnName}`;
                    }
                });
                let savedColumnsConfirmWithName = displayString + this.savedColumnsConfirmationWarning;
                let downstreamRules: Container[] = [];
                if(Object.values(this.savedColumnsEditDetails).some(detail => detail.id !== -1)){
                    downstreamRules = (await this.buildingBlocksService.getDownstreamRules(this.focusedObject.id, this.focusedObject['periodBeginId']).toPromise().then(res => res.result,
                    err => {
                        savedColumnsConfirmWithName += this.savedColumnsTimeoutWarning;
                        return [];
                    }));
                }
                if(downstreamRules.length > 0){
                    let downstreamRulesString: string = '<br><br>Downstream rules affected:';
                    downstreamRules.forEach(rule => {
                        downstreamRulesString += `<br>${rule.name}`;
                    });
                    this.openSavedColumnsConfirmationPopup(savedColumnsConfirmWithName + downstreamRulesString + this.versionConflictConfirmationWarning);
                } else {
                    this.openSavedColumnsConfirmationPopup(savedColumnsConfirmWithName);
                }
                const isConfirmed = await this.areSavedColumnsConfirmed.toPromise();
                this.areSavedColumnsConfirmed = new BehaviorSubject<boolean>(false);
                if(!isConfirmed){
                    this.toast.info('Rule has not been altered.');
                    this.savedColumnsEditDetails = {};
                    this.buildingBlockHelper.revertUnsavedChanges();
                    this.buildingBlockHelper.setShowLoadPanel(false);
                    return;
                }
            }
            Object.keys(this.savedColumnsAllDetails).forEach(key => {
                this.savedColumnsAllDetails[key].ruleFriendlyName = this.focusedObjectChanges.name;
            });
            const updatedContainer: Container = new Group(
                this.focusedObjectChanges.id,
                this.focusedObjectChanges.name,
                this.focusedObjectChanges.parentId,
                this.focusedObjectChanges.description,
                this.focusedObjectChanges.typeId,
                JSON.stringify(this.containerProperties),
                this.focusedObjectChanges.recurrenceId,
                this.focusedObjectChanges.lastModified,
                this.focusedObjectChanges.locked,
                this.focusedObjectChanges.headProcessId,
                this.focusedObjectChanges.isActive,
                this.focusedObjectChanges.periodBeginId,
                this.focusedObjectChanges.periodEndId);
            const context: ContainerRuleContext = {
                id: updatedContainer.id,
                name: updatedContainer.name,
                description: updatedContainer.description,
                recurrenceId: updatedContainer.recurrenceId,
                objectJson: updatedContainer.objectJson,
                parentId: updatedContainer.parentId,
                typeId: updatedContainer.typeId,
                locked: updatedContainer.locked,
                isActive: updatedContainer.isActive,
                savedColumnsAllDetails: this.savedColumnsAllDetails,
                periodBeginId:updatedContainer.periodBeginId,
                periodEndId: updatedContainer.periodEndId
            };
            if(this.focusedObject.name !== this.focusedObjectChanges.name) {
                await this.buildingBlocksService.updateRule(context).toPromise().then(res => {
                    if(res.message != null) {
                        this.toast.error(`${updatedContainer.name} not saved: ${res.message}`);
                        this.buildingBlockHelper.revertUnsavedChanges();
                        this.buildingBlockHelper.setShowLoadPanel(false);
                        return;
                    }
                });
                this.buildingBlockHelper.getAllContainers().filter(object => object.name === this.focusedObject.name).forEach(container => {
                    container.name = this.focusedObjectChanges.name;
                    this.buildingBlockHelper.updateStoreObject(container, false);
                });
            }
            this.buildingBlocksService.updateRuleVersion(context).subscribe(res => {
                if(res.message != null) {
                    this.toast.error(`${updatedContainer.name} not saved: ${res.message}`);
                    this.buildingBlockHelper.revertUnsavedChanges();
                } else {
                    this.focusedObject['objectJson'] = res.result.objectJson;
                    this.toast.success(`'${updatedContainer.name}' saved successfully`);
                    this.focusedObject.name = this.focusedObjectChanges.name;
                    this.focusedObject.description = this.focusedObjectChanges.description;
                    this.focusedObjectChanges.objectJson = res.result.objectJson;
                    this.buildingBlockHelper.refreshDataColumns();
                    this.containerProperties = JSON.parse(res.result.objectJson);
                    this.buildingBlockHelper.updateStoreObject(res.result, true);
                }
                this.buildingBlockHelper.setShowLoadPanel(false);
            });
        }
    }

    completeSavedColumnConfirmation(proceed: boolean){
        this.areSavedColumnsConfirmed.next(proceed);
        this.areSavedColumnsConfirmed.complete();
    }

    openSavedColumnsConfirmationPopup(changesString: string){
        this.savedColumnsConfirmationPopupProps = new CorePopupProperties().createMessageOnly('600', '400', true, 'Confirm Saved Column Edits', false, false, false, false, null, false);

        this.savedColumnsConfirmationPopupSteps = [
            new CorePopupStep(
                changesString,
                () => this.completeSavedColumnConfirmation(true),
                () => this.completeSavedColumnConfirmation(false),
                this,
                'Confirm',
                'Revert'
            )
        ];
        this.savedColumnsConfirmationPopupProps.visible = true;
    }

    onRevertNodeButtonClick(e): void {
        this.buildingBlockHelper.revertUnsavedChanges();
    }

    getSegmentCriteriaStr() {
        let criteria = '';
        if (this.criteriaFilterBuilders.length) {
            const conditions = [];
            this.criteriaFilterBuilders.forEach(fb => {
                const fbConditions = [...fb.normalizeConditionsWithGroups(fb.idProps.value),
                    ...fb.normalizeConditions(fb.qtyProps.value),
                    ...fb.normalizeConditions(fb.dateProps.value),
                    ...fb.normalizeConditions(fb.acctProps.value)];
                conditions.push(...fbConditions);
            });
            criteria = conditions.map(c => c[0] + ' ' + this.criteriaFilterBuilders.first.convertConditionToSql(c)).join(' AND ');
        }
        return criteria;
    }

    formulaValueChanged(newValue): void {
        this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'displayFormulaString').value = newValue;
    }

    formulaSqlValueChanged(newValue): void {
        this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'sqlFormulaString').value = newValue;
    }

    evaluateConditionalDisplay(gearProp: GearProperty): boolean {
        const focusedGear = this.focusedObjectChanges;
        const isShowAdvancedView = this.showAdvancedView;
        if (gearProp.conditionalDisplayArg) {
            // eslint-disable-next-line no-eval
            const isDisplayed: boolean = eval(gearProp.conditionalDisplayArg);
            if(!isDisplayed){
                const changedProperty = this.focusedObjectChanges.propertyValues.find(prop => prop.property.systemName === gearProp.systemName);
                if(changedProperty && gearProp.systemName !== 'filterString'){
                    changedProperty.value = this.focusedObject['propertyValues'].find(prop => prop.property.systemName === gearProp.systemName)?.value;
                }
            }
            return isDisplayed;
        }
        return true;
    }

    process() {
        this.buildingBlocksService.processRule(this.scopeContainer.id, this.seriesId, this.periodId).
            subscribe(res => this.toast.success(`Rows Inserted: ${res}`), e => (this.toast.error('Error occurred while processing. No changes made.')));
    }

    persist() {
        this.buildingBlocksService.persistRowUpdate(this.focusedObject.id, this.seriesId, this.periodId).
            subscribe(res => this.toast.success(`Rows Updated: ${res}`), e => (this.toast.error('Error occurred while processing. No changes made.')));
    }

    getSqlFormula = async () => {
        if (!this.focusedObjectChanges.id.startsWith('Sg')) {
            const displayFormulaString = this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'displayFormulaString').value;
            const convertIntsToFloat = this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'convertIntegersToFloat').value;
            const containerId = this.focusedObjectChanges.id.slice(2);
            const sqlFormulaString = await this.buildingBlocksService.getSqlFormulaString(displayFormulaString, containerId, convertIntsToFloat).toPromise();
            this.focusedObjectChanges.propertyValues.find(x => x.property.systemName === 'sqlFormulaString').value = sqlFormulaString;
        }
    };

    onPropChangeUpdateMappings(e: any) {
        if (e && this.focusedObject instanceof Gear){
            this.buildingBlockHelper.setFocusedObjectChanges(this.focusedObjectChanges);
            const currentProperties = this.focusedObjectChanges.propertyValues.map(p => p.property.systemName);
            this.gearPropertyService.updateMappings(currentProperties);
        }
    }

    isWideFieldType(type: GearPropertyDatatype): boolean {
        return this.widePropertyTypes.includes(type);
    }

    AdjustPanelWidthForFilterBuilderValues() {
        const padding = 52;
        const valueMaxAutoWidth = 200;
        const valueElements = Array.from(document.getElementsByClassName('dx-filterbuilder-text dx-filterbuilder-item-value'));
        const exprTargetWidths = valueElements.map(x => x['offsetLeft'] + Math.min(x['offsetWidth'], valueMaxAutoWidth) + padding);
        const maxExprTargetWidth = Math.max(...exprTargetWidths);

        this.buildingBlockHelper.setPropertyPanelWidthRequest(maxExprTargetWidth);
    }

    isUnsavedChange(propertyName: string){
        const changes = this.buildingBlockHelper.getUnsavedChanges();
        return changes.includes(propertyName);
    }

    isFocusedObjectProductionRule() {
        return this.focusedObjectChanges.typeId === containerTypeIds.ProductionRule;
    }

    isFocusedObjectAIRule() {
        return this.focusedObjectChanges.typeId === containerTypeIds.AIRule;
    }

    applyChangesToObject(object: BbObject){
        if (object instanceof Gear) {
            object.applyChanges(this.focusedObjectChanges);
            for (let i = 0; i < object.propertyValues.length; i++) {
                const properties = object.propertyValues;
                // Todo: Refactor Segment gear to work off of DbGear model and remove this branch
                if (object.gearTypeCode === GearCode.SegmentGearCode) {
                    if (properties[i].property.friendlyName === ('Payment')) {
                        properties[i].value = object.isPayment ?? false;
                    }
                    if (properties[i].property.friendlyName === ('Criteria Filter')) {
                        properties[i].value = this.getSegmentCriteriaStr();
                    }
                    if (properties[i].value === Object(properties[i].value)) {
                        properties[i] = this.helperService.deepCopyTwoPointO(properties[i]);
                        properties[i].value = JSON.stringify(properties[i].value);
                    } else {
                        if (typeof properties[i].value === 'number') {
                            properties[i].value = `${properties[i].value}`;
                        }
                        properties[i].value = properties[i].value;
                    }
                } else {
                    properties[i].value = properties[i].value;
                }
            }
        }
    }

    convertSegment(e: any){
        this.buildingBlocksService.convertSegment(+this.focusedObject.id.replace('Sg', '')).subscribe(containerRes => {
            if(containerRes.responseCode === coreResponseCodes.Success){
                const newContainerId = containerRes.result.id;
                this.toast.success('Segment converted successfully, refreshing cache...');
                this.buildingBlocksService.resetBuildingBlockService().subscribe(resetRes => {
                    setTimeout(() => {
                        this.router.navigate(['building-blocks/' + newContainerId]).then(_ => {
                            window.location.reload();
                        });
                    }, 2000);
                });
            } else {
                this.toast.error(containerRes.message);
            }
        });
    }

    checkFileManagerContainer(e: any){
        this.appFileManager.reloadFilesFromAzure('user-defined-documents');
    }
}
