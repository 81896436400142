import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CorePopupComponent } from 'src/app/shared/components/core-popup/core-popup.component';
import { CorePopupProperties } from 'src/app/shared/models/core-popup-properties';
import { CorePopupStep } from 'src/app/shared/models/core-popup-step';
import { FileProcessingService } from 'src/app/shared/services/file-processing.service';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { CoreRequest } from 'src/app/shared/models/core-request';
import { CoreImportPacket } from 'src/app/shared/models/core-import-packet';
import { ImportCleaningSourceData } from 'src/app/shared/models/import-cleaning-source-data';
import { PollValue } from 'src/app/shared/models/poll-value';
import { coreResponseCodes, fileUploadTypeIds, processLogRecordTypes } from '../../constants/enums';

@Component({
    selector: 'core-import',
    templateUrl: './core-import.component.html',
    styleUrls: ['./core-import.component.scss']
})
export class CoreImportComponent implements OnInit, OnDestroy {
    @ViewChild('popup', { static: false })
    popup: CorePopupComponent;

    @Input() importURL: string;
    @Input() importKey: string = '';
    @Input() description: string;
    @Input() importCleaningSourceData: ImportCleaningSourceData;

    @Output() onImportSuccess = new EventEmitter();

    pageDestroyed: boolean = false;
    popupInitialHeight: string = '250';
    popupInitialWidth: string = '510';
    popupDefaultHeight: string = '400';
    popupDefaultWidth: string = '650';
    popupProps: CorePopupProperties;
    popupSteps: CorePopupStep[];
    importFileId: string;
    isImportFileDirty: boolean = false;
    isImportInProgress: boolean = false;
    isAwaitingImportResponse: boolean = false;

    constructor(
        private fileProcessingService: FileProcessingService,
        private processLogService: ProcessLogService,
        private toast: ToastrService) {
    }

    ngOnInit(): void {
        this.resetImportPopup(null, null, false, true);
    }

    open(): void {
        this.resetImportPopup(null, null, true);
    }

    close(): void {
        if (!this.isAwaitingImportResponse) {
            this.isImportInProgress = false;
        }
        this.resetImportPopup(null, null, false);
    }

    resetImportPopup(e: CorePopupStep = null, component: CorePopupComponent = null, openPopup: boolean = false, isInstantaneous: boolean = false): void {
        if (isInstantaneous) {
            if (!this.isImportInProgress) {
                this.popupProps = new CorePopupProperties().createMessageOnly(this.popupInitialWidth, this.popupInitialHeight, true,
                    `Import ${this.description}`);
                this.popupProps.uploadUrl = this.fileProcessingService.getFileUploadUrl();
                this.popupSteps = [ new CorePopupStep('', null, this.close, this, 'Import File', 'Cancel', false, false)
                    .enableFileUpload(this.importFile, 'Select File', 'or Drag and Drop: Excel, CSV, or TSV files', ['.xls', '.xlsx', '.csv', '.tsv'], 'instantly',
                      fileUploadTypeIds.ImportAccountHistory, false)];
                if (this.popup) {
                    this.popup.steps = this.popupSteps;
                }
                if (openPopup === true) {
                    this.popupProps.visible = true;
                }
            }
        } else {
            setTimeout(() => {
                this.resetImportPopup(e, null, openPopup, true);
            });
        }
    }

    importFile(event: any, value: CorePopupStep, isCleaningDataInGrid: boolean = false): void {
        this.isImportInProgress = true;
        const importRequest = new CoreRequest<CoreImportPacket>(null, []);

        if (!isCleaningDataInGrid) {
            this.importFileId = value.uploadProps.importedFileIds[0];
            const importPacket = new CoreImportPacket(this.importFileId, null, this.importKey);
            importRequest.arguments.push(importPacket);
        } else {
            const importPacket = new CoreImportPacket(this.importFileId, null, this.importKey);
            importPacket.addCleanedRecords(this.popup.steps.find(x => x.dataGrid).dataGrid.dataSource);
            importRequest.arguments.push(importPacket);
        }

        this.isAwaitingImportResponse = true;
        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            importRequest.transactionId = newTransactionResponse.result;
            this.pollProcessLogByTransactionId('Import Log', importRequest.transactionId);
            this.isImportFileDirty = false;
            this.fileProcessingService.coreImport(this.importURL, importRequest).subscribe(response => {
                this.isAwaitingImportResponse = false;
                if (response.responseCode === coreResponseCodes.Success) {
                    this.toast.success(`${this.description} imported successfully`);
                    this.isImportInProgress = false;
                    this.popup.steps[this.popup.stepIndex].okAndCancelVisible = false;
                    this.onImportSuccess.emit();
                } else if (response.responseCode === coreResponseCodes.DirtyData) {
                    this.isImportFileDirty = true;
                    const cleaningPopupStep = new CorePopupStep(null, (e: CorePopupStep) => {
                        this.importFile(null, null, true);
                    }, null, this, 'Finalize Record Cleaning');
                    cleaningPopupStep.enableImportCleaningGrid(response.results[0].cleaningRecords, this.importCleaningSourceData);
                    this.popup.steps[this.popup.stepIndex].okAndCancelVisible = false;
                    this.popupProps.visible = true;
                    setTimeout(() => {
                        this.changePopupPurpose(this.popup, this.popupProps, this.popup.steps, 'Clean Imported Records');
                        this.popup.steps.push(cleaningPopupStep);
                    }, 1500);
                } else {
                    this.toast.error(response.message, response.messageHeader);
                    this.isImportInProgress = false;
                }
            });
        });
    }

    pollProcessLogByTransactionId(popupTitle: string, transactionId: string, shouldCloseOnSuccess: boolean | (() => boolean) = false) {
        const poll = new BehaviorSubject(new PollValue<number>(0));
        this.changePopupPurpose(this.popup, this.popupProps, this.popup.steps, popupTitle);
        const logStep = new CorePopupStep('', this.resetImportPopup, this.resetImportPopup, this, 'Close Log', 'Cancel', false, false, false, true);
        logStep.isPreformatted = true;
        this.popupProps.width = this.popupDefaultWidth;
        this.popupProps.height = this.popupDefaultHeight;
        this.popup.steps.push(logStep);
        this.popupProps.visible = true;
        poll.subscribe(lastValue => {
            setTimeout(() => {
                this.processLogService.getProcessLogsByTransactionId(transactionId, poll.value.value).subscribe(logs => {
                    let continuePolling = true;
                    logs.results.forEach(log => {
                        logStep.message += log.log + '\n';
                        if (log.processLogRecordTypeId === processLogRecordTypes.Error || log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                            continuePolling = false;

                            const shouldClose = (typeof shouldCloseOnSuccess === 'function') ? shouldCloseOnSuccess() : shouldCloseOnSuccess;
                            if (shouldClose && log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                                this.popupProps.visible = false;
                            }
                        }
                    });

                    if (continuePolling) {
                        let idToPollFrom = poll.value.value;
                        if (logs.results && logs.results.length > 0) {
                            idToPollFrom = logs.results.map(x => x.id).reduce((a, b) => Math.max(a, b));
                        }

                        poll.next(new PollValue(idToPollFrom));
                    }

                    setTimeout(() => {
                        this.popup.setScrollHeightToBottom();
                    }, 100);
                });
            }, lastValue.getTimeout(1000));

            if (this.pageDestroyed) {
                poll.unsubscribe();
            }
        });
    }

    changePopupPurpose(popup: CorePopupComponent, popupProps: CorePopupProperties, popupSteps: CorePopupStep[], title: string,
        height: string = this.popupDefaultHeight, width: string = this.popupDefaultWidth): void {
        popupProps.title = title;
        while (popupSteps.length > 0) {
            popupSteps.pop();
        }
        popup.changePopupSize(height, width);
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }
}
