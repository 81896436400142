<dx-data-grid
    [(dataSource)]="gridDatasource"
    (onContentReady)="checkForChanges()"
    (onRowInserted)="emitValue()"
    (onRowRemoved)="emitValue()"
    (onRowUpdated)="emitValue()"
    (onToolbarPreparing)="filterToolbarItems($event)">
    <dxi-column dataField="item" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
    </dxi-column>
    <dxo-editing mode="batch" [allowUpdating]="isEditable" [allowDeleting]="isEditable" [allowAdding]="isEditable"></dxo-editing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
</dx-data-grid>
