<dx-popup
    #corePopupComponent
    class="core-component"
    [width]="props.width"
    [height]="props.height"
    [showTitle]="props.showTitle"
    [title]="props.title"
    [dragEnabled]="props.dragEnabled"
    container=".dx-viewport"
    [resizeEnabled]="props.resizeEnabled"
    [hideOnOutsideClick]="props.closeOnOutsideClick"
    [maxHeight]="props.maxHeight"
    [showCloseButton]="props.showCloseButton"
    [(visible)]="props.visible"
    (onHiding)="onHiding($event)"
    (onResizeEnd)="onResizeEnd($event)">
    <ng-container *ngIf="props && steps && steps.length > 0">
        <div id="corePopupContentWrapperId" class="core-popup-content-wrapper">
            <dx-scroll-view #popupScrollView [ngSwitch]="steps[stepIndex].isPreformatted" width="100%" height="100%" (onScroll)="onScroll($event)">
                <pre *ngSwitchCase="true" [innerHTML]="steps[stepIndex].message"></pre>
                <div *ngSwitchCase="false" [innerHTML]="steps[stepIndex].message"></div>
                <ng-container *ngIf="steps[stepIndex].itemsProps && steps[stepIndex].itemsProps.length > 0">
                    <div [ngClass]="{ 'item-props-single-line': steps[stepIndex].areAllPropsOnOneLine }">
                        <ng-container *ngFor="let itemProps of steps[stepIndex].itemsProps; index as i">
                            <div [ngClass]="{ 'single-line-input': itemProps.isSingleLineInput }">
                                <div
                                    *ngIf="itemProps.label"
                                    [ngClass]="{
                                        'dx-field-label dx-field-label-margin': !itemProps.isSingleLineInput,
                                        'dx-field-label': itemProps.isSingleLineInput
                                    }">
                                    {{ itemProps.label }}
                                </div>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.DateBox">
                                    <div [ngClass]="{ 'dx-field-value': itemProps.label }" [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-date-box 
                                            [type]="itemProps.type" 
                                            [value]="itemProps.selectedValue" 
                                            [displayFormat]="itemProps.format" 
                                            (onValueChanged)="editorBoxOptionChanged($event, i)">
                                        </dx-date-box>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.NumberBox">
                                    <div 
                                        [ngClass]="{ 'dx-field-value': itemProps.label }"
                                        [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-number-box 
                                            [value]="itemProps.selectedValue" 
                                            [format]="itemProps.format" 
                                            [showClearButton]="true" 
                                            stylingMode="underlined"
                                            (onValueChanged)="editorBoxOptionChanged($event, i)">
                                        </dx-number-box>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.TextBox">
                                    <div 
                                        [ngClass]="{ 'dx-field-value': itemProps.label }"
                                        [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-text-box 
                                            [value]="itemProps.selectedValue" 
                                            [showClearButton]="true" 
                                            valueChangeEvent="keyup" 
                                            stylingMode="underlined"
                                            (onValueChanged)="editorBoxOptionChanged($event, i)">
                                        </dx-text-box>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.SelectBox">
                                    <div
                                        [ngClass]="{ 'dx-field-value': itemProps.label }"
                                        [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-select-box
                                            [searchEnabled]="true"
                                            [dataSource]="itemProps.dataset"
                                            [value]="itemProps.selectedValue"
                                            [displayExpr]="itemProps.displayExpr"
                                            [valueExpr]="itemProps.valueExpr"
                                            stylingMode="underlined"
                                            (onOptionChanged)="selectBoxOptionChanged($event, i)">
                                            <ng-container *ngFor="let rule of itemProps.validationRules">
                                                <dxi-validation-rule
                                                    [type]="rule.type"
                                                    [message]="rule.message"
                                                    [validationCallback]="rule.validationCallbackFunction"
                                                    [reevaluate]="rule.isReevaluated">
                                                </dxi-validation-rule>
                                            </ng-container>
                                        </dx-select-box>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.List">
                                    <div [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-list
                                            [id]="itemProps.uniqueId"
                                            [dataSource]="itemProps.dataset"
                                            [displayExpr]="itemProps.displayExpr"
                                            [keyExpr]="itemProps.valueExpr"
                                            [height]="itemProps.height"
                                            [showSelectionControls]="itemProps.areSelectionControlsShown"
                                            [selectionMode]="itemProps.selectionMode"
                                            [(selectedItemKeys)]="itemProps.selectedItemKeys"
                                            (onSelectionChanged)="listSelectionChanged($event, i)"
                                            (onContentReady)="onListItemContentReady($event, i)">
                                        </dx-list>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.Lookup">
                                    <div
                                        [ngClass]="{ 'dx-field-value padded-top-control': itemProps.label }"
                                        [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-lookup
                                            [dataSource]="itemProps.dataset"
                                            [value]="itemProps.selectedValue"
                                            [displayExpr]="itemProps.displayExpr"
                                            [valueExpr]="itemProps.valueExpr"
                                            placeholder="Select..."
                                            [showClearButton]="true"
                                            [searchEnabled]="true"
                                            stylingMode="underlined"
                                            (onOptionChanged)="selectBoxOptionChanged($event, i)">
                                            <ng-container *ngFor="let rule of itemProps.validationRules">
                                                <dxi-validation-rule
                                                    [type]="rule.type"
                                                    [message]="rule.message"
                                                    [validationCallback]="rule.validationCallbackFunction"
                                                    [reevaluate]="rule.isReevaluated">
                                                </dxi-validation-rule>
                                            </ng-container>
                                        </dx-lookup>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="itemProps.editorType === htmlTemplateEditorType.CoreTableDropDown">
                                    <div
                                        [ngClass]="{ 'dx-field-value': itemProps.label }"
                                        [style.padding-left]="itemProps.leftPad ? itemProps.leftPad : null">
                                        <dx-drop-down-box
                                            #coreTableDropDownDropDown
                                            [dropDownOptions]="itemProps.dropDownOptions"
                                            [dataSource]="itemProps.dataset"
                                            [(value)]="itemProps.selectedValue"
                                            [displayExpr]="itemProps.displayExpr"
                                            [valueExpr]="itemProps.valueExpr"
                                            contentTemplate="contentTemplate"
                                            (onOptionChanged)="selectBoxOptionChanged($event, i)">
                                            <div *dxTemplate="let e of 'contentTemplate'">
                                                <core-data-grid
                                                    #coreTableDropDownGrid
                                                    [dataSource]="itemProps.dataset"
                                                    [columns]="itemProps.dropDownGridColumns"
                                                    [props]="itemProps.dropDownGridProps"
                                                    [focusedRowKey]="itemProps.selectedValue"
                                                    [parentTemplate]="{
                                                        itemProps: itemProps,
                                                        parentTemplate: e
                                                    }">
                                                </core-data-grid>
                                            </div>
                                        </dx-drop-down-box>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="steps[stepIndex].uploadProps && steps[stepIndex].uploadProps.visible">
                    <dx-file-uploader
                        [selectButtonText]="steps[stepIndex].uploadProps.fileImportButtonText"
                        [labelText]="steps[stepIndex].uploadProps.fileImportLabel"
                        [allowedFileExtensions]="steps[stepIndex].uploadProps.allowedFileExtensions"
                        [uploadMode]="steps[stepIndex].uploadProps.uploadMode"
                        [multiple]="steps[stepIndex].uploadProps.allowMultiple"
                        name="file"
                        [uploadUrl]="props.uploadUrl"
                        [chunkSize]="400000"
                        [maxFileSize]="steps[stepIndex].uploadProps.maxFileSize"
                        [(value)]="value"
                        [uploadHeaders]="requestHeaders"
                        [readOnly]="isReadOnly"
                        (onValueChanged)="areUploadsDisabled($event)"
                        (onUploaded)="onfileUploadCompleted($event)">
                    </dx-file-uploader>
                </ng-container>
                <ng-container *ngIf="steps[stepIndex].coreDropdown && steps[stepIndex].coreDropdown.props">
                    <core-dropdown
                        #popupCoreDropDown
                        [dropdownName]="steps[stepIndex].coreDropdown.dropdownName"
                        [(selectedValue)]="steps[stepIndex].coreDropdown.selectedValue"
                        [dataSource]="steps[stepIndex].coreDropdown.dataSource"
                        [popupProps]="steps[stepIndex].coreDropdown.popupProps"
                        [props]="steps[stepIndex].coreDropdown.props"
                        (selectionChanged)="steps[stepIndex].coreDropdown.onSelectionChangedFunction($event)"
                        (itemClick)="steps[stepIndex].coreDropdown.onItemClickFunction($event)"
                        (onDelete)="steps[stepIndex].coreDropdown.onDeleteFunction($event)"
                        (onAdd)="steps[stepIndex].coreDropdown.onAddFunction($event)"
                        (onRename)="steps[stepIndex].coreDropdown.onRenameFunction($event)"
                        (onSave)="steps[stepIndex].coreDropdown.onSaveFunction($event)"
                        (onCheckUncheck)="steps[stepIndex].coreDropdown.onCheckUncheckFunction($event)">
                    </core-dropdown>
                </ng-container>
                <!-- This grid must be the only visible element in element corePopupContentWrapperId for its dynamic sizing to function properly -->
                <ng-container *ngIf="steps[stepIndex].dataGrid && steps[stepIndex].dataGrid.dataSource">
                    <core-data-grid 
                        #popupGrid 
                        [dataSource]="steps[stepIndex].dataGrid.dataSource" 
                        [columns]="steps[stepIndex].dataGrid.columns" 
                        [props]="steps[stepIndex].dataGrid.props">
                    </core-data-grid>
                </ng-container>
                <ng-container *ngIf="steps[stepIndex].formProps && steps[stepIndex].formProps.coreFormFieldProperties">
                    <form id="form" (submit)="onAddFormSubmit($event)">
                        <dx-form #addForm [formData]="steps[stepIndex].formProps.defaultFormValues" validationGroup="formData">
                            <dxi-item itemType="group" [colCount]="steps[stepIndex].formProps.formColCount">
                                <ng-container *ngIf="steps[stepIndex].formProps.message">
                                    <dxi-item [colSpan]="steps[stepIndex].formProps.formColCount">
                                        <div class="formFieldPrompt">
                                            {{ steps[stepIndex].formProps.message }}
                                        </div>
                                    </dxi-item>
                                </ng-container>
                                <ng-container *ngFor="let data of steps[stepIndex].formProps.coreFormFieldProperties; let i = index">
                                    <dxi-item itemType="group" [colSpan]="steps[stepIndex].formProps.formColCount">
                                        <dxi-item *ngIf="data.prompt" [colSpan]="steps[stepIndex].formProps.formColCount">
                                            <div class="formFieldPrompt">
                                                {{ data.prompt }}
                                            </div>
                                        </dxi-item>
                                        <ng-container *ngIf="data.editorType === htmlTemplateEditorType.BbPeriodFilter">
                                            <dxo-label text="Period Range"></dxo-label>
                                            <app-bb-period-range-input [selectedRecurrenceId]="1" [(actualValue)]="data.selectedValue"></app-bb-period-range-input>
                                        </ng-container>
                                        <ng-container *ngIf="data.editorType !== htmlTemplateEditorType.BbPeriodFilter">
                                            <dxi-item [dataField]="data.label" [editorType]="data.editorType" [colSpan]="data.colSpan" [editorOptions]="data.editorOptions">
                                                <ng-container *ngIf="data.displayLabel">
                                                    <dxo-label [text]="data.displayLabel"></dxo-label>
                                                </ng-container>
                                                <ng-container *ngFor="let rule of data.validationRules">
                                                    <dxi-validation-rule
                                                        [type]="rule.type"
                                                        [message]="rule.message"
                                                        [validationCallback]="rule.validationCallbackFunction"
                                                        [reevaluate]="rule.isReevaluated">
                                                    </dxi-validation-rule>
                                                </ng-container>
                                            </dxi-item>
                                        </ng-container>
                                    </dxi-item>
                                </ng-container>
                                <dxi-item 
                                    *ngIf="steps[stepIndex].formProps.addButtonProps" 
                                    itemType="button" 
                                    horizontalAlignment="left" 
                                    [buttonOptions]="steps[stepIndex].formProps.addButtonProps">
                                </dxi-item>
                            </dxi-item>
                            <dxi-item cssClass="core-popup-button-wrapper" itemType="group" colCount="2">
                                <dxi-item cssClass="button-item" itemType="button" [buttonOptions]="submitButtonOptions"></dxi-item>
                                <dxi-item itemType="button" [buttonOptions]="cancelButtonOptions"></dxi-item>
                            </dxi-item>
                        </dx-form>
                    </form>
                </ng-container>
            </dx-scroll-view>
        </div>
        <div *ngIf="!steps[stepIndex].formProps" class="core-popup-button-wrapper">
            <dxi-item class="button-item">
                <dx-button #corePopupYesButton class="core-popup-button dx-button-default" [disabled]="okClickDisabled() || !steps[stepIndex].isOkClickEnabled" (click)="okClick($event)" [visible]="steps[stepIndex].okAndCancelVisible">
                    {{ steps[stepIndex].okClickText }}
                </dx-button>
            </dxi-item>
            <dxi-item class="button-item">
                <dx-button #corePopupCancelButton class="core-popup-button" [disabled]="cancelClickDisabled()" (click)="cancelClick($event)" [visible]="steps[stepIndex].okAndCancelVisible">
                    {{ steps[stepIndex].cancelClickText }}
                </dx-button>
            </dxi-item>
        </div>
    </ng-container>
</dx-popup>
