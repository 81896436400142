<app-core-multiline-string-input [height]="this.panelHeight" [props]="readOnlyStringProperties" [value]="this.friendlyValue"></app-core-multiline-string-input>
<dx-button id="formula-builder-button-{{id}}" icon="edit" (click)="launchPopup()" class="formula-builder-button"></dx-button>
<dx-tooltip target="#formula-builder-button-{{id}}" showEvent="dxhoverstart" hideEvent="dxhoverend">
    <div *dxTemplate="let data of 'content'">
        <span>{{this.popupTitle}}</span>
    </div>
</dx-tooltip>

<app-formula-builder-popup
    [(visible)]="this.popupVisible"
    [value]="this.friendlyValue"
    (valueChange)="friendlyValueUpdate($event)"
    [data]="this.data"
    [popupTitle]="this.popupTitle"
    [height]="this.height"
    [id]="this.id">
</app-formula-builder-popup>
<div *ngIf="displaySql" class="dx-field-label sqlLabel">SQL formula</div>
<app-core-string-input *ngIf="displaySql" [props]="readOnlyStringProperties" [value]="value"></app-core-string-input>
