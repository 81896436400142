import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';
import { XactionFileImportCleaningRecord } from '../models/xaction-file-import-cleaning-record';
import { XactionFileImportPacket } from '../models/xaction-file-import-packet';
import { XactionFileImportRequest } from '../models/xaction-file-import-request';
import { SpreadsheetData } from '../models/spreadsheet-data';
import { TOKEN_NAME } from '../services/auth.service';
import { SetupObjectAttachFileContext } from '../models/contexts/setup-object-attach-file-context';
import { CoreImportPacket } from '../models/core-import-packet';

@Injectable()
export class FileProcessingService {
    private fileProcessingURL = environment.apiEndpoint + '/FileProcessing';

    constructor(private http: HttpClient) {
    }

    downloadUploadedFile(fileUploadId: string): Observable<Blob> {
        return this.http.get(this.fileProcessingURL + '/download/' + fileUploadId, { responseType: 'blob' });
    }

    getAllFileUploads(): Observable<SetupObjectAttachFileContext[]> {
        return this.http.get<SetupObjectAttachFileContext[]>(this.fileProcessingURL + '/all-files');
    }

    insertImportedFilesIntoXaction(request: CoreRequest<XactionFileImportRequest>): Observable<CoreResponse<XactionFileImportPacket>> {
        return this.http.post<CoreResponse<XactionFileImportPacket>>(this.fileProcessingURL + '/insertImportedFilesIntoXaction', request);
    }

    insertIntegrationSourceIntoXaction(request: CoreRequest<XactionFileImportRequest>): Observable<CoreResponse<XactionFileImportPacket>> {
        return this.http.post<CoreResponse<XactionFileImportPacket>>(this.fileProcessingURL + '/insertIntegrationSourceIntoXaction', request);
    }

    getFileUploadUrl() {
        return this.fileProcessingURL;
    }

    getFileImportHeaders(fileTypeId: number) {
        const tokenString = localStorage.getItem(TOKEN_NAME);
        return {
            fileTypeId,
            Authorization: `Bearer ${tokenString}`
        };
    }

    removeFileUpload(fileUploadId: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.fileProcessingURL + '/file-upload/' + fileUploadId);
    }

    attachFileToSetupObject(request: CoreRequest<SetupObjectAttachFileContext>): Observable<CoreResponse<SetupObjectAttachFileContext>> {
        return this.http.post<CoreResponse<SetupObjectAttachFileContext>>(this.fileProcessingURL + '/attachFileToSetupObject', request);
    }

    coreImport(url: string, request: CoreRequest<CoreImportPacket>): Observable<CoreResponse<CoreImportPacket>> {
        return this.http.post<CoreResponse<CoreImportPacket>>(url, request);
    }
}
