export class PopupArguments {
    periodIds: number[];
    seriesId: number;
    datasourceIds: number[];
    planIds: string[];
    ruleIds: string[];
    recurrenceId: number;
    title?: string;
    shortTitle?: string;
    sellerFilterIds?: string[];

    constructor() {}

    createForImported(periodIds: number[], seriesId: number, datasourceIds: number[], recurrenceId: number) {
        this.periodIds = periodIds;
        this.seriesId = seriesId;
        this.datasourceIds = datasourceIds;
        this.recurrenceId = recurrenceId;
        return this;
    }

    createForAIProcessed(periodIds: number[], seriesId: number, datasourceIds: number[], recurrenceId: number, ruleIds: string[]){
        this.periodIds = periodIds;
        this.seriesId = seriesId;
        this.datasourceIds = datasourceIds;
        this.recurrenceId = recurrenceId;
        this.ruleIds = ruleIds;
        return this;
    }

    createForProcessed(periodIds: number[], seriesId: number, planIds: string[], ruleIds: string[], recurrenceId: number, sellerIds?: string[]) {
        this.periodIds = periodIds;
        this.seriesId = seriesId;
        this.planIds = planIds;
        this.ruleIds = ruleIds;
        this.recurrenceId = recurrenceId;
        this.sellerFilterIds = sellerIds;

        return this;
    }
}
