import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-bb-field-list',
  providers: [ {provide: SaveableBbProperty, useExisting: BbFieldListComponent }],
  templateUrl: './bb-field-list.component.html',
  styleUrls: ['./bb-field-list.component.scss']
})
export class BbFieldListComponent extends SaveableBbProperty {

  @Input() isEditable: boolean = true;
  @Input() set datasource(source: any[]) {
    this.gridDatasource = source;
  }
  get datasource() {
    return this.gridDatasource;
  }
  @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>(true);
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  isValid: boolean = true;
  gridDatasource: any[];
  gearPropertyMappings: Record<string, any>;

  constructor(private bbHelper: BuildingBlockHelperService, private helperService: HelperService) {
    super();
  }

  checkForChanges(){
    if(this.dataGrid.instance.hasEditData()){
      this.bbHelper.setGridPropertyAsUnsaved('classifications');
    } else {
      this.bbHelper.removeGridPropertyFromUnsaved('classifications');
    }
  }

  emitValue() {
    this.gridDatasource = this.gridDatasource.map(entry => {
      const entryCopy = this.helperService.deepCopyTwoPointO(entry);
      delete entryCopy['__KEY__'];
      return entryCopy;
  });
    this.datasourceChange.emit(this.gridDatasource);
  }

  saveInternalData(): Promise<void> {
    return this.dataGrid?.instance.saveEditData();
  }

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
  filterToolbarItems(e){
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
  }

  sortColumn(data) {
    const column = this as any;
    const value = column.calculateCellValue(data);
    return column.lookup.calculateCellValue(value);
  }
}

