import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, ViewChild, ElementRef, ViewChildren, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DxPopoverComponent, DxTextBoxComponent, DxToolbarComponent } from 'devextreme-angular';
import { CoreBotRequest } from '../../../models/core-bot-request';
import { NbButton, NbChatComponent } from '@nebular/theme';
import * as events from 'devextreme/events';
import { SellerService } from '../../../services/seller.service';
import { CoreBotService } from '../../../services/core-bot.service';
import { CoreBotPopupContentComponent } from '../corebot-shared/popup-content/corebot-popup-content.component';
import { Gear, Container, ContainerRule, Group } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { combineLatest, forkJoin, of } from 'rxjs';
import { ChatBotUIObject } from 'src/app/shared/models/chatbot-ui-object';
import { CoreBotResponse } from 'src/app/shared/models/core-bot-response';
import { ChatBotUIType, CoreFeature, EnumUserGroup, RuleBotStages, coreResponseCodes, processingDataViewerFunctions } from 'src/app/shared/constants/enums';
import { FieldService } from 'src/app/shared/services/field.service';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { PopupArguments } from 'src/app/shared/models/popup-arguments';
import { RequiredProperty } from 'src/app/shared/models/required-property';
import { DisplayDataArguments } from 'src/app/shared/models/core-display-data-arguments';
import { PeriodService } from 'src/app/shared/services/period.service';
import { map } from 'rxjs/operators';
import { HelperService } from 'src/app/shared/services/helper.service';
import { TreeListProps } from 'src/app/shared/models/core-tree-list-properties';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { UserMessage } from 'src/app/shared/models/chat-messages/user-message';
import { BotTypingMessage } from 'src/app/shared/models/chat-messages/bot-typing-message';
import { BasicChatMessage } from 'src/app/shared/models/chat-messages/basic-chat-message';
import { ChatBotMessage } from 'src/app/shared/models/chat-messages/chatbot-message';
import { AuditBotMessage } from 'src/app/shared/models/chat-messages/auditbot-message';
import { ConfirmButtomMessage } from 'src/app/shared/models/chat-messages/confirm-button-message';
import { AuditBotReportRedirect } from 'src/app/shared/models/chat-messages/auditbot-report-redirect';
import { AuditGridFilterCriteria } from 'src/app/shared/models/audit-grid-filter-criteria';
import { RuleVersionBatchChanges } from 'src/app/shared/models/rule-version-batch-changes';
import { Period } from 'src/app/shared/models/period';

@Component({
    selector: 'app-rulebot-chat',
    templateUrl: './rulebot-chat.component.html',
    styleUrls: ['./rulebot-chat.component.scss']
})
export class RuleBotChatComponent implements OnInit, AfterViewInit {
    @ViewChild('nebularChat', {static: false}) nebularChat: NbChatComponent;
    @ViewChild('ruleBotTextArea') ruleBotTextArea: ElementRef;
    @ViewChild('sendButton') sendButton: NbButton;
    @ViewChild('chatContent') chatContent: CoreBotPopupContentComponent;
    @ViewChild('newConversationButton') newConversationButton: DxToolbarComponent;

    @Input() buttonIcon: string = 'paper-plane-outline';
    @Input() message: string = '';
    @Input() isReportAudit: boolean = false;
    @Input() reportInfo: any;
    @Input() position: string = 'right';
    @Input() chatTitle: string = 'AI Rule Building Assistant';
    @Input() initialMessage: string = 'Need help building a rule? Ask me any question about rule building, and I\'ll do my best to help you out!' +
    '\n\nPlease note that creating AI-generated gears is not always accurate, so audit thoroughly after using this tool.';
    @Output() onInputChange = new EventEmitter<string>();

    @Input() showPopup: boolean = false;
    @Output() showPopupChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    coreBotConversationId: string = null;
    showLoadingIndicator: boolean = false;
    messages: any[];
    sellerName: string = '';
    request: CoreBotRequest;
    bot: string;
    isUsingCoreBotBeta: boolean = false;
    scopeContainer: Container;
    isSupportVisible: boolean = false;
    samplePrompt: string;

    seriesId: number;
    periodId: number;
    focusedObject: Gear;
    dataColumns: any;
    reportRules: any;
    username: string;
    auditedRow: AuditGridFilterCriteria;
    newRuleVersionPeriods: any;
    switchToVersion: Container;
    constructor(
        private sellerService: SellerService,
        private coreBotService: CoreBotService,
        private buildingBlocksService: BuildingBlocksService,
        private buildingBlockHelper: BuildingBlockHelperService,
        private fieldService: FieldService,
        private periodService: PeriodService,
        private helperService: HelperService,
        private authService: AuthService,
        private permissionService: PermissionService
    )
    {
        this.clearCoreBotConversation = this.clearCoreBotConversation.bind(this);
        this.showTicketWidget = this.showTicketWidget.bind(this);
    }

    ngOnInit() {
        this.sellerService.getMe().subscribe(seller => {
            this.sellerName = seller.name;
        });
        this.username = this.authService.getUserFromToken();
        this.messages = [{
            text: this.initialMessage,
            date: new Date(),
            reply: false,
            user:{
                name: 'AuditBot',
                avatar: '../../../../assets/images/AuditBotIcon.png'
        }}];
        this.samplePrompt = this.getSamplePrompt();
    }

    ngAfterViewInit(): void {
        if (localStorage.getItem('CoreBotQuestion') !== null){
            this.showPopup = true;
            this.message = localStorage.getItem('CoreBotQuestion');
            localStorage.removeItem('CoreBotQuestion');
        }

        this.sendButton.disabled = true;

        combineLatest([
            this.buildingBlockHelper.getSeriesId(),
            this.buildingBlockHelper.getPeriodId(),
            this.buildingBlockHelper.getFocusedObjectChanges(),
            this.buildingBlockHelper.getAuditedRow(),
            this.buildingBlockHelper.getDataColumns(),
            this.buildingBlockHelper.getScopeContainer()
        ]).pipe().subscribe(([seriesId, periodId, focusedObject, auditedRow, columns, scopeContainer]) => {
            this.seriesId = seriesId;
            this.periodId = periodId;
            this.focusedObject = focusedObject;
            this.auditedRow = this.helperService.deepCopyTwoPointO(auditedRow);
            if(this.auditedRow){
                this.auditedRow.auditedObjToRowPath = this.auditedRow.auditedObjToRowPath.slice(this.auditedRow.auditedObjToRowPath.length - 1);
            }
            this.scopeContainer = scopeContainer;
            this.dataColumns = {dataSource: columns, displayExpr: 'friendlyName', valueExpr: 'systemName'};
        });
    }

    onContentReady(e){
        events.on(e.component.content(), 'dxmousewheel', null, (ev) => {
            ev.stopPropagation();
        });
    }

    submitText(e: any){
        if (this.isReportAudit){
            this.submitReportAudit();
        }
        else {
            this.submitRuleAudit();
        }
    }

    submitRuleAudit(){
        if (this.message.length > 0){
            this.request = {
                prompt: this.message,
                conversationId: this.coreBotConversationId,
                containerId: parseInt(this.scopeContainer.id.slice(2), 10),
                additionalInfo: null,
                environment: environment.production ? 'production' : 'test'
            };

            this.messages.push(new UserMessage(this.sellerName, this.message));
            this.message = '';
            this.samplePrompt = 'Enter your prompt...';
            this.disableRuleBotFields();

                setTimeout(() => {
                    this.messages.push(new BotTypingMessage());
                }, 500);

            this.showLoadingIndicator = true;
            this.coreBotService.getRuleBotQuestionType(this.request).subscribe(async botType => {
                this.coreBotConversationId = botType.conversationId;
                this.request.conversationId = botType.conversationId;
                const responseObj = JSON.parse(botType.response);
                const bot = responseObj[0]['Bot'];
                if (bot === 'RuleBot' && !this.request.prompt.includes('?')){
                    setTimeout(() => {
                        this.getRuleBotConsent();
                    }, 750);
                }
                else if (bot === 'Suggest_RuleBot' || (bot === 'RuleBot' && this.request.prompt.includes('?'))){
                    await this.getAuditBotResponse(true);
                }
                else {
                    await this.getAuditBotResponse(false);
                }
            });
        }
    };

    scrollMessagesToBottom(e: any){
        this.chatContent.nebularChat.scrollListBottom();
        this.ruleBotTextArea.nativeElement.focus();
    }

    onModelChange(value: string): void {
        if (value.length > 0){
            this.sendButton.disabled = false;
        }
        else {
            this.sendButton.disabled = true;
        }
        this.onInputChange.emit(value);
    }

    disableRuleBotFields(){
        this.sendButton.disabled = true;
        this.ruleBotTextArea.nativeElement.disabled = true;
        this.newConversationButton.disabled = true;
    }

    enableRuleBotFields(){
        this.ruleBotTextArea.nativeElement.disabled = false;
        this.newConversationButton.disabled = false;
    }

    clearCoreBotConversation(e: any){
        this.coreBotConversationId = null;
        this.messages = [
            new BasicChatMessage('How can I be of assistance?', 'AuditBot', '../../../../assets/images/AuditBotIcon.png')
        ];
    }

    onHiding(e: any){
        this.showPopup = false;
        this.showPopupChanged.emit(this.showPopup);
    }

    getAuditBotResponse(followUp: boolean){
        return new Promise((res) => {
            if (!this.auditedRow){
                this.request.additionalInfo = null;
                this.coreBotService.getAuditBotResponse(this.request, this.periodId, this.seriesId).subscribe(async auditResponse => {
                    await this.handleAuditBotResponse(auditResponse, followUp);
                    res(true);
                });
            } else {
                this.request.additionalInfo = JSON.stringify(this.auditedRow);
                this.coreBotService.getUpstreamAuditBotResponse(this.request, this.periodId, this.seriesId, this.focusedObject.id).subscribe(async auditResponse => {
                    await this.handleAuditBotResponse(auditResponse, followUp);
                    res(true);
                });
            }
        });
    }

    handleAuditBotResponse(auditResponse: CoreBotResponse, followUp: boolean){
        return new Promise(async (res) => {
            this.coreBotConversationId = auditResponse.conversationId;
            const botResponse = await this.buildRuleBotUIObject(auditResponse);
            setTimeout(() => {
                this.messages.pop();
                if (auditResponse.responseCode === coreResponseCodes.Error){
                    this.messages.push(new ChatBotMessage(auditResponse, 'AuditBot', '../../../../assets/images/AuditBotIcon.png'));
                }
                else {
                    this.messages = [...this.messages, new AuditBotMessage(auditResponse, botResponse, this.dataColumns)];
                    this.checkWidgetVisibility();
                }

                if (followUp === true){
                    setTimeout(() => {
                        this.messages.push( new ConfirmButtomMessage('I can make the change you requested above' + 
                            ' directly to this rule. Would you like me to edit this rule?', 'RuleBot'));
                    }, 5000);
                    res(true);
                }
                else {
                    this.enableRuleBotFields();
                    res(true);
                }
            }, 500);
        });
    }

    getSecondStageRuleBotResponse(){
        return new Promise((res) => {
            this.request.additionalInfo = 'edit';
            this.coreBotService.buildRuleBotRule(this.request, RuleBotStages.ModifyRule).subscribe(resp => {
                setTimeout(() => {
                    this.messages.pop();
                    this.coreBotConversationId = resp.conversationId;

                    if (!resp.response.includes('error')){
                        this.buildingBlocksService.getContainerById(this.scopeContainer.id).subscribe(scopeContainer => {
                            this.buildingBlockHelper.setScopeContainer(scopeContainer);
                            this.buildingBlockHelper.replaceObjectInStore(scopeContainer);
                            this.buildingBlockHelper.refreshDataColumns();
                            res(true);
                        });
                        this.messages.push(new ChatBotMessage(resp, 'RuleBot', '../../../../assets/images/ruleBot.png'));
                    }
                    else {
                        this.messages.push(new BasicChatMessage(resp.response, 'RuleBot', '../../../../assets/images/ruleBot.png'));
                        this.checkWidgetVisibility();
                    }
                    this.enableRuleBotFields();
                    res(true);
                }, 550);
            });
        });
    }

    getFirstStageRuleBotResponse(): Promise<boolean>{
        return new Promise((res) => {
            this.coreBotService.buildRuleBotRule(this.request, RuleBotStages.VersionValidation).subscribe(resp => {
                if (resp.responseCode === coreResponseCodes.Error){
                    setTimeout(() => {
                        this.messages.pop();
                        this.messages.pop();
                        this.messages.push(new BasicChatMessage(resp.response, 'RuleBot', '../../../../assets/images/ruleBot.png'));
                        this.enableRuleBotFields();
                        res(false);
                    }, 500);
                }
                else if (JSON.parse(resp.response).hasOwnProperty('currentVersionEndPeriod')){
                    setTimeout(() => {
                        this.messages.pop();
                        this.messages.pop();
                        this.newRuleVersionPeriods = JSON.parse(resp.response);
                        this.messages.push(new ConfirmButtomMessage('In order to make these changes, I need to create a new rule version. ' +
                            'May I proceed with creating a new version of this rule in the most recent period?',
                            'CreateRuleVersion'));
                        res(false);
                    }, 1500)
                }
                else {
                    res(true);
                }
            });
        })
    }

    buildNewRuleVersion(): Promise<boolean>{
        return new Promise((res) => {
            const ruleVersions = this.buildingBlockHelper.getAllRuleVersionsById(parseInt(this.scopeContainer.id.replace('Co', '')));
            const changes = new RuleVersionBatchChanges();
            changes.inserts = [];
            changes.updates = [];
            changes.deletes = [];
            changes.ruleName = this.scopeContainer.name;

            const currentVersionEndPeriod: any = this.newRuleVersionPeriods.currentVersionEndPeriod;
            const newVersionBeginPeriod: any = this.newRuleVersionPeriods.newVersionBeginPeriod;
            const currentVersionId: string = this.newRuleVersionPeriods.currentVersionId;

            const insertedRow = new Group(
                '-1',
                this.scopeContainer.name,
                this.scopeContainer.parentId.replace('Co', ''),
                '',
                this.scopeContainer.typeId,
                '{"isPayment":false,"isSegment":false,"level":1,"insertXaction":false,"columnMappings":[],"savedColumnBindings":[],"headProcessId":""}',
                null,
                'replace this in bb-version-selector',
                false,
                '',
                true,
                newVersionBeginPeriod.Id,
                null
            );
            changes.inserts.push(insertedRow);

            const updatedRow = this.helperService.deepCopyTwoPointO(ruleVersions.find(version => version.id === currentVersionId));
            updatedRow.id = updatedRow.id.replace('Co', '');
            updatedRow.parentId = updatedRow.parentId.replace('Co', '');
            updatedRow.periodEndId = currentVersionEndPeriod.Id;
            changes.updates.push(updatedRow);

            this.buildingBlocksService.batchChangeVersions(changes).subscribe(resp => {
                if (resp.responseCode === coreResponseCodes.Success) {
                    const updated = resp.result.updatedVersions[0];
                    const updatedVersion = new Group(updated.id, updated.name, updated.parentId, updated.description, updated.typeId, updated.objectJson,
                        updated.recurrenceId, updated.lastModified, updated.locked, updated.headProcessId, updated.isActive, updated.periodBeginId, updated.periodEndId);
                    this.buildingBlockHelper.replaceObjectInStore(updatedVersion);

                    const inserted = resp.result.insertedVersions[0];
                    this.buildingBlockHelper.addObjectsToStore([inserted], true);
                    this.request.containerId = parseInt(inserted.id.replace('Co', ''));
                    this.buildingBlockHelper.setScopeContainer(inserted);
                    this.buildingBlockHelper.refreshDataColumns();
                    res(true);
                }
                else {
                    res(false);
                }
            });
        });
    }

    async getFollowUpResponse(e: any){
        this.request.conversationId = this.coreBotConversationId;
        
        if (e === 'CreateRuleVersion'){
            this.messages.pop();

            setTimeout(async () => {
                this.messages.push(new BotTypingMessage());
                const isNewVersionBuilt = await this.buildNewRuleVersion();

                if (isNewVersionBuilt === true){
                    this.getSecondStageRuleBotResponse();
                }
                else {
                    this.messages.pop();
                    this.messages.push(new BasicChatMessage('An error occurred when I tried creating a new version. See the logs for details', 'RuleBot', '../../../../assets/images/ruleBot.png'));
                    this.enableRuleBotFields();
                }
            }, 500);
        }
        else {
            setTimeout(() => {
                this.messages.push(new BotTypingMessage());
            }, 400);
            const isVersionValidated = await this.getFirstStageRuleBotResponse();

            if (isVersionValidated === true){
                this.messages.pop();
                this.getSecondStageRuleBotResponse();
            }
        }
    }

    resetChat(e: any){
        this.messages.pop();
        this.enableRuleBotFields();
    }

    buildRuleBotUIObject(response: CoreBotResponse): Promise<ChatBotUIObject[]> {
        return new Promise(async (res) => {
            let content: string = response.response;
            content = content.replace(/```/g, '\`').replace(/``/g, '\`').replace(/json/g, '').replace(/`sql/g, '`');
            const components = content.split('`');
            const newResponse: ChatBotUIObject[] = [];
            let isCode: boolean = false;
            for (const x of components){
                if (isCode){
                    if (x.includes('filterBuilderObject')){
                        try {
                            let filterBuilderObject;
                            if (x.indexOf('{') === -1){
                                filterBuilderObject = JSON.parse('{' + x + '}');
                            }
                            else {
                                filterBuilderObject = JSON.parse(x);
                            }
                            const filter = JSON.parse(await this.remapFilterObject(JSON.stringify(filterBuilderObject['filterBuilderObject']), false));
                            newResponse.push({id: response.logId, type: ChatBotUIType.Filter, content:filter});
                        }
                        catch (error) {
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:x});
                        }
                    }
                    else if (x.includes('joinBuilderObject')){
                        try {
                            let filterBuilderObject;
                            if (x.indexOf('{') === -1){
                                filterBuilderObject = JSON.parse('{' + x + '}');
                            }
                            else {
                                filterBuilderObject = JSON.parse(x);
                            }
                            const join = JSON.parse(await this.remapFilterObject(JSON.stringify(filterBuilderObject['joinBuilderObject']), true));
                            newResponse.push({id: response.logId, type: ChatBotUIType.Join, content:join});
                        }
                        catch (error) {
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:x});
                        }
                    }
                    else if (x.includes('aggregateFields')){
                        try {
                            let aggregateFields;
                            if (x.indexOf('{') === -1){
                                aggregateFields = JSON.parse('{' + x + '}');
                            }
                            else {
                                aggregateFields = JSON.parse(x);
                            }
                            aggregateFields['aggregateFields'] = this.mapGroupFriendlyNamesToSystem(aggregateFields['aggregateFields']);
                            newResponse.push({id: response.logId, type: ChatBotUIType.Group, content:aggregateFields['aggregateFields']});
                        }
                        catch (error) {
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:x});
                        }
                    }
                    else if (x.includes('fieldMaps')){
                        try {
                            let unionFields;
                            if (x.indexOf('{') === -1){
                                unionFields = JSON.parse('{' + x + '}');
                            }
                            else {
                                unionFields = JSON.parse(x);
                            }
                            unionFields['fieldMaps'] = this.mapUnionFriendlyNamesToSystem(unionFields['fieldMaps']);
                            newResponse.push({id: response.logId, type: ChatBotUIType.Union, content:unionFields['fieldMaps']});
                        }
                        catch (error) {
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:x});
                        }
                    }
                    else {
                        if (x.indexOf('{') !== -1 && x.includes('sqlFormulaString')){
                            const formula = JSON.parse(x);
                            let formulaString = JSON.stringify(formula['sqlFormulaString']);
                            formulaString = formulaString.replace(/^"|"$/g, '');
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:formulaString});
                        }
                        else {
                            newResponse.push({id: response.logId, type: ChatBotUIType.Formula, content:x});
                        }
                    }
                }
                else {
                    newResponse.push({id: response.logId, type: ChatBotUIType.PlainText, content:x});
                }
                isCode = !isCode;
            }
            res(newResponse);
        });
    }

    async remapFilterObject(filter: string, isJoin: boolean): Promise<string>{
        const filterArray = JSON.parse(filter);
        for (let x = 0; x < filterArray.length; x++){
            const innerFilter = filterArray[x];
            let isCleaned = false;
            for (let y = 0; y < innerFilter.length; y++){
                if (y === 0) {
                    this.dataColumns.dataSource.forEach(element => {
                        if(innerFilter[y].toLowerCase().includes(element.friendlyName.toLowerCase()) && !isCleaned){
                            let prefix = '';
                            switch (true){
                                case element.systemName.includes('date'):
                                    prefix = 'Date.Transaction.';
                                    break;
                                case element.systemName.includes('text'):
                                    prefix = 'Text.Transaction.';
                                    break;
                                case element.systemName.includes('tag'):
                                    prefix = 'Tag.';
                                    break;
                                case element.systemName.includes('qty') || element.systemName.includes('period'):
                                    prefix = 'Quantity.Transaction.';
                                    break;
                                case element.systemName.includes('var'):
                                    prefix = 'AccountFactors.';
                                    break;
                                case element.systemName.includes('Bb'):
                                    prefix = 'Quantity.Gear.';
                                    break;
                                case element.systemName.includes('seller_id_field_no'):
                                    prefix = 'RoleType.';
                                    break;
                                case element.systemName.includes('seller_id'):
                                    prefix = 'RoleType.';
                                    break;
                                case element.systemName.includes('job_title') || element.systemName.includes('seller_group') ||
                                    element.systemName.includes('seller_type') || element.systemName.includes('region') ||
                                    element.systemName.includes('attrib_'):
                                    prefix = 'Attribute.';
                                    break;
                            }

                            let joinPrefix = '';
                            if (isJoin){
                                joinPrefix = innerFilter[y].split('.')[0] + '.';
                            }

                            innerFilter[y] = joinPrefix + prefix + element.systemName;

                            isCleaned = true;
                        }
                    });
                }
            }
            filterArray[x] = innerFilter;
        }

        if (JSON.parse(filter).length === 1 && filter.indexOf('[[') === 0){
            filter = filter.slice(1, -1);
        }

        filter = await this.additionalFilterMappings(filter, isJoin);

        filter = JSON.stringify(filterArray);

        return filter;
    }

    additionalFilterMappings(filter: string, isJoin: boolean): Promise<string>{
        return new Promise<string>((res) => {
            forkJoin([
                this.fieldService.getTags(),
                this.sellerService.getAllSellers(),
                this.fieldService.getSingletons()
            ]).subscribe(([tags, sellers, roles]: any[]) => {
                this.parseFilterIds(JSON.parse(filter), tags, sellers, roles, isJoin).then(filterObj =>{
                    res(JSON.stringify(filterObj));
                });
            });
        });
    }

    async parseFilterIds(filterObj: any, tags: any, sellers: any, roles: any, isJoin: boolean): Promise<any>{
        return new Promise(async (res) => {
            for(let x = 0; x < filterObj.length; x++) {
                if (typeof(filterObj[x]) === typeof(Object())){
                    this.parseFilterIds(filterObj[x], tags, sellers, roles, isJoin);
                }
                else if (typeof(filterObj[x]) !== typeof(Number())){
                    if (filterObj[x] === '!==' || filterObj[x] === '!='){
                        filterObj[x] = '<>';
                    }

                    if (isJoin === true && x === 0){
                        if (!filterObj[x + 1].includes('null')){
                            filterObj[x + 2] = filterObj[x + 2].split('.')[filterObj[x + 2].split('.').length - 1];
                        }
                    }

                    if (filterObj[x].includes('tag')){
                        const isArray = filterObj[x + 1].toString().includes('in') || filterObj[x + 1].toString().includes('not in') ? true : false;

                        if (!isJoin && !filterObj[x + 1].toString().includes('null') && !filterObj[x + 1].toString().includes('like') && filterObj[x + 1].toString() !== ' = '){
                            tags.filter(tag => filterObj[x + 2].toString().includes(tag.name)).forEach(match => {
                                filterObj[x + 2] = filterObj[x + 2].replace(match.name, match.id).replace(/\'/g, '');
                            });

                            if (isArray){
                                for (let item of filterObj[x + 2]){
                                    item = parseInt(item, 10);
                                }
                            }
                            else {
                                filterObj[x + 2] = parseInt(filterObj[x + 2], 10);
                            }
                        }
                    }
                    else if (filterObj[x].includes('seller') && !filterObj[x].includes('name')){

                        const isArray = filterObj[x + 1].toString().includes('in') || filterObj[x + 1].toString().includes('not in') ? true : false;

                        if (!isJoin && !filterObj[x + 1].toString().includes('null') && !filterObj[x + 1].toString().includes('like') && filterObj[x + 1].toString() !== ' = '){
                            sellers.filter(seller => filterObj[x + 2].toString().includes(seller.name)).forEach(match => {
                                filterObj[x + 2] = filterObj[x + 2].replace(match.name, match.id).replace(/\'/g, '');
                            });

                            if (isArray){
                                for (let item of filterObj[x + 2]){
                                    item = parseInt(item, 10);
                                }
                            }
                            else {
                                filterObj[x + 2] = parseInt(filterObj[x + 2], 10);
                            }
                        }
                    }
                    else if (filterObj[x].includes('role')){
                        if (isJoin){
                            filterObj[x] = 'MainSource.RoleType.seller_id_field_no_name';
                        }
                        else {
                            filterObj[x] = 'RoleType.seller_id_field_no_name';
                        }
                        const isArray = filterObj[x + 1].toString().includes('in') || filterObj[x + 1].toString().includes('not in') ? true : false;

                        if (!filterObj[x + 1].toString().includes('null') && !filterObj[x + 1].toString().includes('like') && filterObj[x + 1].toString() !== ' = '){
                            roles.filter(role => filterObj[x + 2].toString().includes(role.refName)).forEach(match => {
                                if (!isJoin){
                                    filterObj[x + 2] = filterObj[x + 2].replace('RoleType.' + match.refName + '_name', match.fieldNo).replace(/\'/g, '');
                                }
                                else {
                                    filterObj[x + 2] = filterObj[x + 2].replace(/\'/g, '');
                                }
                            });

                            if (!isJoin){
                                if (isArray){
                                    for (let item of filterObj[x + 2]){
                                        item = parseInt(item, 10);
                                    }
                                }
                                else {
                                    filterObj[x + 2] = parseInt(filterObj[x + 2], 10);
                                }
                            }
                        }
                    }
                }
            }
            res(filterObj);
        });
    }

    mapGroupFriendlyNamesToSystem(input: any){
        for (const x of input){
            let isCleaned = false;
            this.dataColumns.dataSource.forEach(element => {
                const unders = RegExp('_', 'g');
                const spaces = RegExp(' ', 'g');
                if(x.systemName.replace(unders, ' ').toLowerCase() === element.friendlyName.toLowerCase() && !isCleaned){
                    const field = new RegExp(`${element.friendlyName.toLowerCase()}`, 'g');
                    const fieldUnderscores = new RegExp(`${element.friendlyName.toLowerCase().replace(spaces, '_')}`, 'g');
                    x.systemName = x.systemName.toLowerCase().replace(field, element.systemName).replace(fieldUnderscores, element.systemName);
                    isCleaned = true;
                }
            });
        }
        return input;
    }

    mapUnionFriendlyNamesToSystem(input: any){
        for (const x of input){
            let isCleaned = false;
            this.dataColumns.dataSource.forEach(element => {
                const unders = RegExp('_', 'g');
                const spaces = RegExp(' ', 'g');
                if (!isCleaned){
                    if(x.sourceName.replace(unders, ' ').toLowerCase() === element.friendlyName.toLowerCase() && !isCleaned){
                        const field = new RegExp(`${element.friendlyName.toLowerCase()}`, 'g');
                        const fieldUnderscores = new RegExp(`${element.friendlyName.toLowerCase().replace(spaces, '_')}`, 'g');
                        x.sourceName = x.sourceName.toLowerCase().replace(field, element.sourceName).replace(fieldUnderscores, element.sourceName);
                        isCleaned = true;
                    }

                    if(x.auxName.replace(unders, ' ').toLowerCase() === element.friendlyName.toLowerCase() && !isCleaned){
                        const field = new RegExp(`${element.friendlyName.toLowerCase()}`, 'g');
                        const fieldUnderscores = new RegExp(`${element.friendlyName.toLowerCase().replace(spaces, '_')}`, 'g');
                        x.auxName = x.auxName.toLowerCase().replace(field, element.auxName).replace(fieldUnderscores, element.auxName);
                        isCleaned = true;
                    }
                }

            });
        }
        return input;
    }

    submitReportAudit(){
        this.request = {
            prompt: this.message,
            conversationId: this.coreBotConversationId,
            additionalInfo: JSON.stringify(this.reportInfo),
            environment: environment.production ? 'production' : 'test',
        };

        this.messages.push(new UserMessage(this.sellerName, this.message));
        this.message = '';
        this.samplePrompt = 'Enter your prompt...';
        this.disableRuleBotFields();

        setTimeout(() => {
            this.messages.push(new BotTypingMessage());
        }, 500);

        this.coreBotService.getReportAuditResponse(this.request).subscribe(response => {
            this.coreBotConversationId = response.conversationId;
            setTimeout(async () => {
                this.messages.pop();
                if (response.responseCode === coreResponseCodes.Error){
                    this.messages.push(new BasicChatMessage(response.response, 'AuditBot', '../../../../assets/images/AuditBotIcon.png'));
                    this.enableRuleBotFields();
                }
                else {
                    const auditReportResponse = await this.buildRuleBotUIObject(response);
                    this.messages = [...this.messages, new AuditBotMessage(response, auditReportResponse, this.dataColumns)];

                    if (this.request.additionalInfo
                        && JSON.parse(this.request.additionalInfo).Value
                        && JSON.parse(JSON.parse(this.request.additionalInfo).Value).reportInfo){
                        setTimeout(() => {
                            let rulesParsed;
                            const regex = /rule_([0-9]+)/;
                            const cellData = JSON.parse(JSON.parse(this.request.additionalInfo).Value);
                            const ruleId = cellData.binding.match(regex);
                            let message: string;
                            if (cellData.isBoundToRule){
                                rulesParsed = cellData.reportInfo.reportRules.filter(x => x.rule.Id.replace('Co', '') === ruleId[1]);
                                message = 'Would you like to audit the rule that generated this value?';
                            }
                            else {
                                rulesParsed = cellData.reportInfo.reportRules;
                                message = 'Would you like to audit the rules that power this report?';
                            }
                            this.reportRules = rulesParsed;
                            if (this.permissionService.checkCurrentUserPermission(CoreFeature.ViewProcessedRecords.toString()) && this.request.additionalInfo){
                                this.messages.push(new AuditBotReportRedirect(message, rulesParsed));
                            }
                            else {
                                this.messages.pop();
                                setTimeout(() => {
                                    this.messages.push(new BasicChatMessage(
                                        'It looks like you don\'t have access to view processed output. Contact your administrator if you believe this to be an error.',
                                        'AuditBot',
                                        '../../../../assets/images/AuditBotIcon.png')
                                    );
                                }, 500);
                            }
                        }, 5000);
                    }
                    else {
                        this.enableRuleBotFields();
                    }
                }
            }, 750);
        });
    }

    redirectUser(e: any){
        if (e !== 'output'){
            console.log(e);
            window.location.href = window.location.origin + '/building-blocks-audit/Co' + e;
        }
        else {
            const ruleNames = this.reportRules.map(x => 'Co' + x.rule.Id);
            this.openReportProcessedOutput(ruleNames);
            this.resetChat(e);
        }
    }

    openReportProcessedOutput(ruleIds: string[]): void {
        const props: TreeListProps = new TreeListProps('processedRecords', 'id', 'parentId', null, true);
        props.loadingVisible = true;

        this.periodService.getBoundPeriods().pipe(
            map(periods => periods.filter(period => Date.parse(period.beginDate.toString()) >= Date.parse(localStorage.getItem('beginDate'))
                    && Date.parse(period.endDate.toString()) <= Date.parse(localStorage.getItem('endDate')))
                    .map(period => period.id)
                ),
        )
        .subscribe(periodIds => {
            const seriesIdStr = sessionStorage.getItem('selectedSeries');
            const seriesId = seriesIdStr ? this.helperService.parseBracketedIdString(seriesIdStr)[0] : null;
            const recurrenceId = Number.parseInt(sessionStorage.getItem('selectedSeries'), 10);
            const sellers = localStorage.getItem(`${this.username}.selectedSellers`);

            const sellerArray = sellers.split(']').map(x => x.replace('[', ''));
            sellerArray.pop();

            const popupArgs = new PopupArguments().createForProcessed(periodIds, seriesId, [''], ruleIds, recurrenceId, sellerArray);
            const requiredProperties = [new RequiredProperty('seriesId', seriesId), new RequiredProperty('periodId', periodIds)];

            popupArgs.title = 'Report Records';
            popupArgs.shortTitle = 'Report Records';

            const displayDataArguments = new DisplayDataArguments(requiredProperties, processingDataViewerFunctions.Processed, popupArgs);
            this.openViewEditPopup(displayDataArguments, props);
        });
    }

    openViewEditPopup(ddArgs: DisplayDataArguments, component: TreeListProps | GridProps) {
        try {
            this.helperService.openDisplayDataPopup(ddArgs, '/record-data-viewer');
        } catch (error) {
            alert('error');
        }

        component.loadingVisible = false;
    }

    showTicketWidget(){
        const body = document.getElementsByTagName('body')[0];
        const script1 = document.createElement('script');
        this.populateFreshdeskFields();
        script1.async = true;
        script1.innerHTML = 'FreshworksWidget(\'open\');';
        body.appendChild(script1);
    }

    populateFreshdeskFields() {
        this.sellerService.getMe().subscribe(seller => {
            const body = document.getElementsByTagName('body')[0];
            const script1 = document.createElement('script');
            script1.async = true;
            script1.innerHTML = 'FreshworksWidget(\'identify\', \'ticketForm\', {name: \'' + seller.name + '\',email: \'' + seller.emailAddress + '\',' +
             'subject: \'Core Commissions Issue (' + window.location.href + ') \',' +
             'description: \'Provide as much detail as possible when describing your issue. The more detail provided, the quicker we can address the problem.\'});';
            body.appendChild(script1);
            body.removeChild(script1);
        });
    }

    checkWidgetVisibility(){
        if (this.messages.length >= 6){
            this.isSupportVisible = true;
        }
    }

    getSamplePrompt(): string{
        const rand: number = Math.floor(Math.random() * 7);
        const prompts = [
            'Can you tell me how this rule works?',
            'How do I use the diagram audit feature?',
            'Where do I go to add a new gear to this rule?',
            'What\'s a gearbox and what is it used for?',
            'How would I add a formula gear to this rule that simply outputs the value 1000?',
            'How many gears can I include in my rule?',
            'What do I do if my rule isn\'t auditing?'
        ];
        return prompts[rand];
    }

    getRuleBotConsent(){
        this.messages.pop();
        setTimeout(() => {
            this.messages.push(new ConfirmButtomMessage('It looks like you want me to make a change to your rule. May I proceed with making the requested changes?', 'RuleBot'));
        }, 500);
    }
}
