import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, NgModule, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResourceManager, AjaxRemoteServiceOptions, DashboardExportExtension } from 'devexpress-dashboard';
import { ToolboxExtension, DashboardMenuItem } from 'devexpress-dashboard/designer';
import { DxDashboardControlModule, DxDashboardControlComponent } from 'devexpress-dashboard-angular';
import { ChartLineOptionsExtension } from './extensions/chart-line-options-extension';
import { DashboardService } from '../../services/dashboard.service';
import { SellerService } from '../../services/seller.service';
import { PeriodService } from '../../services/period.service';
import { RecurrenceService } from '../../services/recurrence.service';
import { SeriesService } from '../../services/series.service';
import { AccountAttributeClassService } from '../../services/account-attributeClass.service';
import { AppElementsService } from '../../services/app-element.service';
import { DashboardContext } from '../../models/contexts/dashboard-context';
import {
    DxLoadPanelModule, DxCheckBoxModule, DxButtonModule, DxNumberBoxModule,
    DxSelectBoxModule, DxDropDownBoxModule, DxTreeListModule, DxPopupModule,
    DxResponsiveBoxModule, DxTextBoxModule, DxDataGridModule,
    DxValidatorModule
} from 'devextreme-angular';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { AuthService } from '../../services/auth.service';
import { Dashboard } from '../../models/dashboard';
import { Series } from '../../models/series';
import { Recurrence } from '../../models/recurrence';
import { Period } from '../../models/period';
import { Seller } from '../../models/seller';
import { AttributeClass } from '../../models/attribute-class';
import { HelperService } from '../../services/helper.service';
import { PermissionService } from '../../services/permission.service';
import { Bucket } from '../../models/bucket';
import { EnumCoreBotInteraction, CoreFeature, coreResponseCodes, EnumBucketClass, EnumBucketType, settingClassIds, CoreBotInteractionTriggerType } from '../../constants/enums';
import { Svgs } from 'src/assets/svg-constants';
import { SettingService } from '../../services/setting.service';
import { HistSellerService } from '../../services/hist-seller.service';
import { CoreComponentModule } from '../core-component.module';
import { BucketService } from '../../services/bucket.service';
import { TreeViewItem } from 'devexpress-dashboard/model';
import { TextBoxItemEditorExtension } from 'devexpress-dashboard/designer/text-box-item-editor-extension';
import { CoreBotInteractionService } from '../../services/core-bot-interaction-service';
import { ReportingParametersComponent } from '../reporting-parameters/reporting-parameters.component';
import { XactionService } from '../../services/xaction.service';
import { SiteThemeService } from '../../services/site-theme.service';
import { DistributionList } from '../../models/distribution-list';
import { DistributionListService } from '../../services/distribution-list.service';

@Component({
    selector: 'app-dashboard-viewer',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './dashboard-viewer.component.html',
    styleUrls: ['./dashboard-viewer.component.scss'],
    providers: [DashboardService, SellerService, AuthService]
})
export class DashboardViewerComponent implements OnInit, OnDestroy {
    @Input() showLoadingPanel: boolean; // This determines if a loading panel should ever be shown, loadingVisible determines if the dashboard is currently loading.
    @Input() allowEditing: boolean;
    @Input() hasAdminView: boolean;
    @ViewChild('dashboardControl', { static: true }) dashboardControl: DxDashboardControlComponent;
    @ViewChild('previewDashboardControl', { static: true }) previewDashboardControl: DxDashboardControlComponent;
    @ViewChild('selector', {static: true}) dateAccountSelector: ReportingParametersComponent;
    @Output() switchToViewerMode = new EventEmitter();
    @Output() onExitDesignerClick = new EventEmitter();
    @Output() onListChange = new EventEmitter();

    username: string;
    isErrorPopupVisable: boolean = false;
    previewLoadingVisible: boolean = false;
    loadingVisible: boolean = false;
    previewLoading: boolean = false;
    pageDestroyed: boolean = false;
    previewPopupVisible = false;
    deletePopupVisible = false;
    endpoint: string;
    mode: string;
    indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';
    ajaxRemoteServiceOptions: AjaxRemoteServiceOptions;
    // this stores the Id of the Core-defined Dashboard class
    dashboardId: number;
    // this stores the Id of the DevExpress dashboard configuration
    dashboardConfigId: number;
    dashboardName: string;
    sellerId: number;
    isDeleteButtonHidden: boolean;
    canCreateDashboard: boolean;
    isPublic: boolean;
    includeZeroes: boolean;
    useClientDataProcessing: boolean;
    attributes: string;
    defaultPriority: number;
    recurrences: Recurrence[];
    dummyRecurrence: Recurrence = new Recurrence(null, null, null, null, null, null).createDummy();
    recurrenceId: number;
    globalDistributionLists: DistributionList[];
    defaultUiViewId: number;
    series: Series[];
    showInactiveSegments = false;
    changesMade: boolean;
    previewSeriesIds: number[];
    previewBeginDate: Date;
    previewEndDate: Date;
    previewSelectedSellerIds: number[];
    previewViewableAccounts: string;
    periods: Period[];
    filteredPeriods: Period[];
    periodsBeginSorted: Period[];
    periodsEndSorted: Period[];
    accountFilterVisible = false;
    sellers: Seller[];
    seller: Seller;
    attributeClasses: AttributeClass[];
    dashboards: {name: string, id: number}[] = [];
    isImplementer: boolean = false;
    dashboardArraySubscription: Subscription = null;
    dashboardIdSubscription: Subscription = null;
    isExportsLimitedPromise: Promise<boolean>;
    isExportBlocked = false;
    isReportParamsVisible = false;
    allowsDashboardPermissionsEdit: boolean;
    isHomeDefaultEnabledPromise: Promise<boolean>;
    isHomeDefaultEnabled: boolean;
    isHomeDefault: boolean;
    defaultBucket: Bucket = new Bucket({bucketClassId: EnumBucketClass.DefaultHome, type: EnumBucketType.Dashboard});
    guid: string;
    readonly VIEWABLE_ACCOUNTS_OPTIONS = [
        'Include Account Only',
        'Include All Subordinates',
        'Include Direct Subordinates Only'
     ];
    readonly MODE_DESIGNER = 'Designer';
    readonly MODE_VIEWER = 'Viewer';
    readonly EXTENSION_OPTIONS = {
        viewerApi: {
            onDashboardTitleToolbarUpdated: (e) => {
                if (this.isHomeDefaultEnabled && this.dashboardId && this.router.url.includes('dashboard')) {
                    e.options.actionItems.push({
                        type: 'button',
                        icon: 'home',
                        hint: 'Show on Home Page',
                        click: () => {
                            this.onHomeDefaultClicked();
                        },
                    });
                    setTimeout(() => this.updateHomeButtonStatus());
                }
                if (this.allowEditing && this.mode === this.MODE_VIEWER && this.dashboardId) {
                    e.options.actionItems.push({
                        type: 'button',
                        id: 'edit-dashboard-button',
                        hint: 'Edit Dashboard',
                        icon: 'edit',
                        click: () => {
                            this.switchToDesigner();
                        }
                    });
                }
                if (this.dashboardId || this.mode === this.MODE_DESIGNER) {
                    e.options.actionItems.push({
                        type: 'button',
                        icon: 'maximize',
                        hint: 'Fullscreen',
                        click: () => {
                            if (document.fullscreenElement) {
                                document.exitFullscreen();
                            } else {
                                document.getElementsByClassName('dx-dashboard-widget-container')[0].requestFullscreen();
                            }
                        },
                    });
                }
                if (this.mode === this.MODE_VIEWER && this.hasAdminView) {
                    // Date/Acct Selector must be the first item to be formatted correctly
                    const dateRangeLabelStr = this.helperService.getSessionDateRangeLabel();
                    e.options.actionItems.unshift({
                        type: 'button',
                        icon: 'group',
                        click: () => this.isReportParamsVisible = true,
                        template:
                            `<div id="report-params-button">
                                <i class="dx-icon-group"></i>
                                <span>${dateRangeLabelStr}</span>
                            </div>`
                    });
                }
                if (e.options.actionItems.find(x => x.name === 'export-menu')) {
                    // Using same icon for export that is used in report viewer for UI consistency
                    const exportMenuItem = e.options.actionItems.find(x => x.name === 'export-menu');
                    const downloadIconElement = document.querySelector('#web-report-download-icon');
                    if (downloadIconElement) {
                        exportMenuItem.template = downloadIconElement.innerHTML;
                    }
                }
                this.dashboardName = e.dashboard.title.text();
            },
            onItemWidgetCreated: (e) => {
                e.getWidget().option?.('headerFilter.visible', 'true');
            }
        }
    };

    dashboardRequests: JQueryXHR[] = [];

    constructor(private toast: ToastrService,
        private dashboardService: DashboardService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private authService: AuthService,
        private recurrenceService: RecurrenceService,
        private distributionListService: DistributionListService,
        private seriesService: SeriesService,
        private appElementsService: AppElementsService,
        private helperService: HelperService,
        private attributeClassService: AccountAttributeClassService,
        private permissionService: PermissionService,
        private settingsService: SettingService,
        private histSellerService: HistSellerService,
        private bucketService: BucketService,
        private siteThemeService: SiteThemeService,
        private coreBotInteractionService: CoreBotInteractionService,
        private xactionService: XactionService,
        private router: Router) {
        ResourceManager.registerIcon(Svgs.Edit);
        ResourceManager.registerIcon(Svgs.Maximize);
        ResourceManager.registerIcon(Svgs.Home);
        ResourceManager.embedBundledResources();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
        if(this.mode === this.MODE_VIEWER && this.guid && this.guid !== ''){
            this.cancelAllDashboardRequests();
        }
    }

    cancelAllDashboardRequests() {
        this.dashboardRequests.forEach(req => {
            // We want to abort requests that are past the UNSENT (0) and OPENED (1) states
            if (req.readyState !== 0 && req.readyState !== 1) {
                req.abort();
            }
        });
        this.dashboardService.cancelDashboard(this.guid).subscribe();
    }

    async ngOnInit() {
        this.setDashboardMode();

        this.username = this.authService.getUserFromToken();
        this.isDeleteButtonHidden = !this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteDashboards.toString());
        this.canCreateDashboard = this.permissionService.checkCurrentUserPermission(CoreFeature.CreateDashboards.toString());
        this.mode = this.MODE_VIEWER;
        this.endpoint = `${environment.apiEndpoint}/dashboard`;
        this.ajaxRemoteServiceOptions = {
            beforeSend: (jqxhr, settings) => {
                this.dashboardRequests.push(jqxhr);
                jqxhr.setRequestHeader('Authorization', 'Bearer ' + this.authService.getToken());
            },
            headers: { Authorization: 'Bearer ' + this.authService.getToken() }
        };

        this.permissionService.getIsImplementer().subscribe(isImplementer => {
            this.isImplementer = isImplementer;
        });

        if (this.hasAdminView === undefined) {
            this.sellerService.getHasAdminView().subscribe(result => this.hasAdminView = result);
        }

        this.previewViewableAccounts = this.VIEWABLE_ACCOUNTS_OPTIONS[0];

        this.isExportsLimitedPromise = this.settingsService.getBoolSetting(settingClassIds.InternalLimitReportDashboardExportsByAttribute).toPromise();
        this.isHomeDefaultEnabledPromise =  this.bucketService.useDefaultBucket().toPromise();

        const darkModeChanges: Subscription = this.appElementsService.getDarkModeChanges().subscribe(x => {
            if (!this.pageDestroyed) {
                this.setDashboardMode();
            } else {
                darkModeChanges.unsubscribe();
            }
        });
    }

    createDashboard(name?: string): void {
        this.loadingVisible = true;
        this.dashboardId = 0;

        this.designDashboard(true, name);
    }

    designDashboard(newDashboard: boolean, newName?: string): void {
        forkJoin([this.recurrenceService.GetAllPublishedRecurrences(),
            this.distributionListService.getGlobalDistributionLists(),
            this.seriesService.getAllSeries(),
            this.getDashboard(this.dashboardId)
        ]).subscribe(([recurrences, globalDistributionLists, series, dashboard]) => {
            this.recurrences = recurrences;
            this.recurrences.unshift(this.dummyRecurrence);
            this.globalDistributionLists = globalDistributionLists;
            this.globalDistributionLists.unshift(new DistributionList(0, '<None>'));

            this.series = series;

            this.isPublic = dashboard.isPublic;
            this.includeZeroes = dashboard.includeZeroes;
            this.defaultPriority = dashboard.priority;
            this.recurrenceId = dashboard.recurrenceId === undefined ? 0 : dashboard.recurrenceId;
            this.defaultUiViewId = dashboard.defaultUiViewId === undefined || dashboard.defaultUiViewId === null ? 0 : dashboard.defaultUiViewId;
            this.attributes = dashboard.attributes;
            this.useClientDataProcessing = dashboard.useClientDataProcessing;

            this.generateDashboard(this.dashboardId, null, null, null, true, newDashboard, dashboard.xml, newName);
        });
    }

    async generateDashboard(dashboardId: number, beginDate?: string, endDate?: string, sellers?: string, designMode?: boolean, newDashboard?: boolean, xml?: string, newName?: string): Promise<void> {
        if (this.hasAdminView && (dashboardId === null || dashboardId === undefined)) {
            this.dashboardControl.instance.initializeDashboard('', '');
            return;
        }

        this.loadingVisible = true;
        this.dashboardId = dashboardId;
        const localDashboardId: number = dashboardId;

        if (await this.isHomeDefaultEnabledPromise) {
            this.isHomeDefaultEnabled = true;
            this.isHomeDefault = await this.bucketService.getDefaultBucket(this.dashboardId, EnumBucketType.Dashboard).toPromise().then(bucket => !!bucket);
        }

        if (await this.isExportsLimitedPromise) {
            forkJoin({
                dashboard: this.dashboardService.getDashboard(this.dashboardId),
                mySeller: this.sellerService.getSeller(this.authService.getUserFromToken())
            }).subscribe(results => {
                // TODO: Should move this attribute string check into attributeservices.
                const currentAttributes = this.histSellerService.getCurrentHistSeller(results.mySeller.histSellers).attributes;
                let didAttributesMatch: boolean = true;
                if (results.dashboard.exportAttributes !== null) {
                    for (const attribute of currentAttributes) {
                        if (!attribute.attributeClass.isText && !results.dashboard.exportAttributes.includes('[' + attribute.id + ']')) {
                            didAttributesMatch = false;
                            break;
                        }
                    }
                }

                const exportExtension = this.dashboardControl.instance.findExtension('dashboardExport') as DashboardExportExtension;
                exportExtension.allowExportDashboard = didAttributesMatch;
                exportExtension.allowExportDashboardItems = didAttributesMatch;
            });
        }

        if (this.dashboardArraySubscription !== null) {
            this.dashboardArraySubscription.unsubscribe();
        }
        if (this.dashboardIdSubscription !== null) {
            this.dashboardIdSubscription.unsubscribe();
        }

        if (designMode === true || newDashboard === true) {
            this.mode = this.MODE_DESIGNER;
        } else {
            this.mode = this.MODE_VIEWER;
        }

        if (!this.username) {
            return;
        }

        const isApplyDefaults = localStorage.getItem(`${this.username}.applyDefaultLayouts`) !== false.toString();
        forkJoin([
            this.sellerService.getSellerWithSubordinateSetting(this.username),
            this.distributionListService.getDefaultDistributionList(isApplyDefaults, EnumBucketType.Dashboard, this.dashboardId),
            this.periodService.getPeriods(),
        ]).subscribe(([seller, defaultDistributionList, allPeriods]) => {
            this.sellerId = seller.id;
            this.seller = seller;
            this.periods = allPeriods;
            this.setInitialValues(this.sellerId);

            this.sellerService.getSubordinatesWithCurrentHistory(seller.id, true).subscribe(currentSubs => {
                for (const subordinate of currentSubs) {
                    if (subordinate.id !== seller.id) {
                        subordinate.parentId = subordinate.histSellers[0].bossSellerId;
                    }
                }
                this.setAttributeValues(currentSubs);

                this.sellers = currentSubs;

                this.attributeClassService.getAttributeClasses().subscribe(attributeClasses => {
                    this.attributeClasses = attributeClasses.filter(c => !c.isExcluded);
                });
            });

            if (this.mode === this.MODE_DESIGNER) {
                this.dashboardService.getDashboardMapping().subscribe(dashboards => {
                    this.dashboards = dashboards;
                });
            }

            this.periodService.setStorageDates(this.sellerId, this.seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {
                beginDate ??= localStorage.getItem('beginDate');
                endDate ??= localStorage.getItem('endDate');
                const context = new DashboardContext();
                context.id = dashboardId;
                context.beginDate = beginDate ? new Date(beginDate + 'Z') : this.periods[this.periods.length - 1]?.beginDate ?? allPeriods[allPeriods.length - 1]?.beginDate;
                context.endDate = endDate ? new Date(endDate + 'Z') : this.periods[this.periods.length - 1]?.endDate ?? allPeriods[allPeriods.length - 1]?.endDate;

                const storedSellers = sellers ? sellers : localStorage.getItem(`${this.username}.selectedSellers`);
                context.selectedSellers = storedSellers;
                if (isApplyDefaults && defaultDistributionList) {
                    const layout = JSON.parse(defaultDistributionList.layoutString);
                    context.selectedSellers = this.helperService.createBracketedIdString(layout.selectedRowKeys);
                    this.appElementsService.provideDistributionListChanged(defaultDistributionList.id);
                }
                context.token = this.authService.getUserFromToken();
                context.showInactiveSegments = this.showInactiveSegments;
                context.isDesignMode = this.mode === this.MODE_DESIGNER;
                context.xml = xml;
                context.seriesIdList = sessionStorage.getItem('selectedSeries');

                if (newDashboard) {
                    context.name = newName ?? '';
                }

                this.dashboardArraySubscription = this.dashboardService.getDashboardArray(context).subscribe(result => {
                    this.guid = result.guid;
                    this.dashboardIdSubscription = this.dashboardService.getDashboardId(result.bytes).subscribe(dashboardConfigId => {
                        // Doesn't load the dashboard if the request is out of date.
                        if (this.dashboardId === localDashboardId) {
                            this.dashboardConfigId = dashboardConfigId;
                            this.dashboardControl.instance.loadDashboard(dashboardConfigId.toString());
                        }
                        this.loadingVisible = false;
                    });
                },
                error => {
                    this.loadingVisible = false;
                    this.isErrorPopupVisable = true;
                    sessionStorage.removeItem('selectedDashboard');
                    return;
                });
            });
        });
    }

    handleDashboardRedirect() {
        this.helperService.handleReportDashboardLoadError(this.hasAdminView);
    }

    getDashboard(dashboardId: number): Observable<Dashboard> {
        if (dashboardId === 0) {
            const dashboard = new Dashboard();
            dashboard.id = dashboardId;
            dashboard.isPublic = false;
            dashboard.includeZeroes = false;
            dashboard.priority = 1;
            dashboard.recurrenceId = this.dummyRecurrence.id;
            dashboard.defaultUiViewId = 0;
            dashboard.seriesIdList = '';
            return of(dashboard);
        } else {
            return this.dashboardService.getDashboard(dashboardId);
        }
    }

    onShowInactiveSegmentsChange(e): void {
        this.dashboardService.updateShowInactiveSegments(this.showInactiveSegments).subscribe();
    }

    previewDashboard(regenerate: boolean): void {
        this.previewPopupVisible = true;
        this.previewLoading = true;
        this.accountFilterVisible = false;

        if (!this.previewSeriesIds?.length) {
            this.previewSeriesIds = this.series.map(x => x.id);
        }

        this.periodService.setStorageDates(this.sellerId, this.seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {

            const context = new DashboardContext();
            context.id = 0;
            context.configId = this.dashboardConfigId;
            context.beginDate = this.previewBeginDate;
            context.endDate = this.previewEndDate;
            context.selectedSellers = this.constructIdListString(this.previewSelectedSellerIds);
            context.token = this.authService.getUserFromToken();
            context.name = this.dashboardControl.instance.dashboard().title.text();
            context.xml = '';
            context.priority = this.defaultPriority;
            context.seriesIdList = this.constructIdListString(this.previewSeriesIds);
            context.recurrenceId = this.recurrenceId === 0 ? null : this.recurrenceId;
            context.isPublic = this.isPublic;
            context.includeZeroes = this.includeZeroes;
            context.attributes = '';
            context.showInactiveSegments = this.showInactiveSegments;
            context.viewableAccounts = this.previewViewableAccounts;
            context.isDesignMode = false;

            const dashboardJSON = this.dashboardControl.instance.dashboard().getJSON();
            this.dashboardService.postDashboardConfig(this.dashboardConfigId, dashboardJSON).subscribe(postConfigResult => {
                this.dashboardService.getPreviewDashboardArray(context).subscribe(result => {
                    this.dashboardService.getDashboardId(result.bytes).subscribe(dashboardPreviewConfigId => {
                        this.previewDashboardControl.instance.loadDashboard(dashboardPreviewConfigId.toString());
                        this.previewDashboardControl.instance.reloadData();
                        this.previewLoading = false;
                    });
                });
            });
        });
    }

    insertDashboard(newDashboard: Dashboard, dashboardConfigJSON: any): void {
        this.dashboardService.postDashboardConfig(this.dashboardConfigId, dashboardConfigJSON).subscribe(result => {
            // using the xml field to hold the dashboard config id value until we use it do retrieve the xml on the server
            newDashboard.xml = this.dashboardConfigId.toString();
            this.dashboardService.insertDashboard(newDashboard).subscribe(insertedDashboard => {
                if (!this.hasAdminView) {
                    this.appElementsService.sideNavOuterToolbar.insertNavigationItem(EnumBucketType.Dashboard,
                        insertedDashboard.id, insertedDashboard.name, true);
                }
                this.toast.success(`Dashboard '${insertedDashboard.name}' has been successfully created`);
                this.discardDashboardChanges();
                this.router.navigate([`/pages/dashboard/${insertedDashboard.id}`]);
                this.onListChange.emit();
            });
        });
    }

    updateDashboard(dashboard: Dashboard, dashboardConfigJSON: any): void {
        this.dashboardService.postDashboardConfig(this.dashboardConfigId, dashboardConfigJSON).subscribe(result => {
            // using the xml field to hold the dashboard config id value until we use it do retrieve the xml on the server
            dashboard.xml = this.dashboardConfigId.toString();
            this.dashboardService.updateDashboard(dashboard).subscribe(updateResult => {
                this.appElementsService.sideNavOuterToolbar.renameNavigationItem(EnumBucketType.Dashboard,
                    dashboard.id, dashboard.name);
                this.toast.success(`Dashboard '${dashboard.name}' has been successfully updated`);
                this.discardDashboardChanges();
                this.switchToViewerMode.emit();
                this.onListChange.emit();
                this.generateDashboard(this.dashboardId);
            });
        });
    }

    deleteDashboard(preventRedirect = false): void {
        this.loadingVisible = true;
        this.deletePopupVisible = false;
        this.dashboardService.deleteDashboard(this.dashboardId).subscribe(result => {
            if (result.responseCode === coreResponseCodes.Success) {
                sessionStorage.removeItem('selectedDashboard');
                this.appElementsService.sideNavOuterToolbar.removeNavigationItem(EnumBucketType.Dashboard, this.dashboardId);
                this.toast.success(`Dashboard '${this.dashboardName}' has been successfully deleted`);
                this.discardDashboardChanges();
                this.loadingVisible = false;
                this.onListChange.emit();
                if (!preventRedirect) {
                    this.handleDashboardRedirect();
                }
            } else {
                this.toast.error(`An error occurred while attempting to delete dashboard '${this.dashboardName}'`);
                this.loadingVisible = false;
            }
        }, error => {
            this.toast.error(error.error);
            this.loadingVisible = false;
        });
    }

    onBeforeRender(e): void {
        // Registering the extension for rich text editor
        e.component.registerExtension(new TextBoxItemEditorExtension(e.component));
        e.component.registerExtension(new ChartLineOptionsExtension(e.component));

        if (!this.permissionService.checkCurrentUserPermission(CoreFeature.ExportDashboards.toString()))
        {
            const exportExtension = e.component.findExtension('dashboardExport') as DashboardExportExtension;
            exportExtension.allowExportDashboard = false;
            exportExtension.allowExportDashboardItems = false;
        }
    }

    onSave(): void {
        const dashboardName = this.dashboardName;

        if (dashboardName === undefined || dashboardName.length === 0) {
            this.toast.warning('Please enter a dashboard title before saving');
        } else if (this.dashboardNameExists(dashboardName)) {
            this.toast.warning(`Dashboard '${dashboardName}' already exists. Please rename and try again.`);
        } else {
            this.loadingVisible = true;

            const dashboard: Dashboard = {
                id: this.dashboardId,
                name: dashboardName,
                // xml will be set on the server from the DashboardStorage
                xml: '',
                sellerIdString: '',
                priority: this.defaultPriority,
                seriesIdList: '',
                recurrenceId: this.recurrenceId === 0 ? null : this.recurrenceId,
                defaultUiViewId: this.defaultUiViewId,
                isPublic: this.isPublic,
                includeZeroes: this.includeZeroes,
                attributes: this.attributes,
                exportAttributes: '',
                useClientDataProcessing: this.useClientDataProcessing
            };

            if (this.dashboardId === 0) {
                this.insertDashboard(dashboard, this.dashboardControl.instance.dashboard().getJSON());
            } else {
                this.updateDashboard(dashboard, this.dashboardControl.instance.dashboard().getJSON());
            }
        }
    }

    onDelete(): void {
        this.deletePopupVisible = true;
    }

    // This is to force the TreeView widget to behave the same as the ListBox and win viewer defaults
    itemWidgetOptionsPrepared(e) {
        if (e.dashboardItem instanceof TreeViewItem) {
          e.options.selection.recursive = false;
        }
      }

    onResize(e): void {
        document.body.querySelector<HTMLElement>('.dx-popup-content .preview-dashboard-container').style.maxHeight =
            (e.actionValue[0].height - 130).toString() + 'px';
        document.body.querySelector<HTMLElement>('.dx-popup-content .dx-dashboard-surface').style.maxHeight =
            (e.actionValue[0].height - 130).toString() + 'px';
        document.body.querySelector<HTMLElement>('.dx-popup-content .preview-dashboard-sellers').style.height =
            (e.actionValue[0].height - 170).toString() + 'px';
    }

    setInitialValues(sellerId: number): void {

        this.filteredPeriods = this.recurrenceId > 0
            ? this.periods.filter(period => period.recurrenceId === this.recurrenceId)
            : this.periods;

        this.sortAndFilterPublishedPeriods();

        const storedBeginDate = localStorage.getItem('beginDate');
        const storedEndDate = localStorage.getItem('endDate');
        const sortedPeriodsDesc = this.periodService.sortPeriods(this.filteredPeriods);
        const getCurrentPeriod = this.periodService.getCurrent(sortedPeriodsDesc);

        // Due to Period Date Typing Issue cannot explicitly set context dates to new Date(storedBeginDate)
        // The Periods Array which fills the options of the drop down are strings not dates so the values will never show up
        if (this.filteredPeriods && this.filteredPeriods.length > 0) {
            const previewBeginPeriod = this.filteredPeriods.find(({ beginDate }) => beginDate.toString() === storedBeginDate);
            const previewEndPeriod = this.filteredPeriods.find(({ endDate }) => endDate.toString() === storedEndDate);
            this.previewBeginDate = previewBeginPeriod ? previewBeginPeriod.beginDate : null;
            this.previewEndDate = previewEndPeriod ? previewEndPeriod.endDate : null;
        }

        const selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
        this.previewSelectedSellerIds = this.getIdArrayFromConstructedListString(selectedSellers);
    }

    onRecurrenceChanged(): void {
        this.filteredPeriods = this.recurrenceId > 0
            ? this.periods.filter(period => period.recurrenceId === this.recurrenceId)
            : this.periods;
    }

    sortAndFilterPublishedPeriods(): void {
        this.periodsBeginSorted = this.helperService.removeDuplicateValuesByMatchingCallBack<Period, Date>(Object.assign([], this.filteredPeriods), (period) => period.beginDate);
        this.periodsEndSorted = this.helperService.removeDuplicateValuesByMatchingCallBack<Period, Date>(Object.assign([], this.filteredPeriods), (period) => period.endDate);
        this.periodsBeginSorted.sort((a, b) => {
            if (a.beginDate.valueOf() < b.beginDate.valueOf()) {
                return -1;
            } else {
                return 1;
            }
        });

        this.periodsEndSorted.sort((a, b) => {
            if (a.endDate.valueOf() < b.endDate.valueOf()) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    setAttributeValues(sellers: Seller[]): void {
        sellers.forEach(seller => {
            if (seller.histSellers.length > 0) {
                seller.histSellers[0].attributes.forEach(attribute => {
                    const obj: { [k: string]: any } = {};
                    if (attribute.attributeClass.isText) {
                        obj[attribute.attributeClass.name] = attribute.name;
                    } else {
                        obj[attribute.attributeClass.name] = attribute.name;
                    }
                    Object.assign(seller, obj);
                });
            }
        });
    }

    previewSettingsChanged(beginDateSet: boolean = false, endDateSet: boolean = false): void {
        if (this.previewPopupVisible && this.previewBeginDate < this.previewEndDate && this.previewSeriesIds.length > 0 && !this.previewLoading) {
            this.previewDashboard(true);
        } else if (this.previewBeginDate >= this.previewEndDate && beginDateSet) {
            const period = this.periods.filter(x => x.beginDate === this.previewBeginDate)[0];
            this.previewEndDate = period.endDate;
        } else if (this.previewBeginDate >= this.previewEndDate && endDateSet) {
            const period = this.periods.filter(x => x.endDate === this.previewEndDate)[0];
            this.previewBeginDate = period.beginDate;
        }
    }

    onClickAccountFilter(): void {
        this.accountFilterVisible = true;
    }

    onClickBackToDashboard(): void {
        this.accountFilterVisible = false;
        this.previewSettingsChanged();
    }

    onClickPreview(): void {
        if (!this.previewPopupVisible && !this.previewLoading) {
            this.previewDashboard(false);
        }
    }

    constructIdListString(ids: number[]): string {
        let listString = '';
        ids.forEach(x => {
            listString += `[${x.toString()}]`;
        });
        return listString;
    }

    getIdArrayFromConstructedListString(listString: string): number[] {
        const ids = [];
        if (!listString) {
            return ids;
        }

        listString.split(']').forEach(x => {
            ids.push(parseInt(x.replace('[', ''), 10));
        });
        ids.pop();
        return ids;
    }

    onDashboardInitialized(e): void {
        this.changesMade = false;
        this.appElementsService.headerComponent.unsavedChanges = this.changesMade;

        const toolboxExtension = e.component.findExtension('toolbox') as ToolboxExtension;

        // removing default menu items that we don't want
        toolboxExtension.removeMenuItem('create-dashboard');
        toolboxExtension.removeMenuItem('open-dashboard');
        toolboxExtension.removeMenuItem('save');

        // adding custom delete menu item
        const customDeleteMenuItemId = 'custom-delete';
        const customDeleteMenuItem = new DashboardMenuItem(customDeleteMenuItemId, 'Delete', 1, 0, () => {
            this.onDelete();
        });

        // Adding custom save as new menu item
        const customSaveAsNewMenuItemId = 'custom-save-as-new';
        const customSaveAsNewMenuItem = new DashboardMenuItem(customSaveAsNewMenuItemId, 'Save as New', 2, 0, () => {
            this.dashboardId = 0;
            let i = 0;
            let newDashboardName = this.dashboardName;
            while (this.dashboardNameExists(newDashboardName)) {
                newDashboardName = this.dashboardName + ` - Copy${i < 2 ? '' : ' ('+i+')'}`;
                i++;
            }
            this.dashboardName = newDashboardName;
            this.onSave();
        });

        // Adding custom save menu item
        if ((this.canCreateDashboard && this.dashboardId === 0) || (this.dashboardId > 0 && this.allowEditing)){
            if (toolboxExtension.menuItems().filter(a => a.id === 'custom-save').length === 0) {
                const customSaveMenuItem = new DashboardMenuItem('custom-save', 'Save', 1, 0, () => {
                    this.onSave();
                });
                toolboxExtension.addMenuItem(customSaveMenuItem);
            }
        }

        // Adding custom exit menu item
        const customExitMenuItemId = 'custom-exit';
        if (toolboxExtension.menuItems().filter(a => a.id === customExitMenuItemId).length === 0) {
            const customExitMenuItem = new DashboardMenuItem(customExitMenuItemId, 'Exit', 1000, 0, () => {
                this.exitDesignerClick();
            });
            toolboxExtension.addMenuItem(customExitMenuItem);
        }

        toolboxExtension.removeMenuItem(customDeleteMenuItemId);
        toolboxExtension.removeMenuItem(customSaveAsNewMenuItemId);

        if (this.dashboardId > 0) {
            if (this.allowEditing){
                toolboxExtension.addMenuItem(customSaveAsNewMenuItem);
            }
            if (!this.isDeleteButtonHidden){
                toolboxExtension.addMenuItem(customDeleteMenuItem);
            }
        }

        if (this.dashboardId){
            if (!localStorage.getItem(`${this.username}.selectedSellers`)){
                this.coreBotInteractionService.startInteraction(EnumCoreBotInteraction.DASelectorEmptyDashboard);
            }
            else {
                const context: any = {
                    BeginDate: this.dateAccountSelector.context.beginDate,
                    EndDate: this.dateAccountSelector.context.endDate,
                    SeriesId: this.dateAccountSelector.context.seriesId ? this.dateAccountSelector.context.seriesId : this.dateAccountSelector.prodSeriesId
                };
                this.xactionService.getXactionCountByPeriodSeries(context).subscribe(res => {
                    if (res.result === 0){
                        this.coreBotInteractionService.startInteraction(EnumCoreBotInteraction.EmptyPeriod);
                    }
                });
            }
            this.coreBotInteractionService.runAuditInteractions(CoreBotInteractionTriggerType.DashboardGeneration);
        }
    }

    onDashboardBeginUpdate(e): void {
        if (this.mode === this.MODE_DESIGNER) {
            this.changesMade = true;
            this.appElementsService.headerComponent.unsavedChanges = this.changesMade;
        }
        this.dashboardService.postDashboardConfig(this.dashboardConfigId, this.dashboardControl.instance.dashboard().getJSON()).subscribe();
    }

    dashboardNameExists(dashboardName: string): boolean {
        return this.dashboards.some(x => x.name === dashboardName && x.id !== this.dashboardId);
    }

    hasUnsavedChanges(): boolean {
        return this.mode === this.MODE_DESIGNER && this.changesMade;
    }

    switchToDesigner(): void {
        this.designDashboard(false);
    }

    switchToViewer(): void {
        this.discardDashboardChanges();
        this.generateDashboard(this.dashboardId);
    }

    exitDesignerClick(): void {
        this.onExitDesignerClick.emit();
    }

    onHomeDefaultClicked() {
        const toastMsg = 'Home page updated';
        this.isHomeDefault = !this.isHomeDefault;

        if (this.isHomeDefault) {
            this.defaultBucket.itemId = this.dashboardId;
            this.bucketService.addDefaultBucket(this.defaultBucket).subscribe(() => this.updateHomeButtonStatus(toastMsg));
        } else {
            this.bucketService.deleteDefaultBucketForSeller().subscribe(() => this.updateHomeButtonStatus(toastMsg));
        }
    }

    updateHomeButtonStatus(toastMsg = '') {
        const element = document.querySelector<HTMLElement>('.dx-toolbar-item .dx-button.home').firstChild as HTMLElement;
        if (this.isHomeDefault) {
            element.classList.add('toolbar-button-activated');
            element.title = 'Remove from Home Page';
        } else {
            element.classList.remove('toolbar-button-activated');
            element.title = 'Show on Home Page';
        }

        if (toastMsg) {
            this.toast.success(toastMsg);
        }
    }

    setDashboardMode(): void {
        const isDarkMode = this.siteThemeService.getIsDarkMode();
        for (const index in document.styleSheets) {
            if (document.styleSheets[index]) {
                const stylesheet = document.styleSheets[index];
                const href = stylesheet.href;
                if (href?.includes('dashboard-light-mode') || href?.includes('dashboard-dark-mode')) {
                    stylesheet.disabled = (!isDarkMode && href?.includes('dashboard-dark-mode')) || (isDarkMode && href?.includes('dashboard-light-mode'));
                }
            }
        }
    }

    discardDashboardChanges(): void {
        // this is a way to avoid the devexpress out-of-the-box 'Confirm saving' pop-up - it doesn't fit our implementation
        this.dashboardControl.instance.initializeDashboard((this.dashboardConfigId).toString(), {});
        // there are no longer any unsaved changes
        this.appElementsService.headerComponent.unsavedChanges = false;
    }

    ngOnDestroy() {
        if(this.mode === this.MODE_VIEWER && this.guid && this.guid !== ''){
            this.cancelAllDashboardRequests();
        }
        this.dashboardControl?.instance?.dispose();
        this.pageDestroyed = true;
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxReportViewerModule,
        DxLoadPanelModule,
        DxDashboardControlModule,
        DxCheckBoxModule,
        DxButtonModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxTreeListModule,
        DxPopupModule,
        DxResponsiveBoxModule,
        DxTextBoxModule,
        DxDataGridModule,
        DxValidatorModule,
        CoreComponentModule,
    ],
    declarations: [DashboardViewerComponent],
    exports: [DashboardViewerComponent]
})
export class DashboardViewerModule { }
