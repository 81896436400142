import { ChangeDetectorRef, Component, ComponentRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { _ } from 'ag-grid-community';
import { DxAccordionComponent, DxLoadPanelComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { CoreGridComponent } from 'src/app/shared/components/core-data-grid/core-data-grid.component';
import { CoreDropdownComponent } from 'src/app/shared/components/core-dropdown/core-dropdown.component';
import { CorePopupComponent } from 'src/app/shared/components/core-popup/core-popup.component';
import { CoreTreeListComponent } from 'src/app/shared/components/core-tree-list/core-tree-list.component';
import { CoreFeature, coreResponseCodes, EnumCoreBotInteraction, EnumSingletonClassId, EnumUserGroup, fileUploadTypeIds, processingDataViewerFunctions, processLogRecordTypes, settingClassIds,
     singletonClassIds, uiViewContextCodes } from 'src/app/shared/constants/enums';
import { AttributeClass } from 'src/app/shared/models/attribute-class';
import { ColumnType } from 'src/app/shared/models/core-column-type';
import { CoreDropdownPopupProperties } from 'src/app/shared/models/core-dropdown-popup-properties';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { CoreDynamicCellEditorProps } from 'src/app/shared/models/core-dynamic-cell-editor-props';
import { CoreDynamicInputProperties } from 'src/app/shared/models/core-dynamic-input-properties';
import { CoreEventArguments } from 'src/app/shared/models/core-event-arguments';
import { CorePopupProperties } from 'src/app/shared/models/core-popup-properties';
import { CorePopupStep } from 'src/app/shared/models/core-popup-step';
import { CoreRequest } from 'src/app/shared/models/core-request';
import { Customer } from 'src/app/shared/models/customer';
import { DatasourceMapping } from 'src/app/shared/models/datasource-mapping';
import { DevExpressDMLFunctions } from 'src/app/shared/models/dev-express-dml-functions';
import { EtlPlan } from 'src/app/shared/models/etl-plan';
import { IdLongValueString } from 'src/app/shared/models/id-long-value-string';
import { IGCacheRefresh } from 'src/app/shared/models/ig-cache-refresh';
import { PublishPeriod } from 'src/app/shared/models/period';
import { PollValue } from 'src/app/shared/models/poll-value';
import { PopupArguments } from 'src/app/shared/models/popup-arguments';
import { ProcessRulesContext } from 'src/app/shared/models/contexts/process-rules-context';
import { ProcessingContext } from 'src/app/shared/models/contexts/processing-context';
import { Product } from 'src/app/shared/models/product';
import { Recurrence } from 'src/app/shared/models/recurrence';
import { RequiredProperty } from 'src/app/shared/models/required-property';
import { SellerName } from 'src/app/shared/models/seller-name';
import { Tag } from 'src/app/shared/models/tag';
import { UiView } from 'src/app/shared/models/ui-view';
import { XactionFileImportRequest } from 'src/app/shared/models/xaction-file-import-request';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { DatasourceMappingService } from 'src/app/shared/services/datasource-mapping.service';
import { DatasourceService } from 'src/app/shared/services/datasource.service';
import { EtlPlanService } from 'src/app/shared/services/etl-plan.service';
import { FieldService } from 'src/app/shared/services/field.service';
import { FileProcessingService } from 'src/app/shared/services/file-processing.service';
import { PeriodService } from 'src/app/shared/services/period.service';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { RecurrenceService } from 'src/app/shared/services/recurrence.service';
import { SellerService } from 'src/app/shared/services/seller.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { UiViewService } from 'src/app/shared/services/ui-view.service';
import { defaultMappingNameValue, processAllPlanName, toastrConstants, yesNoArray } from '../../shared/constants/constants';
import { CoreColumn } from '../../shared/models/core-column';
import { GridProps } from '../../shared/models/core-data-grid-properties';
import { DisplayDataArguments } from '../../shared/models/core-display-data-arguments';
import { TreeListProps } from '../../shared/models/core-tree-list-properties';
import { ImportedTable } from '../../shared/models/processing-imported-table';
import { ProcessedTable } from '../../shared/models/processing-processed-table';
import { HelperService } from '../../shared/services/helper.service';
import { ProcessingService } from '../../shared/services/processing.service';
import { confirm, custom } from 'devextreme/ui/dialog';
import { CalculationService } from 'src/app/shared/services/calculation.service';
import { PeriodDropdownComponent } from 'src/app/shared/components/period-dropdown/period-dropdown.component';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreResponse } from 'src/app/shared/models/core-response';
import { IntegrationSourceService } from '../../shared/services/integration-source-service';
import { IntegrationSource } from '../../shared/models/integration-source';
import { AnalyticService } from 'src/app/shared/services/analytic.service';
import { SeriesService } from 'src/app/shared/services/series.service';
import { Series } from 'src/app/shared/models/series';
import { CoreBotService } from 'src/app/shared/services/core-bot.service';
import { CoreBotInteractionService } from 'src/app/shared/services/core-bot-interaction-service';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { ResultSet } from '@devexpress/analytics-core/analytics-data';
import { CoreEditorOptions } from 'src/app/shared/models/core-editor-options';

@Component({
    selector: 'app-processing',
    templateUrl: './processing.component.html',
    styleUrls: ['./processing.component.scss']
})

export class ProcessingComponent implements OnInit, OnDestroy {
    @ViewChild('periodDropdown', { static: false }) periodDropdown: PeriodDropdownComponent;
    @ViewChild('layoutDropdown', { static: false }) layoutDropdown: CoreDropdownComponent;

    @ViewChild('importedAccordion', { static: false }) importedAccordion: DxAccordionComponent;
    @ViewChild('processedAccordion', { static: false }) processedAccordion: DxAccordionComponent;
    @ViewChild('accordionWrapper', { static: false }) accordionWrapper: ElementRef;

    @ViewChild('importedRecordsGrid', { static: false }) importedRecordsGrid: CoreGridComponent;
    @ViewChild('processedTreeList', { static: false }) processedTreeList: CoreTreeListComponent;
    @ViewChild('processingPopup', { static: false }) processingPopup: CorePopupComponent;
    @ViewChild('decisionPopup', { static: false }) decisionPopup: CorePopupComponent;

    toastConsts = toastrConstants;
    recurrences: Recurrence[];
    series: Series[];
    datasourceMappings: DatasourceMapping[];
    integrationSources: IntegrationSource[];
    activeIntegrationSources: IntegrationSource[];
    sellers: SellerName[];
    tags: Tag[];
    products: Product[];
    customers: Customer[];
    uiViews: UiView[] = [];
    filteredLayouts: UiView[];
    allEtlPlans: EtlPlan[];
    publishedEtlPlans: EtlPlan[];
    filteredPublishedEtlPlans: EtlPlan[];
    selectedSeriesId: number;
    selectedPeriodId: number;
    selectedRecurrenceId: number;
    selectedUiViewId: number;
    popupUrl: string = '/record-data-viewer';
    importFileReplaces: boolean = false;
    importFileDatasourceMappingId: number;
    isImportFileDirty: boolean = false;
    pageDestroyed: boolean = false;
    popupDefaultHeight: string = '275';
    popupDefaultWidth: string = '510';
    popupLogHeight: string = '350';
    popupLogWidth: string = '600';
    dummyLayout: UiView = new UiView().createDummy();
    processingContext: ProcessingContext;
    ruleProcessingContext: ProcessRulesContext;
    periodForPublishing: PublishPeriod;
    isPeriodLocked: boolean = false;
    apiCallsReturned: boolean = false;
    processLogPollErrorCap: number = 20;

    permissionImportRecords: boolean;
    permissionViewImportedRecords: boolean;
    permissionDeleteImportedRecords: boolean;
    permissionRunCycle: boolean;
    permissionDeleteProcessedRecords: boolean;
    permissionUpdateAnalytics: boolean;
    permissionViewProcessedRecords: boolean;
    permissionDeleteLayout: boolean;
    permissionAddLayout: boolean;
    permissionEditLayout: boolean;
    permissionDeletePeriod: boolean;
    permissionCreatePeriod: boolean;
    permissionLockPeriod: boolean;
    permissionPublishPeriod: boolean;
    permissionProcessViewablePlans: boolean;

    layoutPopupProps: CoreDropdownPopupProperties[] = [
        new CoreDropdownPopupProperties().setBodyMessage('add', null).addValidationFunction(this, this.layoutValidation)
    ];

    importedGridProps: GridProps = new GridProps('importedRecordsGrid', null, false, true, true, true).setKeyColumn('datasourceId')
        .setStateSaveLocal().setColumnAutoWidth(false);
    processedTreeListProps: TreeListProps = new TreeListProps('processedRecords', 'id', 'parentId', null, true)
        .setColumnResizingAllowed(true).setColumnAutoWidth(false);
    cleaningGridProps: GridProps = new GridProps('cleaningRecordsGrid', null, false, true)
        .addDMLFunctions(new DevExpressDMLFunctions(null, null, null, false, true, false))
        .setColumnResizingAllowed(true);
    columnCleaningGridProps: GridProps = new GridProps('columnCleaningGrid', null, false, true)
        .addDMLFunctions(new DevExpressDMLFunctions(null, null, null, false, true, false));

    importedRecords: ImportedTable[];
    processedRecords: ProcessedTable[];
    filteredImportedRecords: ImportedTable[];
    filteredProcessedRecords: ProcessedTable[];
    viewImportedItems: UiView[] = [];
    viewProcessedItems: UiView[] = [];
    runCycleItems: EtlPlan[];

    availableColumnMappings: string[];
    addLayoutMessage: string = '<i>Note - select datasources and plans prior to adding a layout</i>';

    importedColumns: CoreColumn[] = [
        new CoreColumn('datasourceId', '', false),
        new CoreColumn('datasourceName', 'Datasource', true, null, new ColumnType().createDropDownButton(null, 'Click to edit data', true, 'datasourceName',
            'id', 'name', this, this.importedGridOpenViewEdit, this.importedGridLayoutClick, this.getDatasourceViewerLayouts, 'No layouts exist')),
        new CoreColumn('recurrenceName', 'Recurrence', true),
        new CoreColumn('numRecords', 'Count', true),
        new CoreColumn('editDate', 'Last Edited', true, 'datetime'),
        new CoreColumn('isDeleted', '', false)
    ];

    processedColumns: CoreColumn[] = [
        new CoreColumn('id', '', false),
        new CoreColumn('isDeleted', '', false),
        new CoreColumn('parentId', '', false),
        new CoreColumn('name', 'Plans/Rules', true, null, new ColumnType().createDropDownButton(null, 'Click to edit data', true, 'name',
            'id', 'name', this, this.processedTreeOpenViewEdit, this.processedTreeLayoutClick, this.getProcessedViewerLayouts, 'No layouts exist'))
            .sortAsc(null, 2),
        new CoreColumn('ruleType', 'Type', true),
        new CoreColumn('orderIndex', 'Level', true).sortAsc(null, 1),
        new CoreColumn('numRecords', 'Count', true),
        new CoreColumn('processDate', 'Last Processed', true, 'datetime')
    ];

    cleaningGridColumns: CoreColumn[] = [
        new CoreColumn('singletonName', 'Column/Field', true).sortAsc(),
        new CoreColumn('rowNumbersString', 'Affected Row Numbers', true),
        new CoreColumn('text', 'Unrecognized Text', true)
            .addCalculateDisplayValue(this, this.calculateCleaningTextDisplayValue),
        new CoreColumn('replaceWith', 'Replace With', true, 'string', null, true)
            .addCalculateDisplayValue(this, this.calculateReplaceWithDisplayValue)
            .addDynamicCellEditorTemplate(this, this.getEditorPropsForCleaningGridCell)
    ];

    columnCleaningGridColumns: CoreColumn[] = [
        new CoreColumn('oldVal', 'Expected Column', true),
        new CoreColumn('newVal', 'Unrecognized Column', true, 'string', null, true)
            .addDynamicCellEditorTemplate(this, this.getEditorPropsForColumnCleaningGridCell, true)
    ];

    showHideInactiveMsg: string;
    showInactiveMsg: string = 'Show Inactive';
    hideInactiveMsg: string = 'Hide Inactive';

    processingPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly(this.popupDefaultWidth, this.popupDefaultHeight, true, 'Run Cycle');
    processingPopupSteps: CorePopupStep[] = [];

    decisionPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly(this.popupDefaultWidth, this.popupDefaultHeight, true, 'Choose an Import Option');
    decisionPopupSteps: CorePopupStep[] = [];

    periodDropdownProps: CoreDropdownProperties[] = [];
    layoutDropdownProps: CoreDropdownProperties;
    isImplementer: boolean = false;
    useNewGrid: boolean = false;
    loadpanel: ComponentRef<DxLoadPanelComponent>;
    importFileIntegrationSourceId: number;
    disableStaleRules: boolean = true;
    staleRules: string[];
    containerIdsByName: Record<string, string[]> = {};

    constructor(private processingService: ProcessingService,
        private helperService: HelperService,
        private toast: ToastrService,
        private periodService: PeriodService,
        private recurrenceService: RecurrenceService,
        private appElementService: AppElementsService,
        private settingService: SettingService,
        private fileProcessingService: FileProcessingService,
        private datasourceMappingService: DatasourceMappingService,
        private integrationSourceService: IntegrationSourceService,
        private datasourceService: DatasourceService,
        private processLogService: ProcessLogService,
        private sellerService: SellerService,
        private fieldService: FieldService,
        private uiViewService: UiViewService,
        private etlPlanService: EtlPlanService,
        private seriesService: SeriesService,
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private calcService: CalculationService,
        private permissionService: PermissionService,
        private analyticService: AnalyticService,
        private coreBotService: CoreBotService,
        private coreBotInteractionService: CoreBotInteractionService,
        private cd: ChangeDetectorRef,
        private bbService: BuildingBlocksService) {

        this.periodDropdownProps['series'] = new CoreDropdownProperties().createPeriodDropdownDefaults('name', false, false, false);
        this.periodDropdownProps['recurrence'] = new CoreDropdownProperties().createPeriodDropdownDefaults('name', false, false, false);
        this.periodDropdownProps['period'] = new CoreDropdownProperties().createPeriodDropdownDefaults('dateRange', true, true, true, 'Period');

        this.periodService.getPeriodLockedEvent().subscribe(() => {
            this.isPeriodLocked = true;
        });

        this.sellerService.getMe().subscribe(res => {
            this.isImplementer = EnumUserGroup.Implementer === res.userGroupId;
        });
        this.processingPopupProps.uploadUrl = this.fileProcessingService.getFileUploadUrl();
    }

    ngOnInit() {

        this.permissionImportRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.ImportRecords.toString());
        this.permissionViewImportedRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewImportedRecords.toString());
        this.permissionRunCycle = this.permissionService.checkCurrentUserPermission(CoreFeature.RunCycle.toString());
        this.permissionDeleteProcessedRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteProcessedRecords.toString());
        this.permissionUpdateAnalytics = this.permissionService.checkCurrentUserPermission(CoreFeature.UpdateAnalytics.toString());
        this.permissionViewProcessedRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewProcessedRecords.toString());
        this.permissionDeleteLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteLayouts.toString());
        this.permissionAddLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.CreateLayouts.toString());
        this.permissionEditLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.EditLayouts.toString());
        this.permissionDeletePeriod = this.permissionService.checkCurrentUserPermission(CoreFeature.DeletePeriod.toString());
        this.permissionCreatePeriod = this.permissionService.checkCurrentUserPermission(CoreFeature.CreatePeriod.toString());
        this.permissionLockPeriod = this.permissionService.checkCurrentUserPermission(CoreFeature.LockPeriod.toString());
        this.permissionPublishPeriod = this.permissionService.checkCurrentUserPermission(CoreFeature.PublishPeriod.toString());
        this.permissionProcessViewablePlans = this.permissionService.checkCurrentUserPermission(CoreFeature.ProcessViewablePlans.toString());

        this.periodDropdownProps['period'].deleteHidden = !this.permissionDeletePeriod;
        this.periodDropdownProps['period'].isLockShown = this.permissionLockPeriod;
        this.periodDropdownProps['period'].isPublishShown = this.permissionPublishPeriod;
        this.periodDropdownProps['period'].addNewHidden = !this.permissionCreatePeriod;

        this.importedGridProps.isColumnResizingAllowed = true;

        this.layoutDropdownProps = new CoreDropdownProperties()
        .createAll(true, 'id', 'name', !this.permissionEditLayout, !this.permissionAddLayout, !this.permissionEditLayout, !this.permissionDeleteLayout, true, false, 170, 'layout',
            'deleteDisabled', 'deleteDisabled', 'deleteDisabled', false, this.addLayoutMessage,
            'Apply Layout');

        if (this.permissionImportRecords) {
            const importedIndex = this.importedColumns.findIndex(x => x.columnHeader === 'Import File');
            if (importedIndex === -1){
                this.importedColumns.push(
                    new CoreColumn('import', 'Import File', true, null, new ColumnType().createButton('. . .', this.openImportFileDecision, this, 'Import file for datasource'))
                );
            }
        }

        if (this.permissionRunCycle || this.permissionProcessViewablePlans) {
            const processedIndex = this.processedColumns.findIndex(x => x.columnHeader === 'Process');
            if (processedIndex === -1){
                this.processedColumns.push(
                    new CoreColumn('openBuildingBlock', 'Edit', true, null,
                        new ColumnType().createButton('', this.openInBuildingBlocksFunctions, this, 'Open In Building Blocks', 'comment', undefined, undefined,
                            (obj) => obj.row.data.parentId !== null && obj.row.data.parentId !== undefined))
                );
                this.processedColumns.push(
                    new CoreColumn('process', 'Process', true, null, new ColumnType().createButton('. . .', this.performRunCycleCheck, this, 'Process')));
            }
        }

        forkJoin([
            this.settingService.getSettingsByClassIds([
                settingClassIds.InternalProcessingLayout
            ]),
            this.datasourceMappingService.getAllDatasourceMappings(),
            this.integrationSourceService.getAllIntegrationSources(),
            this.uiViewService.getUiViewsByContextCodes([
                uiViewContextCodes.ProcessingLayout,
                uiViewContextCodes.EditXactionsWebNew,
                uiViewContextCodes.EditCalculationsWebNew]),
            this.etlPlanService.getAllEtlPlans()
        ]).subscribe(([settings, datasourceMappings, integrationSources, uiViews, allEtlPlans]) => {
            this.allEtlPlans = allEtlPlans;
            this.publishedEtlPlans = allEtlPlans.filter(plan => plan.isPublished);
            this.publishedEtlPlans
                .sort((a, b) => (a.description === processAllPlanName || b.description === processAllPlanName) ? 1
                    : (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : (a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 0)));

            this.publishedEtlPlans.forEach(plan => plan.recurrenceIds = this.helperService.parseBracketedIdString(plan.recurrences));
            this.setFilteredPublishedEtlPlans();
            this.selectedUiViewId = Number(settings.find(x => x.settingClassId === settingClassIds.InternalProcessingLayout)?.value ?? this.dummyLayout.id);
            this.datasourceMappings = datasourceMappings;
            this.activeIntegrationSources = integrationSources.filter(x => x.isActive === true);
            this.uiViews = uiViews.sort((a, b) => this.sortUiView(a, b));

            if (this.permissionViewImportedRecords) {
                this.viewImportedItems = this.uiViews.filter(x => x.contextCode === uiViewContextCodes.EditXactionsWeb && x.datasourceId === null);
            }

            if (this.permissionViewProcessedRecords){
                this.viewProcessedItems = this.uiViews.filter(x => x.contextCode === uiViewContextCodes.EditCalculationsWeb && x.functionId === null && x.functionGroupId === null);
            }

            const sideNavChanges: Subscription = this.appElementService.getSideNavOpenChanges().subscribe(x => {
                if (!this.pageDestroyed) {
                    setTimeout(() => {
                        this.adjustAccordionItemHeights();
                    }, this.appElementService.sideNavOuterToolbar.animationDuration + 1);
                } else {
                    sideNavChanges.unsubscribe();
                }
            });

            this.apiCallsReturned = true;
        });

        forkJoin([this.fieldService.getTags(),
            this.sellerService.getSellerNames(),
            this.fieldService.getAllProducts(),
            this.fieldService.getAllCustomers()
        ]).subscribe(([tags, sellers, products, customers]) => {
            this.tags = tags;
            this.sellers = sellers;
            this.products = products;
            this.customers = customers;
        });

        this.recurrenceService.GetAllRecurrences().subscribe(result => this.recurrences = result);
        this.seriesService.getAllSeries().subscribe(result => this.series = result);
        this.bbService.getAllContainers().subscribe(containerResponse => {
            containerResponse.results.forEach(container => {
                if(!Object.keys(this.containerIdsByName).some(key => key === container.name)){
                    this.containerIdsByName[container.name] = [];
                }
                this.containerIdsByName[container.name].push(container.id);
            });
        });

        this.periodService.getPeriodEvents().pipe(skip(1), take(1)).subscribe(() => this.ngOnInit());

        this.showHideInactiveMsg = this.showInactiveMsg;

        if (localStorage.getItem('CoreBotQuestion') !== null){
            localStorage.removeItem('CoreBotQuestion');
        }
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }

    refreshPage(e: any) {
        this.ngOnInit();
        this.populateImportedAndProcessedData();
    }

    populateImportedAndProcessedData(): void {
        if (this.permissionViewImportedRecords) {
            this.processingService.getImportedTable(this.selectedSeriesId, this.selectedRecurrenceId, this.selectedPeriodId).subscribe(importedRecords => {
                this.importedRecords = importedRecords;
                this.importedRecords.forEach(x => {
                    x['import'] = '...';
                });
                this.applyLayout();
                this.adjustAccordionItemHeights();
            });
        }

        if (this.permissionViewProcessedRecords) {
            this.processingService.getProcessedTable(this.selectedSeriesId, this.selectedRecurrenceId, this.selectedPeriodId).subscribe(processedRecords => {
                this.processedRecords = processedRecords;
                this.processedRecords.forEach(x => {
                    x['parentId'] = x['parentId'] ? x['parentId'] : null;
                    x['process'] = '...';
                    x.numRecords ??=  this.processedRecords.filter(y => y.parentId === x.id).map(y => y.numRecords).reduce((a,b) => a + b, 0);
                });
                this.applyLayout();
                this.adjustAccordionItemHeights();
            });
            this.calcService.getStaleRulesByPeriod(this.selectedPeriodId).subscribe(res => {
                this.disableStaleRules = res.results.length === 0;
                if(!this.disableStaleRules){
                    this.coreBotInteractionService.startInteraction(EnumCoreBotInteraction.StaleProcessedOutput);
                }
                this.staleRules = res.results;
            });
        }
    }

    applyLayout(): void {
        const layout = this.filteredLayouts.find(x => x.id === this.selectedUiViewId);
        if (layout && this.selectedUiViewId !== this.dummyLayout.id) {
            const datasources = this.helperService.removeBracketsFromString(layout.datasources, true) as string[];
            const plans = this.helperService.removeBracketsFromString(layout.plans, true) as string[];

            if (this.permissionViewImportedRecords && this.importedRecords){
                this.filteredImportedRecords = this.importedRecords.filter(x => datasources.some(d => d === x.datasourceId.toString()));
            }
            if (this.permissionViewProcessedRecords && this.processedRecords){
               this.filteredProcessedRecords = this.processedRecords.filter(x => plans.some(p => p === x.id.toString() || (x.parentId !== null && p === x.parentId.toString())));
            }
        } else {
            this.selectedUiViewId = this.dummyLayout.id;
            this.filteredImportedRecords = this.importedRecords;
            this.filteredProcessedRecords = this.processedRecords;
        }

        this.toggleActiveSegmentsDatasource();
    }

    getSelectedDatasourceIdsString(): string {
        return this.importedRecordsGrid.grid.selectedRowKeys.map(x => '[' + x + ']').join('');
    }

    getSelectedPlanIdsString(): string {
        const planIds = this.processedTreeList.treeList.selectedRowKeys.map(key =>  /^[-\d]/.test(key) ? key : this.processedRecords.find(rule => rule.id === key).parentId);
        return [... new Set(planIds)].map(x => '[' + x + ']').join('');
    }

    onPeriodChanged(value: any): void {
        this.selectedPeriodId = value;
        if(!this.helperService.isNullOrUndefined(this.selectedPeriodId)) {
            this.periodService.isPeriodLocked(this.selectedPeriodId).subscribe(res => {
                this.isPeriodLocked = res;
            });
        }

        this.setFilteredUiViews();
        this.setFilteredPublishedEtlPlans();
        this.populateImportedAndProcessedData();
    }

    setFilteredUiViews(): void {
        this.filteredLayouts = this.uiViews.filter(x => x.recurrenceId === this.selectedRecurrenceId && x.contextCode === uiViewContextCodes.ProcessingLayout);
        this.filteredLayouts.unshift(this.dummyLayout);
    }

    setFilteredPublishedEtlPlans(): void {
        // Todo: Revisit onPeriodChanged event triggering this on load
        if (this.publishedEtlPlans) {
            this.filteredPublishedEtlPlans = this.publishedEtlPlans.filter(x => x.recurrenceIds.some(y => y === this.selectedRecurrenceId) || x.recurrenceIds.length === 0);
            if (this.permissionRunCycle) {
                this.runCycleItems = this.filteredPublishedEtlPlans;
            }
        }
    }

    importedGridLayoutClick(eventArgs: any): void {
        this.importedGridOpenViewEdit(null, [eventArgs.component.data.datasourceId], eventArgs.event.itemData.id);
    }

    getDatasourceViewerLayouts(column: CoreColumn, data: any): UiView[] {
        return this.uiViews.filter(x => x.datasourceId === data.datasourceId);
    }

    importedGridOpenViewEdit(eventArgs: any, datasourceIds: number[] = [], uiViewId: number = null): void {
        if (this.permissionViewImportedRecords){
            this.importedGridProps.loadingVisible = true;
            const popupArgs = new PopupArguments().createForImported([this.selectedPeriodId], this.selectedSeriesId, datasourceIds, this.selectedRecurrenceId);

            if (eventArgs && datasourceIds && datasourceIds.length < 1) {
                popupArgs.datasourceIds.push(eventArgs.event.data.datasourceId);
                popupArgs.shortTitle = this.importedRecords.find(x => x.datasourceId === eventArgs.event.data.datasourceId).datasourceName;
                popupArgs.title = 'Datasource: ' + popupArgs.shortTitle;
            } else if (datasourceIds && datasourceIds.length > 1) {
                popupArgs.shortTitle = 'Imported Records';
                popupArgs.title = 'Imported Records';
            }

            const requiredProperties = [new RequiredProperty('seriesId', this.selectedSeriesId), new RequiredProperty('periodId', [this.selectedPeriodId]),
                new RequiredProperty('datasourceId', datasourceIds[0])];

            if (uiViewId !== null) {
                requiredProperties.push(new RequiredProperty('uiViewId', uiViewId));
            }

            const displayDataArguments = new DisplayDataArguments(requiredProperties, processingDataViewerFunctions.Imported, popupArgs);
            this.openViewEditPopup(displayDataArguments, this.importedGridProps);
        }
}

    processedTreeLayoutClick(eventArgs: any): void {
        const selectedValues: { ruleIds: string[], planIds: string[] } = { ruleIds: [], planIds: [] };
        const layout = eventArgs.event.itemData;

        if (layout.functionGroupId) {
            selectedValues.planIds.push(layout.functionGroupId.toString());
        } else if (layout.functionId) {
            selectedValues.ruleIds.push('Sg' + layout.functionId.toString());
        } else if (layout.ruleId) {
            selectedValues.ruleIds.push('Co' + layout.ruleId.toString());
        }

        this.processedTreeOpenViewEdit(null, selectedValues, layout.id);
    }

    getProcessedViewerLayouts(column: CoreColumn, data: any): UiView[] {
        if (data.id.startsWith('Sg')) {
            return this.uiViews.filter(x => x.functionId === +data.id.slice(2));
        } else if (data.id.startsWith('Co')) {
            return this.uiViews.filter(x => x.ruleId === +data.id.slice(2));
        } else {
            return this.uiViews.filter(x => x.functionGroupId === +data.id);
        }
    }

    processedTreeOpenViewEdit(eventArgs: any, selectedValues: { ruleIds: string[], planIds: string[] } = null, uiViewId: number = null): void {
        if (this.permissionViewProcessedRecords){
            this.processedTreeListProps.loadingVisible = true;
            let ruleIds: string[] = [];
            let planIds: string[] = [];

            if (selectedValues !== null) {
                ruleIds = selectedValues.ruleIds;
                planIds = selectedValues.planIds;
            } else {
                const rowData = eventArgs.event.row.data;
                if (rowData.parentId !== null) {
                    if(rowData.id.startsWith('Co')){
                        ruleIds.push(...this.containerIdsByName[rowData.name]);
                    } else {
                        ruleIds.push(rowData.id);
                    }
                    planIds.push(rowData.parentId);
                } else {
                    planIds.push(rowData.id);
                }
            }

            const popupArgs = new PopupArguments().createForProcessed([this.selectedPeriodId], this.selectedSeriesId, planIds, ruleIds, this.selectedRecurrenceId);
            const requiredProperties = [new RequiredProperty('seriesId', this.selectedSeriesId), new RequiredProperty('periodId', this.selectedPeriodId)];
            if (uiViewId !== null) {
                requiredProperties.push(new RequiredProperty('uiViewId', uiViewId));
            }

            if (ruleIds?.length === 1) {
                popupArgs.shortTitle = this.processedRecords.find(x => x.id === ruleIds[0])?.name ?? '';
                popupArgs.title = 'Rule: ' + popupArgs.shortTitle;
            } else if (planIds?.length === 1) {
                popupArgs.shortTitle = this.processedRecords.find(x => x.id === planIds[0])?.name ?? '';
                popupArgs.title = 'Plan: ' + popupArgs.shortTitle;
            } else {
                popupArgs.title = 'Processed Records';
                popupArgs.shortTitle = 'Processed Records';
            }

            const displayDataArguments = new DisplayDataArguments(requiredProperties, processingDataViewerFunctions.Processed, popupArgs);
            this.openViewEditPopup(displayDataArguments, this.processedTreeListProps);
        }
    }

    processFunctions(eventArgs: CorePopupStep) {
        if (this.periodLockCheck()) {
            return;
        }
        this.processedTreeListProps.loadingVisible = true;
        const request = new CoreRequest<ProcessRulesContext>(this.ruleProcessingContext, null);

        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            request.transactionId = newTransactionResponse.result;
            this.pollProcessLogByTransactionId('Process Log', request.transactionId, true);
            this.processingService.processRulesByRequest(request).subscribe(result => this.handleProcessRulesResult(result, request));
        });
    }

    openInBuildingBlocksFunctions(eventArgs: any) {
        const row = eventArgs.event.row.data;

        if (row?.parentId) {
            this.router.navigate([`/building-blocks/${row.id}`]);
        }
    }

    handleProcessRulesResult = (result: CoreResponse<any>, request: CoreRequest<ProcessRulesContext> = null) => {
        this.processedTreeListProps.loadingVisible = false;
        if (result.responseCode === coreResponseCodes.Success) {
            this.toast.success('Plan/Rule successfully processsed.');
            this.refreshPage(null);
        } else if (result.responseCode === coreResponseCodes.CanceledByUser) {
            this.toast.warning('Processing cancelled.');
        } else if (result.responseCode === coreResponseCodes.StaleDependencies) {
            this.handleStaleDependenciesResult(result.results, request);
        } else {
            this.toast.error(this.toastConsts.planSegmentProcessingError);
        }
    };

    handleStaleDependenciesResult(rules: {key: string, value: string}[], request) {
        const ruleStr = rules.map(x => x.value).sort((a, b) => a.localeCompare(b)).join('<br>');
        custom({
            title: 'Process',
            messageHtml: 'Some rules have dependencies that are out-of-date. Do you want to process these also?<br><br>' + ruleStr,
            buttons: [
                {text: 'Yes', onClick: (e) => e.component.option('text')},
                {text: 'No', onClick: (e) => e.component.option('text')},
                {text: 'Cancel', onClick: (e) => e.component.option('text')}
            ]
        }).show().done((dialogResult) => {
            if (dialogResult === 'Cancel') {
                this.processingPopupProps.visible = false;
                this.cd.detectChanges();
            } else {
                if (dialogResult === 'Yes') {
                    request.argument.dependentIds = rules.map(x => x.key);
                }
                request.argument.shouldCheckDependencies = false;
                this.processingService.processRulesByRequest(request).subscribe(this.handleProcessRulesResult);
            }
        });
    }

    openViewEditPopup(ddArgs: DisplayDataArguments, component: TreeListProps | GridProps) {
        try {
            this.helperService.openDisplayDataPopup(ddArgs, this.popupUrl);
        } catch (error) {
            this.toast.error(this.toastConsts.allowPopups);
        }

        component.loadingVisible = false;
    }

    openImportFileDecision(eventArgs: any) {
        if (this.isIntegrationImportButtonDisabled(eventArgs)) {
            this.openImportFilePopup(eventArgs);
        } else {
            const firstMessage = 'Choose your import type:';

            const title = 'Choose an import option';
            this.changeDecisionPopupPurpose(title);

            this.decisionPopupSteps.push(new CorePopupStep(firstMessage, (e: CorePopupStep) => this.decideImportType(eventArgs, 'Excel'),
                (e: CorePopupStep) => this.decideImportType(eventArgs, 'Integration'),
                    this, 'Excel Import', 'Integration Import', false, true, false, this.isIntegrationImportButtonDisabled(eventArgs)));
            this.decisionPopupProps.visible = true;
        }
    }

    decideImportType(eventArgs: any, type: string) {
        if (type === 'Excel') {
            this.openImportFilePopup(eventArgs);
        }
        else {
            this.decisionPopup.closePopup(true);
            this.openImportIntegrationFilePopup(eventArgs);
        }
    }

    openImportFilePopup(eventArgs: any) {
        this.decisionPopupProps.visible = false;
        if (this.periodLockCheck()) {
            return;
        }
        const datasource = this.filteredImportedRecords.find(x => x.datasourceId === eventArgs.event.row.key);
        const title = 'Importing into: ' + datasource.datasourceName;
        const datasourceMappingnames = [
            ...this.datasourceMappings.filter(x => x.datasourceId === datasource.datasourceId)
                .map(x => ({name: x.name === '' ? defaultMappingNameValue : x.name, id: x.id}))
        ];

        const index = datasourceMappingnames.length === 1 ? 1 : 2;
        this.changePopupPurpose(title);
        const requiredProps = [new RequiredProperty('datasourceId', datasource.datasourceId)];

        const firstMessage = 'Import normally appends new records to the selected period.<br><br>Should this file append new records or delete and replace the existing records?';
        this.processingPopupSteps.push(new CorePopupStep(firstMessage, (e: CorePopupStep) => { this.markImportFileAsAppendOrReplace(false, index, datasource); },
            (e: CorePopupStep) => { this.markImportFileAsAppendOrReplace(true, index, datasource); }, this, 'Append', 'Delete/Replace', false, true)
                .appendItemProp(new CoreDynamicInputProperties('Validate Empty Fields: ', true, yesNoArray, 'name', 'value', false).createForSelectBox()));

        if (datasourceMappingnames && datasourceMappingnames.length === 1) {
            this.importFileDatasourceMappingId = datasourceMappingnames[0].id;
        } else {
            this.processingPopupSteps.push(new CorePopupStep('Please select a mapping to use for importing:', this.setFileImportDatasouceMapping, null, this, 'Proceed', 'Back', true, false)
                .appendItemProp(new CoreDynamicInputProperties('', true, datasourceMappingnames, 'name', 'id', datasourceMappingnames[0].id, false).createForSelectBox()));
        }

        this.processingPopupSteps.push(new CorePopupStep('', null, null, this, 'Import File', 'Back', true, false)
             .enableFileUpload(this.importFile, 'Select File', 'or Drag and Drop: Excel, CSV, or TSV files', ['.xls', '.xlsx', '.csv', '.tsv'], 'instantly', 1, false, requiredProps));

        this.processingPopupProps.visible = true;
    }

    openImportIntegrationFilePopup(eventArgs: any) {
        if (this.periodLockCheck()) {
            return;
        }
        const datasource = this.filteredImportedRecords.find(x => x.datasourceId === eventArgs.event.row.key);
        const title = 'Importing into ' + datasource.datasourceName + ' from third-party platform';
        const integrationSourcenames = [
            ...this.activeIntegrationSources.filter(x => x.datasourceId === datasource.datasourceId)
                .map(x => ({name: x.sourceName === '' ? defaultMappingNameValue : x.sourceName, id: x.id }))
        ];
        const integrationSources = [
            ...this.activeIntegrationSources.filter(x => x.datasourceId === datasource.datasourceId && x.action === 'Import')
        ];

        const index = integrationSourcenames.length === 1 ? 1 : 2;
        this.changePopupPurpose(title);

        const firstMessage = 'Import normally appends new records to the selected period.<br><br>Should this file append new records or delete and replace the existing records?';
        this.processingPopupSteps.push(new CorePopupStep(firstMessage, (e: CorePopupStep) => { this.markImportFileAsAppendOrReplace(false, index, datasource); },
            (e: CorePopupStep) => { this.markImportFileAsAppendOrReplace(true, index, datasource); }, this, 'Append', 'Delete/Replace', false, true)
                .appendItemProp(new CoreDynamicInputProperties('Validate Empty Fields: ', true, yesNoArray, 'name', 'value', false).createForSelectBox()));

        this.processingPopupSteps.push(new CorePopupStep('Please select an integration source to use for importing:', this.setFileImportIntegrationSource, null, this, 'Proceed', 'Back', true, false)
            .appendItemProp(new CoreDynamicInputProperties('', true, integrationSources, 'sourceName', 'id', integrationSources[0].id, false).createForSelectBox()));

        this.processingPopupProps.visible = true;
    }

    setFileImportDatasouceMapping(e: CorePopupStep) {
        this.importFileDatasourceMappingId = e.itemsProps[0].selectedValue;
    }

    setFileImportIntegrationSource(e: CorePopupStep) {
        const sources = this.activeIntegrationSources.filter(x => x.id === e.itemsProps[0].selectedValue);
        const source = sources[0];
        this.importIntegrationSource(null, e, false, source);
    }

    async markImportFileAsAppendOrReplace(isReplace: boolean, index: number, datasource: ImportedTable) {
        if (isReplace) {
            const loadPanel = this.helperService.createLoadPanel(null, 'Checking for dependant calculations...');
            this.helperService.injectComponent(this.viewContainerRef, loadPanel);
            if (!await this.checkForCalculationRows(datasource.datasourceId, loadPanel)) {
                this.processingPopup.closePopup(true);
            }
        }

        this.importFileReplaces = isReplace;
        this.processingPopup.changeStepValueByIndex('Select a file or source to import. This file will ' +
            (isReplace ? '<b>replace</b> the existing records for this datasource.'
                : 'be <b>appended</b> to the records in this datasource.'),
            index,
            (isReplace ? fileUploadTypeIds.ImportXactionReplace : fileUploadTypeIds.ImportXactionAppend));
        this.processingPopup.checkIfStepRequiresHeaders();
    }

    isIntegrationImportButtonDisabled(eventArgs: any) {
        let isDisabled = true;
        const datasource = this.filteredImportedRecords.find(x => x.datasourceId === eventArgs.event.row.key);
        const integrationSourcenames = [
            ...this.activeIntegrationSources.filter(x => x.datasourceId === datasource.datasourceId)
                .map(x => ({ name: x.sourceName === '' ? defaultMappingNameValue : x.sourceName, id: x.id }))
        ];

        if (integrationSourcenames.length >= 1) {
            isDisabled = false;
        }

        return isDisabled;
    }

    importFile(event: any, value: CorePopupStep, isCleaningDataInGrid: boolean = false) {
        this.importedGridProps.loadingVisible = true;
        const importRequest = new CoreRequest<XactionFileImportRequest>(null, []);
        if (!isCleaningDataInGrid) {
            const datasourceId = value.uploadProps.requiredproperties.find(x => x.propertyName === 'datasourceId').propertyValue;
            const areEmptyValuesValidated = this.processingPopup.steps[0].itemsProps[0].selectedValue as boolean;

            value.uploadProps.importedFileIds.forEach(fileId => {
                importRequest.arguments.push(new XactionFileImportRequest(fileId, this.selectedSeriesId, this.selectedPeriodId, datasourceId,
                    this.importFileDatasourceMappingId, areEmptyValuesValidated));
            });

            this.cleaningGridProps.requiredProperties = [
                new RequiredProperty('fileIds', value.uploadProps.importedFileIds),
                new RequiredProperty('seriesId', this.selectedSeriesId),
                new RequiredProperty('periodId', this.selectedPeriodId),
                new RequiredProperty('datasourceid', datasourceId),
                new RequiredProperty('datasourceMappingId', this.importFileDatasourceMappingId),
                new RequiredProperty('areEmptyValuesValidated', areEmptyValuesValidated)
            ];
        } else {
            (this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'fileIds').propertyValue as string[]).forEach(fileId => {
                const request = new XactionFileImportRequest(fileId,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'seriesId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'periodId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'datasourceid').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'datasourceMappingId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'areEmptyValuesValidated').propertyValue
                );

                if (event === 'Record') {
                    request.addCleanedRecords(this.processingPopupSteps.find(x => x.dataGrid).dataGrid.dataSource);
                    if (this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'headerMappings')) {
                        request.appendCleanedColumns(this.cleaningGridProps.requiredProperties
                            .find(x => x.propertyName === 'headerMappings').propertyValue);
                    }
                } else if (event === 'Column') {
                    request.addCleanedColumns(this.processingPopupSteps.find(x => x.dataGrid).dataGrid.dataSource);
                }

                importRequest.arguments.push(request);
            });
        }

        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            importRequest.transactionId = newTransactionResponse.result;
            this.pollProcessLogByTransactionId('Import Log', importRequest.transactionId, () => !this.isImportFileDirty);
            this.isImportFileDirty = false;
            this.fileProcessingService.insertImportedFilesIntoXaction(importRequest).subscribe(response => {
                if (response.responseCode === coreResponseCodes.Success) {
                    this.refreshPage(null);
                    this.toast.success('File successfully imported');
                } else if (response.responseCode === coreResponseCodes.SuccessWithTruncated) {
                    this.refreshPage(null);
                    this.toast.success('File successfully imported but some text fields were too long and were truncated');
                } else if (response.responseCode === coreResponseCodes.DirtyData || response.responseCode === coreResponseCodes.DirtyFileHeaders) {
                    this.isImportFileDirty = true;
                    const isColumns = response.responseCode === coreResponseCodes.DirtyFileHeaders;
                    const cleaningString = isColumns ? 'Column' : 'Record';
                    const cleaningStep = new CorePopupStep(null,
                        (e: CorePopupStep) => { this.importFile(cleaningString, null, true); },
                        (e: CorePopupStep, popup: CorePopupComponent) => {
                            let rowData = [];
                            const filter = popup.popupGrid.grid.instance.getCombinedFilter();
                            const loadOptions = popup.popupGrid.grid.instance.getDataSource().loadOptions();
                            popup.popupGrid.grid.instance.getDataSource().store().load({filter, sort: loadOptions.sort}).then((data) => {
                                rowData = data as any[];
                            });
                            const columns = isColumns ? this.columnCleaningGridColumns : this.cleaningGridColumns;
                            const header = columns.map(column => column.columnHeader).join('\t');
                            const rowStrings = [header].concat(rowData.map(row => columns.map(column => this.getRowCellTextForCopyToClipboard(row, column.dataField)).join('\t')));
                            navigator.clipboard.writeText(rowStrings.join('\n')).then(() => {
                                this.toast.success('Cleaning Records successfully copied to clipboard');
                            }, error => {
                                this.toast.error('An error occurred while attempting to copy to clipboard', error);
                            });
                        },
                        this, 'Finalize ' + cleaningString + ' ' + 'Cleaning', 'Copy to Clipboard', false, true);
                    response.results.forEach(packet => {
                        if (packet.cleanedRecords) {
                            packet.cleanedRecords.map(x => {
                                const rowNumbersLimited = x.rowNumbers.slice(0, 50);
                                const rowNumbersSuffix = x.rowNumbers.length > 50 ? ', ...' : '';
                                x.rowNumbersString = rowNumbersLimited.join(', ') + rowNumbersSuffix;
                                x.text = x.text === '' ? '[expected value missing]' : x.text;
                            });
                        }
                    });

                    if (isColumns) {
                        this.availableColumnMappings = response.results[0].headers.unmappedHeaders;
                    } else if (!isColumns && response.results && response.results[0] && response.results[0].headers && response.results[0].headers.headerMappings) {
                        this.cleaningGridProps.requiredProperties.push(new RequiredProperty('headerMappings', response.results[0].headers.headerMappings));
                    }

                    if (this.processingPopupProps.visible) {
                        cleaningStep.setCancelButtonText('Copy to Clipboard');
                        this.processingPopupSteps[this.processingPopup.stepIndex].okAndCancelVisible = false;
                        setTimeout(() => {
                            this.processingPopup.stepIndex++;
                        }, 1500);
                    } else {
                        this.changePopupPurpose('Clean Imported ' + cleaningString + 's');
                    }

                    this.processingPopup.changePopupSize('450', '700');
                    cleaningStep.enableDataGrid(isColumns ? response.results[0].headers.headerMappings : response.results[0].cleanedRecords,
                        isColumns ? this.columnCleaningGridColumns : this.cleaningGridColumns,
                        isColumns ? this.columnCleaningGridProps : this.cleaningGridProps);

                    this.processingPopupSteps.push(cleaningStep);
                    this.processingPopupProps.visible = true;
                } else {
                    this.toast.error(this.toastConsts.errorImportingFile);
                }

                this.importedGridProps.loadingVisible = false;
            });
        });
    }

    getRowCellTextForCopyToClipboard(row: any[], fieldName: string): string {
        if (fieldName === 'rowNumbersString') {
            return row['rowNumbers'].join(', ');
        } else {
            return row[fieldName];
        }
    }

    importIntegrationSource(event: any, value: CorePopupStep, isCleaningDataInGrid: boolean = false, integrationSource: IntegrationSource) {
        this.importedGridProps.loadingVisible = true;
        const importRequest = new CoreRequest<XactionFileImportRequest>(null, []);
            if (!isCleaningDataInGrid) {
                const areEmptyValuesValidated = this.processingPopup.steps[0].itemsProps[0].selectedValue as boolean;

                importRequest.arguments.push(new XactionFileImportRequest('54ca8dac-723a-f25c-537a-4ac775db1767', this.selectedSeriesId, this.selectedPeriodId, integrationSource.datasourceId,
                    integrationSource.id, areEmptyValuesValidated, this.importFileReplaces));

                this.cleaningGridProps.requiredProperties = [
                    new RequiredProperty('sourceId', integrationSource.id),
                    new RequiredProperty('seriesId', this.selectedSeriesId),
                    new RequiredProperty('periodId', this.selectedPeriodId),
                    new RequiredProperty('datasourceid', integrationSource.datasourceId),
                    new RequiredProperty('integrationSourceId', integrationSource.id),
                    new RequiredProperty('areEmptyValuesValidated', areEmptyValuesValidated),
                    new RequiredProperty('isDeleteAndReplace', this.importFileReplaces)
                ];
            }
            else {
                const sourceId = this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'sourceId').propertyValue;
                const request = new XactionFileImportRequest('54ca8dac-723a-f25c-537a-4ac775db1767',
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'seriesId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'periodId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'datasourceid').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'integrationSourceId').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'areEmptyValuesValidated').propertyValue,
                    this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'isDeleteAndReplace').propertyValue
                );

                if (event === 'Record') {
                    request.addCleanedRecords(this.processingPopupSteps.find(x => x.dataGrid).dataGrid.dataSource);
                    if (this.cleaningGridProps.requiredProperties.find(x => x.propertyName === 'headerMappings')) {
                        request.appendCleanedColumns(this.cleaningGridProps.requiredProperties
                            .find(x => x.propertyName === 'headerMappings').propertyValue);
                    }
                } else if (event === 'Column') {
                    request.addCleanedColumns(this.processingPopupSteps.find(x => x.dataGrid).dataGrid.dataSource);
                }

                importRequest.arguments.push(request);
            }

            this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
                importRequest.transactionId = newTransactionResponse.result;
                this.pollProcessLogByTransactionId('Import Log', importRequest.transactionId, () => !this.isImportFileDirty);
                this.isImportFileDirty = false;
                this.fileProcessingService.insertIntegrationSourceIntoXaction(importRequest).subscribe(response => {
                    if (response.responseCode === coreResponseCodes.Success) {
                        this.refreshPage(null);
                        this.toast.success('Integration data successfully imported');
                    } else if (response.responseCode === coreResponseCodes.SuccessWithTruncated) {
                        this.refreshPage(null);
                        this.toast.success('Integration data successfully imported but some text fields were too long and were truncated');
                    } else if (response.responseCode === coreResponseCodes.DirtyData || response.responseCode === coreResponseCodes.DirtyFileHeaders) {
                        this.isImportFileDirty = true;
                        const isColumns = response.responseCode === coreResponseCodes.DirtyFileHeaders;
                        const cleaningString = isColumns ? 'Column' : 'Record';
                        const cleaningStep = new CorePopupStep(null, (e: CorePopupStep) => { this.importIntegrationSource(cleaningString, null, true, integrationSource); },
                            (e: CorePopupStep, popup: CorePopupComponent) => {
                                const rows = popup.popupGrid.grid.instance.getVisibleRows();
                                const columns = isColumns ? this.columnCleaningGridColumns : this.cleaningGridColumns;
                                const header = columns.map(column => column.columnHeader).join('\t');
                                const rowStrings = [header].concat(rows.map(row => columns.map(column => row.data[column.dataField]).join('\t')));
                                navigator.clipboard.writeText(rowStrings.join('\n')).then(() => {
                                    this.toast.success('Cleaning Records successfully copied to clipboard');
                                }, error => {
                                    this.toast.error('An error occurred while attempting to copy to clipboard', error);
                                });
                            },
                            this, 'Finalize ' + cleaningString + ' ' + 'Cleaning', null, false, true);
                        response.results.forEach(packet => {
                            if (packet.cleanedRecords) {
                                packet.cleanedRecords.map(x => {
                                    const rowNumbersLimited = x.rowNumbers.slice(0, 50);
                                    const rowNumbersSuffix = x.rowNumbers.length > 50 ? ', ...' : '';
                                    x.rowNumbersString = rowNumbersLimited.join(', ') + rowNumbersSuffix;
                                    x.text = x.text === '' ? '[expected value missing]' : x.text;
                                });
                            }
                        });

                        if (isColumns) {
                            this.availableColumnMappings = response.results[0].headers.unmappedHeaders;
                        } else if (!isColumns && response.results && response.results[0] && response.results[0].headers && response.results[0].headers.headerMappings) {
                            this.cleaningGridProps.requiredProperties.push(new RequiredProperty('headerMappings', response.results[0].headers.headerMappings));
                        }

                        if (this.processingPopupProps.visible) {
                            cleaningStep.setCancelButtonText('Copy to Clipboard');
                            this.processingPopup.changeStepOkButtonText('Clean ' + cleaningString + 's', 0);
                        } else {
                            this.changePopupPurpose('Clean Imported ' + cleaningString + 's');
                        }

                        this.processingPopup.changePopupSize('450', '700');
                        cleaningStep.enableDataGrid(isColumns ? response.results[0].headers.headerMappings : response.results[0].cleanedRecords,
                            isColumns ? this.columnCleaningGridColumns : this.cleaningGridColumns,
                            isColumns ? this.columnCleaningGridProps : this.cleaningGridProps);

                        this.processingPopupSteps.push(cleaningStep);
                        this.processingPopupProps.visible = true;
                    } else {
                        this.toast.error(this.toastConsts.errorImportingFile);
                    }

                    this.importedGridProps.loadingVisible = false;
                });
            });
    }

    calculateReplaceWithDisplayValue(rowInfo: any): string {
        // For percentages, we want to display them as a percentage rather than a decimal
        if (rowInfo.format === 'p') {
            return String.format(`{0:${rowInfo.format}}`, rowInfo.replaceWith);
        } else {
            return rowInfo.replaceWith;
        }
    }

    calculateCleaningTextDisplayValue(rowInfo: any): string {
        if (rowInfo.singletonClassId === singletonClassIds.Text) {
            return rowInfo.text + ' (to be truncated)';
        } else {
            return rowInfo.text;
        }
    }

    getEditorPropsForCleaningGridCell(cellInfo: any, coreColumn: CoreColumn): CoreDynamicCellEditorProps {
        const result = new CoreDynamicCellEditorProps();
        const singletonClassId = cellInfo.data.singletonClassId;

        if (singletonClassId === EnumSingletonClassId.Date) {
            result.createForDateTimePicker(cellInfo.data.format);
        } else if (singletonClassId === EnumSingletonClassId.Seller) {
            const sellerColumns: CoreColumn[] = [
                new CoreColumn('id', '', false),
                new CoreColumn('name', 'Name', true).sortAsc(),
                new CoreColumn('importName', 'Import Name', true),
                ...this.helperService.getAlternateImportNameColumns(this.sellers)
            ];

            result.createForCoreTableSelectTemplate(this.sellers, sellerColumns, 'importName', 'importName', 300, 600);
        } else if (singletonClassIds.Tag === singletonClassId || singletonClassIds.Product === singletonClassId || singletonClassIds.Customer === singletonClassId) {
            const lookupData = singletonClassId === singletonClassIds.Tag ? this.tags.filter(x => x.tagNo === cellInfo.data.fieldNo)
                : singletonClassId === singletonClassIds.Product ? this.products
                : singletonClassId === singletonClassIds.Customer ? this.customers
                : null;

            result.createForSelectBox(lookupData, 'name', 'name');
        } else if (singletonClassId === singletonClassIds.Qty) {
            result.createForNumberBox(this.fieldService.convertFormatString(cellInfo.data.format));
        } else if (singletonClassId === singletonClassIds.Text) {
            result.createForTextBox();
            result.mockCoreColumn.isEditable = false;
        }

        return result;
    }

    getEditorPropsForColumnCleaningGridCell(cellInfo: any, coreColumn: CoreColumn, gridData: any[]): CoreDynamicCellEditorProps {
        const result = new CoreDynamicCellEditorProps();
        const lookupData = this.availableColumnMappings
            .map(x => ({ name: x })).sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0));
        gridData.forEach(record => {
            if (record.newVal !== null && record.newVal !== undefined && record.newVal !== cellInfo.value) {
                lookupData.splice(lookupData.findIndex(x => x.name === record.newVal), 1);
            }
        });

        result.createForSelectBox(lookupData, 'name', 'name');
        return result;
    }

    cancelProcessing() {
        this.processingService.cancelProcessing().subscribe(() => this.processingPopupSteps[0].cancelDisabled = true);
    }

    pollProcessLogByTransactionId(popupTitle: string, transactionId: string, shouldCloseOnSuccess: boolean | (() => boolean) = false) {
        const poll = new BehaviorSubject(new PollValue<number>(0));
        this.changePopupPurpose(popupTitle);
        const logStep = new CorePopupStep('', null, this.cancelProcessing, this, 'Close Log', 'Cancel', false, true, false, false);
        logStep.isPreformatted = true;
        this.processingPopupProps.width = this.popupLogWidth;
        this.processingPopupProps.height = this.popupLogHeight;
        this.processingPopupSteps.push(logStep);
        this.processingPopupProps.visible = true;
        let pollErrorCount = 0;
        poll.subscribe(lastValue => {
            setTimeout(() => {
                this.processLogService.getProcessLogsByTransactionId(transactionId, poll.value.value).subscribe(logs => {
                    pollErrorCount = 0;
                    let continuePolling = true;
                    logs.results.forEach(log => {
                        logStep.message += log.log + '\n';
                        if (log.processLogRecordTypeId === processLogRecordTypes.Error || log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                            continuePolling = false;

                            const shouldClose = (typeof shouldCloseOnSuccess === 'function') ? shouldCloseOnSuccess() : shouldCloseOnSuccess;
                            if (shouldClose && log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                                this.processingPopupProps.visible = false;
                                this.cd.detectChanges();
                            }
                        }
                    });

                    if (continuePolling) {
                        let idToPollFrom = poll.value.value;
                        if (logs.results && logs.results.length > 0) {
                            idToPollFrom = logs.results.map(x => x.id).reduce((a, b) => Math.max(a, b));
                        }

                        poll.next(new PollValue(idToPollFrom));
                    }

                    setTimeout(() => {
                        this.processingPopup.setScrollHeightToBottom();
                    }, 100);
                }, err => {
                    if(pollErrorCount < this.processLogPollErrorCap){
                        poll.next(new PollValue(poll.value.value));
                    } else {
                        logStep.message += 'Process log has timed out.';
                    }
                    pollErrorCount++;
                });
            }, lastValue.getTimeout(1000));

            if (this.pageDestroyed) {
                poll.unsubscribe();
            }
        });
    }

    onLayoutChanged(value: any): void {
        this.selectedUiViewId = value;
        this.layoutDropdown.setCheckBoxValue(true);
        this.setRowSelectionOnOffForImportedAndProcessed(false);
        this.applyLayout();
        if (value !== null) {
            this.settingService.upsertUserSettingsByClassIdBulk([new IdLongValueString(settingClassIds.InternalProcessingLayout, value + '')]).subscribe(numRecordsUpdated => { });
        }
    }

    deleteLayout(e: CoreEventArguments): void {
        const deletedId = this.selectedUiViewId;
        this.uiViewService.deleteUiView(deletedId).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.uiViews.splice(this.uiViews.findIndex(x => x.id === deletedId), 1);
                this.setFilteredUiViews();
                this.onLayoutChanged(this.dummyLayout.id);
            }
        });
    }

    addLayout(e: any): void {
        const newUiView: UiView = new UiView().createForProcessingLayout(e, this.getSelectedDatasourceIdsString(),
            this.getSelectedPlanIdsString(), uiViewContextCodes.ProcessingLayout, this.selectedRecurrenceId);

        this.uiViewService.insertUiView(newUiView).subscribe(inserted => {
            this.uiViews.push(inserted);
            this.uiViews.sort((a, b) => this.sortUiView(a, b));
            this.setFilteredUiViews();
            this.onLayoutChanged(inserted.id);
            this.setRowSelectionOnOffForImportedAndProcessed(false);
        });
    }

    renameLayout(event: string) {
        const uiView = this.filteredLayouts.find(x => x.id === this.selectedUiViewId);
        uiView.name = event;
        this.uiViewService.updateUiView(uiView).subscribe(updatedView => {
            this.uiViews[this.uiViews.findIndex(x => x.id === updatedView.id)].name = updatedView.name;
            this.uiViews.sort((a, b) => this.sortUiView(a, b));
            this.setFilteredUiViews();
        });
    }

    saveLayout(layoutId: any) {
        if (this.importedRecordsGrid.grid.selectedRowKeys.length < 1 && this.processedTreeList.treeList.selectedRowKeys.length < 1) {
            this.toast.warning('Save NOT performed: No datasources or Plans were selected');
        } else {
            const layout = this.filteredLayouts[this.filteredLayouts.findIndex(x => x.id === layoutId)];
            layout.datasources = this.getSelectedDatasourceIdsString();
            layout.plans = this.getSelectedPlanIdsString();
            this.uiViewService.updateUiView(layout).subscribe(updatedView => {
                this.uiViews[this.uiViews.findIndex(x => x.id === updatedView.id)] = updatedView;
                this.setFilteredUiViews();
                this.toast.success('Layout updated');
                this.setRowSelectionOnOffForImportedAndProcessed(false);
                this.layoutDropdown.setCheckBoxValue(true);
            });
        }
    }

    onLayoutCheckBoxClick(event: any) {
        this.setRowSelectionOnOffForImportedAndProcessed(!event.value);
        this.layoutDropdownProps.isCheckBoxChecked = event.value;
        if (event.value) {
            this.applyLayout();
        } else {
            if (this.selectedUiViewId === this.dummyLayout.id) {
                // setTimeout here and below (else) reassignes selected row keys after the grid changes the object reference internally
                setTimeout(() => {
                    this.importedRecordsGrid.grid.selectedRowKeys = this.importedRecords.map(x => x.datasourceId);
                    this.processedTreeList.treeList.selectedRowKeys = this.processedRecords.filter(x => !x.parentId).map(x => x.id);
                }, 1);
            } else {
                const layout = this.filteredLayouts.find(x => x.id === this.selectedUiViewId);
                this.filteredImportedRecords = this.importedRecords;
                this.filteredProcessedRecords = this.processedRecords;
                setTimeout(() => {
                    this.importedRecordsGrid.grid.selectedRowKeys = this.helperService.removeBracketsFromString(layout.datasources, true) as string[];
                    this.processedTreeList.treeList.selectedRowKeys = this.helperService.removeBracketsFromString(layout.plans, true) as string[];
                }, 1);
            }

            this.toggleActiveSegmentsDatasource();
        }
    }

    layoutValidation(): string {
        let message = '';
        if (this.processedTreeList && this.importedRecordsGrid
            && this.processedTreeList.treeList.selectedRowKeys.length < 1
            && this.importedRecordsGrid.grid.selectedRowKeys.length < 1) {

            message = 'Please select a datasource or plan to proceed';
        }

        return message;
    }

    ontoggleActiveSegmentsDatasourceClick(event: any): void {
        this.showHideInactiveMsg = this.showHideInactiveMsg === this.showInactiveMsg ? this.hideInactiveMsg : this.showInactiveMsg;
        this.onLayoutCheckBoxClick({ value: this.layoutDropdownProps.isCheckBoxChecked });
    }

    toggleActiveSegmentsDatasource(): void {
        if (this.showHideInactiveMsg === this.showInactiveMsg) {
            if (this.permissionViewImportedRecords && this.filteredImportedRecords){
                this.filteredImportedRecords = this.filteredImportedRecords.filter(x => !x.isDeleted);
            }
            if (this.permissionViewProcessedRecords && this.filteredProcessedRecords)
            {
                this.filteredProcessedRecords =  this.filteredProcessedRecords.filter(x => !x.isDeleted);
            }
        }
    }

    viewAllImported(event: any): void {
        let selectedDataSourceIds = this.importedRecordsGrid.grid.selectedRowKeys as number[];
        let isData = selectedDataSourceIds.length > 0;
        let uiViewId: number = null;
        if (event.itemData !== null && event.itemData !== undefined) {
            if (event.itemData.datasourceId !== undefined) {
                selectedDataSourceIds = [...this.filteredImportedRecords.map(x => x.datasourceId)];
                isData = true;
                uiViewId = event.itemData.id;
            }
        }

        if (selectedDataSourceIds && selectedDataSourceIds.length > 0 && isData) {
            this.importedGridOpenViewEdit(null, selectedDataSourceIds, uiViewId);
        } else {
            this.toast.error(this.toastConsts.noSelectionForPopup);
        }
    }

    viewAllProcessed(event: any): void {
        const ruleIds: string[] = new Array();
        const planIds: string[] = new Array();
        let uiViewId: number = null;

        if (event.itemData !== null && event.itemData !== undefined) {
            if (event.itemData.datasourceId !== undefined) {
                uiViewId = event.itemData.id;
                this.filteredProcessedRecords.forEach(record => {
                    if (record.parentId) {
                        ruleIds.push(record.id);
                    } else {
                        planIds.push(record.id);
                    }
                });
            }
        } else {
            this.processedTreeList.treeList.selectedRowKeys.forEach(key => {
                let planId: string;
                if (!key.startsWith('Sg') && !key.startsWith('Co')) {
                    planId = key;
                    (this.processedTreeList.dataSource as any).filter(x => x.parentId === key).forEach(rule => {
                        ruleIds.push(rule.id);
                    });
                } else {
                    planId = (this.processedTreeList.dataSource as any).find(y => y.id === key).parentId;
                    ruleIds.push(key);
                }

                if (planIds.filter(x => x === planId).length < 1) {
                    planIds.push(planId);
                }
            });
        }

        if (ruleIds.length > 0 || planIds.length > 0) {
            this.processedTreeOpenViewEdit(null, { ruleIds, planIds }, uiViewId);
        } else {
            this.toast.error(this.toastConsts.noSelectionForPopup);
        }
    }

    performRunCycleCheck(event: any): void {
        if (this.periodLockCheck()) {
            return;
        }
        const isFromRecordsGrid = event?.event?.row !== undefined;
        const defaultPlan = this.allEtlPlans.find(x => x.description === this.recurrences?.find(y => y.id === this.selectedRecurrenceId).name + '_Run_Cycle')?.description
            ?? this.allEtlPlans.find(x => x.description === 'Run_Cycle')?.description
                ?? processAllPlanName;

        let planName: string = defaultPlan;

        if (isFromRecordsGrid && !this.helperService.isNullOrUndefined(event?.event?.row?.data['name'])) {
            planName = event.event.row.data['name'];
        } else if (!isFromRecordsGrid && !this.helperService.isNullOrUndefined(event?.itemData?.description)) {
            planName = event.itemData.description;
        }

        const planDescription: string = planName === defaultPlan ? 'Run Cycle' : 'Run: ' + planName;
        this.processingContext = new ProcessingContext().createAll(this.selectedPeriodId, this.selectedSeriesId, planName);

        this.processingService.getWarningMessagesForPlanProcessing(this.processingContext).subscribe(messages => {
            this.changePopupPurpose(planDescription);
            this.processingPopupProps.visible = true;
            const warnings = messages;
            if (messages.length === 0) {
                warnings.push(`Are you sure you want to proceed with the execution of: "${planName === defaultPlan ? 'Run Cycle' : planName}"
                in period ${this.periodDropdown.getSelectedPeriodDateRangeStr()}?`);
            }

            for (let i = 0; i < messages.length; i++) {
                if (i === (messages.length - 1)) {
                    if (planName === defaultPlan){
                        this.processingPopupSteps.push(new CorePopupStep(messages[i], this.runCycle, null, this, 'Run Cycle'));
                    } else if (isFromRecordsGrid) {
                        this.ruleProcessingContext = new ProcessRulesContext(this.selectedPeriodId, this.selectedSeriesId, event.event.row.key, true);
                        this.processingPopupSteps.push(new CorePopupStep(messages[i], this.processFunctions, null, this, 'Run'));
                    } else {
                        this.processingPopupSteps.push(new CorePopupStep(messages[i], this.runCycle, null, this, 'Run'));
                    }
                } else {
                    this.processingPopupSteps.push(new CorePopupStep(messages[i], null, null, this));
                }
            }
        });
    }


    runCycle(e: CorePopupStep): void {
        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            const processPlanRequest = new CoreRequest<ProcessingContext>(this.processingContext, null).addTransactionId(newTransactionResponse.result);
            this.pollProcessLogByTransactionId('Run Cycle Log', processPlanRequest.transactionId, true);
            this.processingService.processPlan(processPlanRequest).subscribe(wasSuccess => {
                this.refreshPage(null);
            });
        });
    }

    updateAnalytics(event: any): void {
        this.processingService.getProcessingInProgress().subscribe(inProgress => {
            if (!inProgress) {
                this.toast.success('Analytics cache refresh in progress...');
                this.analyticService.setRefreshInProgress([this.selectedPeriodId]);
                this.processingService.updateIgCache(new IGCacheRefresh([this.selectedPeriodId])).subscribe(success => {
                    if (success === true){
                        this.analyticService.setRefreshCompletedIds([this.selectedPeriodId]);
                        this.toast.success('Analytics cache refresh completed');
                    }
                    else {
                        this.analyticService.setRefreshCompletedIds([this.selectedPeriodId]);
                        this.toast.error('Analytics cache refresh incomplete. See logs for details.');
                    }
                });
            } else {
                this.toast.error(this.toastConsts.processingInProgress);
            }
        });
    }

    changePopupPurpose(title: string, height: string = this.popupDefaultHeight, width: string = this.popupDefaultWidth): void {
        this.processingPopupProps.title = title;
        this.processingPopupSteps = [];
        this.processingPopup.changePopupSize(height, width);
    }

    changeDecisionPopupPurpose(title: string, height: string = this.popupDefaultHeight, width: string = this.popupDefaultWidth): void {
        this.decisionPopupProps.title = title;
        this.decisionPopupSteps = [];
        this.decisionPopup.changePopupSize(height, width);
    }

    setRowSelectionOnOffForImportedAndProcessed(newValue: boolean): void {
        this.importedGridProps.isRowSelectionEnabled = newValue;
        this.processedTreeListProps.isRowSelectionEnabled = newValue;
    }

    onResize(e: any): void {
        this.adjustAccordionItemHeights();
    }

    onAccordionClick(e: any): void {
        this.adjustAccordionItemHeights();
    }

    adjustAccordionItemHeights(): void {
        const minWrapperHeight = 500;
        const totalElements = this.importedAccordion.selectedItems.length + this.processedAccordion.selectedItems.length;
        const importedGrid = document.getElementById('importedRecordsGrid');
        const processedTree = document.getElementById('processedRecords');
        const actionsMenu = document.getElementById('actionsMenu');
        const actionsMenuMiddle = document.getElementById('actionsMenuMiddle');
        const actionsMenuBottom = document.getElementById('actionsMenuBottom');
        let wrapperHeight = window.innerHeight - this.accordionWrapper.nativeElement.offsetTop - this.appElementService.headerElement.offsetHeight;
        wrapperHeight = wrapperHeight < minWrapperHeight ? minWrapperHeight : wrapperHeight;
        const totalAccordionHeaderHeight = 55;
        const accordionMarginHeights = 10;
        const footerHeight = 35;
        const availableHeight = wrapperHeight - totalAccordionHeaderHeight - accordionMarginHeights - footerHeight;
        actionsMenu.style.height = (wrapperHeight - footerHeight) + 'px';
        actionsMenuMiddle.style.top = (((wrapperHeight - footerHeight) / 2) - (actionsMenuMiddle.offsetHeight / 2)) + 'px';
        actionsMenuBottom.style.top = (wrapperHeight - footerHeight - actionsMenuBottom.offsetHeight) + 'px';

        // set the height of the accordion container to use all space below it
        this.accordionWrapper.nativeElement.style.height = wrapperHeight + 'px';
        if (this.importedAccordion.selectedItems.length === 1) {
            importedGrid.style.maxHeight = (availableHeight / totalElements) + 'px';
        }

        if (this.processedAccordion.selectedItems.length === 1) {
            processedTree.style.maxHeight = (availableHeight / totalElements) + 'px';
        }
    }

    sortUiView(a: UiView, b: UiView): number {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
    }

    periodLockCheck(): boolean {
        if (this.isPeriodLocked) {
            this.toast.warning('Period has been locked. Cannot make any changes to processed output.', 'Period Locked');
            return true;
        }
    }

    async checkForCalculationRows(datasourceId: number, loadPanel: ComponentRef<DxLoadPanelComponent>): Promise<boolean> {
        const collissions = await this.datasourceService.checkDataSourceDependants(datasourceId, this.selectedPeriodId, this.selectedSeriesId).toPromise();
        loadPanel.destroy();
        if (collissions > 0) {
            const warning = (`${collissions} dependent calculation row(s) will also be deleted. Are you sure you want to proceed?`);
            return confirm(warning, 'Delete calculation rows?');
        } else if (collissions === 0) {
            return true;
        } else {
            this.toast.error(this.toastConsts.lockedPeriodCalculationDelete);
            return false;
        }
	}

    openDeleteProcessedPopup(): void {
        this.changePopupPurpose('Delete Processed');
        this.processingPopupProps.visible = true;
        const recurrence = `Recurrence: ${this.recurrences.find(r => r.id === this.selectedRecurrenceId).name}`;
        const series = `Series: ${this.series.find(s => s.id === this.selectedSeriesId).name}`;
        const message = `Processed records for the following period will be deleted:<br><br>${series}<br>${recurrence}<br><br>${this.periodDropdown.getSelectedPeriodDateRangeStr()}`;
        this.processingPopupSteps.push(new CorePopupStep(message, this.deleteProcessed, null, this));
    }

    openStaleOutputPopup(): void {
        const selectedValues = {ruleIds: this.staleRules, planIds: []};
        this.processedTreeOpenViewEdit(null, selectedValues);
    }

    deleteProcessed(): void {
        this.calcService.deleteCalculationsByPeriod(this.selectedSeriesId, this.selectedPeriodId).subscribe(result => {
            if (result.result >= 0) {
                this.toast.success(result.result + ' calculation(s) deleted.');
                this.refreshPage(null);
            } else {
                this.toast.error('Delete Failed');
            }
        });
    }

    openInBuildingBlocks(): void {
        const processedRecord = this.processedRecords.find(pr => pr.id === this.processedTreeList.treeList.selectedRowKeys[0]);

        if (this.processedTreeList.treeList.selectedRowKeys.length === 1 && processedRecord?.parentId) {
            this.router.navigate([`/building-blocks/${processedRecord.id}`]);
        } else {
            this.toast.error('Please select one Rule');
        }
    }
}
