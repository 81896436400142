import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, Output, EventEmitter } from '@angular/core';
import { DxTextAreaModule } from 'devextreme-angular';
import { BaseMultiLineStringInputProperties } from '../../models/core-multiLineString-input-properties';

@Component({
    selector: 'app-core-multiline-string-input',
    templateUrl: './core-multiline-string-input.component.html',
    styleUrls: ['./core-multiline-string-input.component.scss']
})
export class CoreMultilineStringInputComponent implements OnInit {

    @Input() value: string;
    @Input() height: string = '60';
    @Input() props: BaseMultiLineStringInputProperties<CoreMultilineStringInputComponent>;
    @Output() valueChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    handleValueChange(event: any) {
        this.valueChange.emit(this.value);
    }
}

@NgModule({
    imports: [
        DxTextAreaModule,
        CommonModule
    ],
    declarations: [CoreMultilineStringInputComponent],
    exports: [CoreMultilineStringInputComponent]
})
export class CoreMultiLineStringInputModule { }
